import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import {SelectOptionService } from './shared/elements/select-option.service';
import { NgHttpLoaderModule } from 'ng-http-loader/ng-http-loader.module';
import { NgxEditorModule } from 'ngx-editor';
import { NgDatepickerModule, DatepickerOptions } from 'ng2-datepicker';
//import { CeiboShare } from 'ng2-social-share';
import * as frLocale from 'date-fns/locale/fr';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MenuItems } from './shared/menu-items/menu-items';
import { AppRoutingModule } from './app-routing.module';
import { AuthenticationService } from './shared/services/authentication.service';
import { ApiInterceptorService } from './shared/services/api-interceptor.service';
import { AuthGuardService } from './shared/services/auth-guard.service';
import { Broadcast } from './shared/services/broadcast.service';
import { AdminVolunteerService } from './shared/services/volunteer.service';
import { AdminCampaignService } from './shared/services/campaigns.service';
import { SurveyService } from './shared/services/survey.service';
import { FeedbackService } from './shared/services/feedback.service';
import { WallPostService } from './shared/services/wallpost.service';
import { MasterNGOService } from './shared/services/masterNGO.service';
import { DashBoardService } from './shared/services/dashboard.service';
import { DataService } from './shared/services/data.service';
import { ToastrModule } from 'ng6-toastr-notifications';




import { AdminEventsService } from './shared/services/events.services';
import { AdminMasterService } from './shared/services/master.services';



import { AppComponent } from './app.component';


import { BasicLoginComponent } from './login/basic-login.component';
import { RegisterComponent } from './login/register/register.component';


import { SharedModule } from './shared/shared.module';
import { DefaultModule} from './shared/default/default.module';
import { LayoutComponent } from './layout/all-layout/layout.component';
import { BreadcrumbsComponent } from './layout/all-layout/breadcrumbs/breadcrumbs.component';
import { ForgotPasswordComponent } from './login/forgotpassword/forgotpassword.component';
import { ResetPasswordComponent } from './login/resetpassword/resetpassword.component';


import { SuperadminModule } from './superadmin/superadmin.module';
import { ReportService } from './shared/services/report.service';
import { PushNotificationService } from './shared/services/pushNotification.service';
import { LearningZoneService } from './shared/services/learningzone.service';
import { TestimonialService } from './shared/services/testimonial.service';
import { QuizService } from './shared/services/quiz.service';
import { ImpactService } from './shared/services/impact.service';
import { LeaderboardService } from './shared/services/leaderboard.service';
import { BeneficiaryService } from './shared/services/beneficiary.service';
import { RoutingService } from './shared/services/routing.service';
import { OtpComponent } from './login/otp/otp.component';
import {ShareButtonsModule} from 'ngx-sharebuttons';
import { NonApprovedComponent } from './login/non-approved/non-approved.component';
import { RejectedComponent } from './login/rejected/rejected.component';


@NgModule({
  declarations: [
    AppComponent,
    BasicLoginComponent,
    LayoutComponent,
    BreadcrumbsComponent,
    RegisterComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    OtpComponent,
    //CeiboShare,
    NonApprovedComponent,
    RejectedComponent
    
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule, ReactiveFormsModule,
    SharedModule,
    NgHttpLoaderModule,
    NgxEditorModule,
    NgDatepickerModule,
    DefaultModule,
    SuperadminModule,
    ToastrModule.forRoot(),
    ShareButtonsModule.forRoot()
  ],
  providers: [{
    provide: HTTP_INTERCEPTORS,
    useClass: ApiInterceptorService,
    multi: true
  },
    SelectOptionService,
    AuthenticationService,
    AuthGuardService,
      Broadcast,
      TestimonialService,
    MenuItems, AdminEventsService, AdminMasterService, AdminVolunteerService, AdminCampaignService, SurveyService, FeedbackService, WallPostService, QuizService,ImpactService,LeaderboardService,
    MasterNGOService, DashBoardService, ReportService, PushNotificationService , LearningZoneService,
    DataService,BeneficiaryService,RoutingService,RejectedComponent],
  bootstrap: [AppComponent]
})
export class AppModule { }
