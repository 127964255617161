import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from './../../shared/services/authentication.service';
import { Router,ActivatedRoute,NavigationStart, NavigationEnd } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AdminMasterService } from './../../shared/services/master.services';
import { DatepickerOptions } from 'ng2-datepicker';
import { ToastrManager, Toastr } from 'ng6-toastr-notifications';
import { Subscription } from 'rxjs';
//import { r } from '@angular/core/src/render3';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html'
})
export class RegisterComponent implements OnInit {

  errorMessage: string;
  successMessage: string;
  registerForm: FormGroup;
  validateForm: boolean = false;
  states: any[] = [];
  cities: any[] = [];
  countries: any[] = [];
  mobileNumber:any = "";
  unsubscribeType: any;
  userCredentials: any;
  email: any = "";
  showNgo: boolean = false;
  isIndividual: boolean = true;
  isNgo: boolean = false;
  submitEnable:boolean = true;

  constructor(
    private _service: AuthenticationService,
    private router: Router,
    private route: ActivatedRoute,
    private masterService: AdminMasterService,
    private toaster: ToastrManager) {

    this.userCredentials = JSON.parse(localStorage.getItem("userCredentials"));
    if(this.userCredentials != null && this.userCredentials!=undefined)
    {
      this.email = this.userCredentials.email;
      this.mobileNumber = this.userCredentials.mobileNumber;
    }
  }

  createForm() {
    this.registerForm = new FormGroup({
      Email: new FormControl( this.email , [Validators.required, Validators.pattern("^[_A-Za-z0-9-\\+]+(\\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\\.[A-Za-z0-9]+)*(\\.[A-Za-z]{2,})$")]),
      FirstName: new FormControl('', [Validators.required, this.whiteSpaceValidator]),
      LastName: new FormControl(''),
      countryCode: new FormControl({value:'+91',disabled:true}, [Validators.maxLength(10)]),
      MobileNumber: new FormControl(this.mobileNumber, [Validators.maxLength(15)]),
      // CityId: new FormControl('', Validators.required),
      // StateId: new FormControl('', Validators.required),
      // CountryId: new FormControl('', Validators.required),
      Ngo: new FormControl('')
      //NgoId: new FormControl('', Validators.required),
    });
  }

  register() {
  
    this.validateForm = true; this.submitEnable = false;
    if (this.registerForm.valid) 
    {  
      
       var registerRequest = this.registerForm.value;
       
       var userCredentials = {
        "email":this.registerForm.controls.Email.value,
        "mobileNumber":this.registerForm.controls.MobileNumber.value
       }
       // set details to browser cache
       localStorage.setItem("userCredentials",JSON.stringify(userCredentials));
       
       this._service.register(registerRequest)
      .subscribe(response => 
       {      
          if (response.isSuccess)
          {
            localStorage.setItem("IsNewRegistered","1");
            this.router.navigate(['otp-verify'])
          }
          else
          {        
            this.submitEnable = true; 
            this.toaster.errorToastr(response.responseMessage);
          }               
       },
       (err) => {
          this.submitEnable = true; 
          this.toaster.errorToastr("Some Error Occurred");
       });
     }     
     else{
      this.submitEnable = true; 
     }
  }

  onSelectType(event){
     var ev = event.target.checked;
     if(!ev){
        this.showNgo = false;
        this.registerForm.get('Ngo').setErrors(null);
        this.registerForm.get('Ngo').clearValidators();
     }
     else{
       this.showNgo = true;
       this.registerForm.get('Ngo').setValidators([Validators.required, this.whiteSpaceValidator]);
     }   
  }

  keyDownHandler(event) {
    if (event.target.value.length > 0) {
        return true;
    } else {
        if (event.keyCode === 32) {
            return false;
        }
    }
  }

// only number validation
numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        return false;
    }
    return true;

}

whiteSpaceValidator(
  control: FormControl
): { [key: string]: any } | null {
  var val = control.value;

  for (var i = 0; i <= val.length - 1; i++) {
      val = val.replace("&nbsp;", "");
  }
  if (val.trim() == "") {
      return { 'hasWhiteSpace': true }
  }
  else {
      return null;
  }
}

  getCities(stateId: number) {
    var obj = { PageNumber: 1, PageSize: 500, stateId: stateId };
    this.masterService.getCities(obj).subscribe(response => {
      this.cities = response.data;
    });
  }

  getStates(countryId: number) {
    var obj = { pageNumber: 1, PageSize: 500, CountryId: countryId };
    this.masterService.getStates(obj).subscribe(response => {
      this.states = response.data;
    });
  }

  ngOnInit() {
    this.createForm();

    var obj = { pageNumber: 1, PageSize: 500 };
    this.registerForm.get("countryCode").enable();

    // this.masterService.getCountries(obj).subscribe(response => {
    //   this.countries = response.data;
    // });
     
    
  }
}
