import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from './../../shared/services/authentication.service';
import { Router,ActivatedRoute,NavigationStart, NavigationEnd } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AdminMasterService } from './../../shared/services/master.services';
import { DatepickerOptions } from 'ng2-datepicker';
import { ToastrManager, Toastr } from 'ng6-toastr-notifications';
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-rejected',
  templateUrl: './rejected.component.html',
  
  
})
export class RejectedComponent implements OnInit {

  constructor()
  {
    
  }
  //status:any;

  // constructor() { 
  //   this.status = localStorage.getItem("corporateApprovalStatus");
  // }

  ngOnInit() {
  }

}
