import { AfterViewInit, Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AdminEventsService } from '../../../shared/services/events.services';
import { AuthenticationService } from '../../../shared/services/authentication.service';
import { AdminMasterService } from '../../../shared/services/master.services';
import { MasterNGOService } from '../../../shared/services/masterNGO.service';
import { DatepickerOptions } from 'ng2-datepicker';
import { SelectOptionService } from '../../../shared/elements/select-option.service'
import * as _moment from 'moment';

@Component({
  selector: 'event-private-create',
  templateUrl: './event-private-create.component.html',
})

export class EventPrivateCreateComponent implements OnInit {

  adminLoginId: number;
  eventForm: FormGroup;
  validateForm: boolean = false;
  eventGoals: any[] = [];
  eventContributionTypes: any[] = [];
  states: any[] = [];
  cities: any[] = [];
  statusId: number = 2;
  countries: any[] = [];
  skillSets: any[] = [];
  skills: any[] = [];
  eventId: number;
  imageUrl: string;
  file: any;
  SearchedCorporates: any[] = [];
  SearchedNGOs: any[] = [];
  corporates: any[] = [];
  searchTerm: string;
  ngoSearchTerm: string;
  selectedNgoId: number;
  selectedNgo: any;
  options: DatepickerOptions = {
    minDate: new Date((new Date().setDate((new Date(Date.now()).getDate() - 1)))), // Minimal selectable date
  };
  optionsApplication: DatepickerOptions = {
    minDate: new Date(Date.now()), // Minimal selectable date
  };
  optionsStart: DatepickerOptions = {
    minDate: new Date((new Date().setDate((new Date(Date.now()).getDate() + 1)))), // Minimal selectable date
  };
  optionsEnd: DatepickerOptions = {
    minDate: new Date((new Date().setDate((new Date(Date.now()).getDate() + 1)))), // Minimal selectable date
  };
  optionsReporting: DatepickerOptions = {
    minDate: new Date((new Date().setDate((new Date(Date.now()).getDate() + 2)))), // Minimal selectable date
  };
  hours: any[];
  minutes: any[];
  startTimeHour: number = 0;
  startTimeMinute: number = 0;
  startTimeZone: number = 1;

  endTimeHour: number = 0;
  endTimeMinute: number = 0;
  endTimeZone: number = 2;
  errorMessage: string;
  showSkills: boolean = false;
  IsCertificateRequired: boolean = true;



  constructor(private _service: AdminEventsService, private authentication: AuthenticationService, private masterService: AdminMasterService, private router: Router, private route: ActivatedRoute, private ngoService: MasterNGOService, private selectOptionService: SelectOptionService) {
    var details = this.authentication.getToken();
    this.adminLoginId = details.access_token.adminLoginId;
    router.events.subscribe((response) => {
      this.eventId = route.snapshot.params['id'];
    });
    this.hours = this.selectOptionService.getHours();
    this.minutes = this.selectOptionService.getMinutes();
  }

    whiteSpaceValidator(
        control: FormControl
    ): { [key: string]: any } | null {
        var val = control.value;

        for (var i = 0; i <= val.length - 1; i++) {
            val = val.replace("&nbsp;", "");
        }
        if (val.trim() == "") {
            return { 'hasWhiteSpace': true }
        }
        else {
            return null;
        }
    }

  selectImage(event) {
    var files = event.target.files;
    this.file = files[0];
    //if (this.file.size <= 2 * 1024 * 1024) {
    var reader = new FileReader();
    reader.onload = (e: any) => {
      this.imageUrl = e.target.result;
    }
    reader.readAsDataURL(files[0]);
  }

  selectValueType(type: number) {
    if (type == 1) {
      this.showSkills = false;
    }
    else {
      this.showSkills = true;
    }
  }

  selectValueTypeforCertificate(type: number) {
    if (type == 2) {
      this.IsCertificateRequired = false;
    }
    else {
      this.IsCertificateRequired = true;

    }
  }

  createForm() {
    this.eventForm = new FormGroup({
      eventId: new FormControl(''),
      isPublic: new FormControl(false),
      statusId: new FormControl(2),
      pictureUrl: new FormControl(''),
      IsCertificateRequired:new FormControl(''),
        title: new FormControl('', Validators.compose([Validators.required, this.whiteSpaceValidator])),
        description: new FormControl('', Validators.compose([Validators.required, this.whiteSpaceValidator])),
      publishDate: new FormControl(new Date(), Validators.required),
      applicationDate: new FormControl(new Date((new Date().setDate((new Date(Date.now()).getDate() + 1)))), Validators.required),
      startDate: new FormControl(new Date((new Date().setDate((new Date(Date.now()).getDate() + 2)))), Validators.required),
      endDate: new FormControl(new Date((new Date().setDate((new Date(Date.now()).getDate() + 2)))), Validators.required),
      reportingDeadline: new FormControl(new Date((new Date().setDate((new Date(Date.now()).getDate() + 3)))), Validators.required),
      corporates: new FormControl([]),
      skillSets: new FormControl([]),
      domains: new FormControl(''),
      goalId: new FormControl('', Validators.required),
      eventContributionTypeId: new FormControl('', Validators.required),
      vacancy: new FormControl('', [Validators.required, Validators.min(0)]),
      bufferVacancy: new FormControl('', Validators.min(0)),
      hours: new FormControl('', [Validators.required, Validators.min(0)]),
      cappedHours: new FormControl(0),
      value: new FormControl('', Validators.required),
      skills: new FormControl(''),
      organiserName: new FormControl(''),
      organiserEmail: new FormControl(''),
      organiserMobileNumber: new FormControl(''),
      organiserNgoname: new FormControl(''),
      masterNgoId: new FormControl(0),
      address: new FormGroup({
        addressId: new FormControl(0),
        location: new FormControl('', Validators.required),
        area: new FormControl('', Validators.required),
        zipcode: new FormControl('', Validators.required),
        cityId: new FormControl('', Validators.required),
        stateId: new FormControl('', Validators.required),
        countryId: new FormControl('', Validators.required),
        latitude: new FormControl('', Validators.required),
        longitude: new FormControl('', Validators.required),
      })
    }, { validators: [this.dateLessThan('publishDate', 'applicationDate'), this.startdateLessThan('applicationDate', 'startDate'), this.enddateLessThan('startDate', 'endDate'), this.reportdateLessThan('endDate', 'reportingDeadline')] });
  }

  setOptions(controlName: string, evnt: any) {
    switch (controlName) {
      case 'publish':
        this.optionsApplication = {
          minDate: new Date((new Date().setDate((new Date(evnt).getDate())))), // Minimal selectable date
        };
        break;

      case 'application':
        this.optionsStart = {
          minDate: new Date((new Date().setDate((new Date(evnt).getDate())))), // Minimal selectable date
        };
        break;

      case 'start':
        this.optionsEnd = {
          minDate: new Date(evnt), // Minimal selectable date
        };
        break;

      case 'end':
        this.optionsReporting = {
          minDate: new Date((new Date().setDate((new Date(evnt).getDate())))), // Minimal selectable date
        };
        break;

      default:
        break;
    }
  }


  dateLessThan(from: string, to: string) {
    return (group: FormGroup): { [key: string]: any } => {
      let f = group.controls[from];
      let t = group.controls[to];
        var fromDate = _moment(f.value).format('YYYY-MM-DD');
        var toDate = _moment(t.value).format('YYYY-MM-DD');
        if (fromDate > toDate) {
        return {
          applicationDate: "Date from should be less than Date to"
        };
      }
      return {};
    }
  }

  startdateLessThan(from: string, to: string) {
    return (group: FormGroup): { [key: string]: any } => {
      let f = group.controls[from];
      let t = group.controls[to];
        var fromDate = _moment(f.value).format('YYYY-MM-DD');
        var toDate = _moment(t.value).format('YYYY-MM-DD');
        if (fromDate > toDate) {
        return {
          startDate: "Date from should be less than Date to"
        };
      }
      return {};
    }
  }

  enddateLessThan(from: string, to: string) {
    return (group: FormGroup): { [key: string]: any } => {
      let f = group.controls[from];
      let t = group.controls[to];
        var fromDate = _moment(f.value).format('YYYY-MM-DD');
        var toDate = _moment(t.value).format('YYYY-MM-DD');
        if (fromDate > toDate) {
        return {
          endDate: "Date from should be less than Date to"
        };
      }
      return {};
    }
  }

  reportdateLessThan(from: string, to: string) {
    return (group: FormGroup): { [key: string]: any } => {
      let f = group.controls[from];
      let t = group.controls[to];
        var fromDate = _moment(f.value).format('YYYY-MM-DD');
        var toDate = _moment(t.value).format('YYYY-MM-DD');
        if (fromDate > toDate) {
        return {
          reportingDeadline: "Date from should be less than Date to"
        };
      }
      return {};
    }
  }

  searchCorporates() {
    if (this.searchTerm) {
      var obj = { SearchTerm: this.searchTerm, PageSize: 10, PageNumber: 1 };
      this._service.searchCorporate(obj).subscribe(response => {
        if (response.isSuccess) {
          this.SearchedCorporates = response.list;
        }
      });
    }
    else {
      this.SearchedCorporates = [];
    }
  }

  selectcorporate(corporate: any) {
    let isExist = false;
    for (var i = 0; i < this.corporates.length; i++) {
      if (corporate.corporateId == this.corporates[i].corporateId) {
        isExist = true;
        break;
      }
    }
    if (!isExist) {
      this.corporates.push(corporate);
    }
    this.searchTerm = '';
    this.SearchedCorporates = [];
  }

  removeCorporate(index: number) {
    this.corporates.splice(index, 1);
  }

  searchNGO() {
    var obj = { SearchTerm: this.ngoSearchTerm, PageSize: 1000, PageNumber: 1 };
    this.ngoService.searchNGO(obj).subscribe(response => {
      if (response.isSuccess) {
        this.SearchedNGOs = response.list;
        console.log(this.SearchedNGOs);
      }
    });
  }

  selectNGO(ngoId: number) {
    this.errorMessage = '';
    let ngo = this.SearchedNGOs.filter(a => a.masterNgoId == ngoId)[0];
    this.selectedNgoId = ngo.masterNgoId;
    this.selectedNgo = ngo;
    this.ngoSearchTerm = '';
  }

  saveAsDraft() {
    this.statusId = 1;
  }
  deleteImage() {
    this.imageUrl = null;
  }

  getLatLongFromAddress() {
    this._service.getLatLong(this.eventForm.controls.address.value).subscribe(response => {
      if (response.isSuccess) {
        this.eventForm.controls.address.setValue({
          addressId: this.eventForm.controls.address.value.addressId,
          location: this.eventForm.controls.address.value.location,
          area: this.eventForm.controls.address.value.area,
          zipcode: this.eventForm.controls.address.value.zipcode,
          cityId: this.eventForm.controls.address.value.cityId,
          stateId: this.eventForm.controls.address.value.stateId,
          countryId: this.eventForm.controls.address.value.countryId,
          latitude: response.latitude,
          longitude: response.longitude,
        });
      }
    });
  }


  save() {
    this.validateForm = true;
    if (this.eventForm.valid) {
      this.errorMessage = '';
      //if (!this.selectedNgoId) {
      //  this.errorMessage = "Please select a NGO.";
      //}
      //else {
        this.eventForm.get("statusId").setValue(this.statusId);
        this.eventForm.get("isPublic").setValue(true);
        this.eventForm.get("corporates").setValue(this.corporates);
        this.eventForm.get("skillSets").setValue(this.skillSets);
        this.eventForm.get("masterNgoId").setValue(this.selectedNgoId);
        this.eventForm.get("IsCertificateRequired").setValue(this.IsCertificateRequired);
        if (this.startTimeZone == 2) {
          this.startTimeHour = 12 + +this.startTimeHour;
        }
        if (this.endTimeZone == 2) {
          this.endTimeHour = 12 + +this.endTimeHour;
        }
        let startTime = new Date(this.eventForm.value.startDate);
        if (startTime.getDate() != new Date().getDate()) {
          startTime.setDate(startTime.getDate() + 1);
        }
        startTime.setUTCHours(this.startTimeHour);
        startTime.setUTCMinutes(this.startTimeMinute);
        let endTime = new Date(this.eventForm.value.endDate);
        if (endTime.getDate() != new Date().getDate()) {
          endTime.setDate(endTime.getDate() + 1);
        }
        endTime.setUTCHours(this.endTimeHour);
        endTime.setUTCMinutes(this.endTimeMinute);
        this.eventForm.value.startDate = startTime;
        this.eventForm.value.endDate = endTime;
        let publishDate = new Date(this.eventForm.value.publishDate);
        var date = publishDate.toUTCString();
        console.log(this.eventForm.value.publishDate);
        if (publishDate.getDate() != new Date().getDate()) {
          publishDate.setDate(publishDate.getDate() + 1);
        }
        this.eventForm.value.publishDate = publishDate;
        let applicationDate = new Date(this.eventForm.value.applicationDate);
        if (applicationDate.getDate() != new Date().getDate()) {
          applicationDate.setDate(applicationDate.getDate() + 1);
        }
        this.eventForm.value.applicationDate = applicationDate;
        let reportingDeadline = new Date(this.eventForm.value.reportingDeadline);
        if (reportingDeadline.getDate() != new Date().getDate()) {
          reportingDeadline.setDate(reportingDeadline.getDate() + 1);
        }
        this.eventForm.value.reportingDeadline = reportingDeadline;
      console.log(this.eventForm.value);
      let html = this.eventForm.value.description;

      var oParser = new DOMParser();
      var oDOM = oParser.parseFromString(html, "text/html");
      var text = oDOM.body.innerText;
      this.eventForm.value.description = text;
        this._service.createSuperAdminEvent(this.eventForm.value).subscribe(response => {
          if (response.isSuccess) {
            if (this.file != null && this.file != undefined) {
              // upload image
              var obj = { eventId: response.data.eventId, file: this.file };
              this._service.uploadImage(obj).subscribe(response => {
                if (response.isSuccess) {
                  this.imageUrl = response.data.pictureUrl;
                }
              });
            }
            this.router.navigate(['/sa/events/private']);
          }
        });
      }
    //}
  }
  getCities(stateId: number) {
    var obj = { PageNumber: 1, PageSize: 500, stateId: stateId };
    this.masterService.getCities(obj).subscribe(response => {
      this.cities = response.data;
    });
  }
  selectContributionType(typeId){
     if(typeId == 2){
      this.eventForm.controls["cappedHours"].clearValidators();
      this.eventForm.controls["cappedHours"].disable();
      this.eventForm.controls["cappedHours"].setValue(0);     
    }
    else{
      this.eventForm.controls["cappedHours"].enable();
      this.eventForm.controls["cappedHours"].setValidators(Validators.min(0));
    }

  }
  getStates(countryId: number) {
    var obj = { pageNumber: 1, PageSize: 500, CountryId: countryId };
    this.masterService.getStates(obj).subscribe(response => {
      this.states = response.data;
    });
  }

  getEventDetails() {
    var obj = { EventId: this.eventId };
    this._service.getSuperAdminEventDetails(obj).subscribe(response => {
      if (response.isSuccess) {
        var detail = response.data;
        this.imageUrl = detail.pictureUrl;
        var corporates = detail.corporates;
        this.skillSets = detail.skillSets;
        if (corporates != null && corporates.length > 0) {
          this.corporates = corporates;
        }
        if (detail.masterNgo != null) {
          this.selectedNgoId = detail.masterNgo.masterNgoId;
          this.selectedNgo = detail.masterNgo;
        }
        this.eventForm.setValue({
          eventId: detail.eventId,
          isPublic: detail.isPublic,
          statusId: detail.statusId,
          IsCertificateRequired: detail.IsCertificateRequired,
          pictureUrl: detail.pictureUrl,
          title: detail.title,
          description: detail.description,
          publishDate: new Date(detail.publishDate),
          applicationDate: new Date(detail.applicationDate),
          startDate: new Date(detail.startDate),
          endDate: new Date(detail.endDate),
          corporates: this.corporates,
          skillSets: this.skillSets,
          domains: detail.domains,
          reportingDeadline: new Date(detail.reportingDeadline),
          goalId: detail.goalId,
          eventContributionTypeId: detail.eventContributionTypeId,
          vacancy: detail.vacancy,
          bufferVacancy: detail.bufferVacancy,
          hours: detail.hours,
          cappedHours: detail.cappedHours,
          value: detail.value,
          skills: detail.skills,
          organiserName: '',
          organiserEmail: '',
          organiserMobileNumber: '',
          organiserNgoname: '',
          masterNgoId: detail.masterNgo != null ? detail.masterNgo.masterNgoId : 0,
          address: {
            addressId: detail.address.addressId,
            location: detail.address.location,
            area: detail.address.area,
            zipcode: detail.address.zipcode,
            cityId: detail.address.cityId,
            stateId: detail.address.stateId,
            countryId: detail.address.countryId,
            latitude: detail.address.latitude,
            longitude: detail.address.longitude,
          }
        });
        if (detail.eventContributionTypeId == 2) {
          this.eventForm.controls["cappedHours"].clearValidators();
          this.eventForm.controls["cappedHours"].disable();
          this.eventForm.controls["cappedHours"].setValue(0);
        }
        else {
          this.eventForm.controls["cappedHours"].enable();
          this
          this.getStates(detail.address.countryId);
          this.getCities(detail.address.stateId);
          let startDate = new Date(detail.startDate);
          this.startTimeHour = startDate.getHours();
          this.startTimeMinute = startDate.getMinutes();
          this.startTimeZone = 1;
          if (this.startTimeHour > 12) {
            this.startTimeZone = 2;
            this.startTimeHour = this.startTimeHour - 12;
          }
          let endDate = new Date(detail.endDate);
          this.endTimeHour = endDate.getHours();
          this.endTimeMinute = endDate.getMinutes();
          this.endTimeZone = 1;
          if (this.endTimeHour > 12) {
            this.endTimeZone = 2;
            this.endTimeHour = this.endTimeHour - 12;
          }
        }
      }
    });
  }

  selectSkill(skillId: any) {
    var s = this.skills.filter(a => a.skillId == skillId);
    var skill = s[0];
    var exist = this.skillSets.filter(a => a.skillId == skill.skillId);
    if (exist.length == 0) {
      this.skillSets.push(skill);
    }
    this.calculateAverageValue();
  }

  removeSkill(index: number) {
    this.skillSets.splice(index, 1);
    this.calculateAverageValue();
  }

  calculateAverageValue() {
    var value = 0;
    for (var i = 0; i < this.skillSets.length; i++) {
      value += +this.skillSets[i].rate;
    }
    var avg = value / this.skillSets.length;
    this.eventForm.controls["value"].setValue(avg);
  }


  ngOnInit() {
    var obj = { pageNumber: 1, PageSize: 500 };
    this.masterService.getCountries(obj).subscribe(response => {
      this.countries = response.data;
    });
    this._service.getEventGoals(obj).subscribe(response => {
      this.eventGoals = response.list;
    });
    this._service.getEventContributionTypes(obj).subscribe(response => {
      this.eventContributionTypes = response.list;
    });
    this.masterService.getSkills(obj).subscribe(response => {
      if (response.isSuccess) {
        this.skills = response.list;
      }
    });
    this.createForm();
    if (this.eventId > 0) {
      this.getEventDetails();
    }
    this.searchNGO();
  }
}
