import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../shared/services/authentication.service';
import { Router, ActivatedRoute,NavigationStart, NavigationEnd, NavigationCancel, NavigationError } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrManager, Toastr } from 'ng6-toastr-notifications';
import { sharedStylesheetJitUrl } from '@angular/compiler';
import { element } from 'protractor';

@Component({
    selector: 'app-otp',
    templateUrl: 'otp.component.html',
    styleUrls: ['./otp.componet.scss']
})
export class OtpComponent implements OnInit {
    otpForm: FormGroup;
    email: string = '';
    mobileNumber: string = '';
    userId: any;
    isSubmitting:boolean = false;
    isUnsubscribedUser: any;
    unsubscribeType: any;
    newUserId: any;
    userCredentials: any;
    unsubscribe: any;
    loadervisible:boolean ;
    isNewRegistration:boolean = false;

    constructor(
        private _service: AuthenticationService,
        private router: Router,
        private activeRoute: ActivatedRoute,
        private toastr: ToastrManager
    ) {
        this.userCredentials = JSON.parse(localStorage.getItem("userCredentials"));
        this.unsubscribe = JSON.parse(localStorage.getItem('unsubscribedUser'));
        if(this.userCredentials==undefined || this.userCredentials==null)
        {
            this.userCredentials = this.unsubscribe;
        }
        else{
            this.email = this.userCredentials.email;
            this.mobileNumber = this.userCredentials.mobileNumber;
        }
        localStorage.removeItem("corporateApprovalStatus");
    }

    createForm() {
        this.otpForm = new FormGroup({
            OtpDigit1: new FormControl(''),
            OtpDigit2: new FormControl(''),
            OtpDigit3: new FormControl(''),
            OtpDigit4: new FormControl(''),
        });
    }

    verifyOtp() {
        this.loadervisible = true;
        var odigit_1 = this.otpForm.controls.OtpDigit1.value;
        var odigit_2 = this.otpForm.controls.OtpDigit2.value;
        var odigit_3 = this.otpForm.controls.OtpDigit3.value;
        var odigit_4 = this.otpForm.controls.OtpDigit4.value;

        if (odigit_1 != '' && odigit_2 != '' && odigit_3 != '' && odigit_4 != '') 
        {
            var otp = odigit_1 + odigit_2 + odigit_3 + odigit_4;

            var otpRequest = null;
            
            var isNew = localStorage.getItem("IsNewRegistered");
            if(isNew != null)
            {
                this.isNewRegistration = isNew == "1" ? true : false; 
            }
            if(this.unsubscribe!=undefined && this.unsubscribe!=null)
            {      
                otpRequest = { ...this.unsubscribe,"OTP":otp,"IsNewRegistered":this.isNewRegistration }
            }
            else
            {
                otpRequest = {
                    "Email":this.email,
                    "MobileNumber":this.mobileNumber,
                    "OTP": otp,
                    "IsNewRegistered":this.isNewRegistration
                   
                }
            }

            this.isSubmitting = true;
         
            this._service.verifyOtp(otpRequest).subscribe(response => {

                    if (response.isSuccess) {
                        
                        if(response.accountType == 2 && response.userCorporateStatusId == 1){
                           
                            this.router.navigate(["non-approved"]);
                            localStorage.setItem("corporateApprovalStatus","1");
                            
                        }
                        else if(response.accountType == 2 && response.userCorporateStatusId == 3){
                            this.router.navigate(["non-approved"]);
                            localStorage.setItem("corporateApprovalStatus","2");
                        }
else{
    this._service.details = response;
    this._service.authorizeToken(response.email);
    var url = localStorage.getItem("navigationUrl");
    if(url != null)
    {
       
          localStorage.removeItem("navigationUrl");
        var redirect = url.substring(url.indexOf('/volunteer/'),url.length);                     
        this.router.navigate([redirect]);
    }
    else
    {

        localStorage.removeItem("userCredentials")
        localStorage.removeItem("unsubscribedUser")

        if (response.userRegistrationStatusId == 4) {
            this.router.navigate(["/volunteer/beneficiary-curriculum"]);
        }
        else {                       
           this.router.navigate(['/volunteer/dashboard']);
        }
    }
}
                       

                       
                    }
                    else {
                        this.toastr.errorToastr(response.responseMessage);
                        this.isSubmitting = false;
                        this.loadervisible = false;
                    }
                }, (err) => {
                    this.toastr.errorToastr("Some error occurred");
                    this.isSubmitting = false;
                    this.loadervisible = false;
                });
        }
        else{
            this.loadervisible = false;
        }

    }

    // moveNextControl(nextCtrlNo: string, event) {
    //     if (event.target.value != '') {
    //         console.log(event);
    //         let nextControlId = "odg" + nextCtrlNo;
    //         let element = document.getElementById(nextControlId) as HTMLElement;
    //         element.focus();
    //     }
        
    // }
    moveNextControl(nextCtrlNo: string, event) {        
        if (event.target.value != '') {  
            if(parseInt(nextCtrlNo) < 4)  {
                let nextControlId = "odg" + nextCtrlNo;
                let element = document.getElementById(nextControlId) as HTMLElement;
                element.focus();
            }
        }else{
            if(parseInt(nextCtrlNo) > 1)  {
            let nextControlId = "odg" + (parseInt(nextCtrlNo) - 2);
            let element = document.getElementById(nextControlId) as HTMLElement;
            element.focus();
            }
      
        }
    }

    movePreviousControl(nextCtrlNo: string, event){
        // console.log(event);
        // const key = event.key; // const {key} = event; ES6+
        // if (key === "Backspace" || key === "Delete") {
        //     // console.log(key);
        //     let nextControlId = "odg" + nextCtrlNo;
        //     let element = document.getElementById(nextControlId) as HTMLElement;
        //     element.focus()
        //     return true;
        // }        
    }

    

    resendOtp() {
        this._service.resendOtp({ "email": this.email, "mobileNumber": this.mobileNumber })
            .subscribe(res => {
                if (res) {
                    this.toastr.successToastr("Otp re-sent successfully");
                } else {
                    this.toastr.errorToastr("Otp not sent");
                }
            }, (err) => {
                this.toastr.errorToastr("Some Error Occured");
            });
    }
    // only number validation
    numberOnly(event): boolean {
        const charCode = (event.which) ? event.which : event.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            return false;
        }
        return true;

    }


    ngOnInit() {
        this.createForm();
    }

}
