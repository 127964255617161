import { AfterViewInit, Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AdminCampaignService } from '../../shared/services/campaigns.service';
import { AdminEventsService } from '../../shared/services/events.services';
import { AuthenticationService } from '../../shared/services/authentication.service';
import { AdminMasterService } from '../../shared/services/master.services';
import { DatepickerOptions } from 'ng2-datepicker';
import { Router, ActivatedRoute } from '@angular/router';
import * as _moment from 'moment';



@Component({
  selector: 'campaign-create',
  templateUrl: './campaign-create.component.html',
})

export class CampaignCreateComponent implements OnInit {
  adminLoginId: number;
  campaignForm: FormGroup;
  validateForm: boolean = false;
  statusId: number = 2;
  campiagnId: number;
  file: any;
  imageUrl: string;
  countries: any[] = [];
  SearchedCorporates: any[] = [];
  corporates: any[] = [];
  searchTerm: string;
  IsCertificateRequired: boolean = true;

    startDateOptions: DatepickerOptions = {
        minDate: new Date((new Date().setDate((new Date(Date.now()).getDate() - 1)))) // Minimal selectable date
    };
    endDateOptions: DatepickerOptions = {
        minDate: new Date((new Date().setDate((new Date(Date.now()).getDate() - 1)))) // Minimal selectable date
    };

  constructor(private _service: AdminCampaignService, private authentication: AuthenticationService, private router: Router, private route: ActivatedRoute, private eventService: AdminEventsService, private masterService: AdminMasterService) {
    var details = this.authentication.getToken();
    this.adminLoginId = details.access_token.adminLoginId;

    router.events.subscribe((response) => {
      this.campiagnId = route.snapshot.params['id'];
    });
  }

    whiteSpaceValidator(
        control: FormControl
    ): { [key: string]: any } | null {
        var val = control.value;

        for (var i = 0; i <= val.length - 1; i++) {
            val = val.replace("&nbsp;", "");
        }
        if (val.trim() == "") {
            return { 'hasWhiteSpace': true }
        }
        else {
            return null;
        }
    }

  selectImage(event) {
    var files = event.target.files;
    this.file = files[0];
    var reader = new FileReader();
    reader.onload = (e: any) => {
      this.imageUrl = e.target.result;
    }
    reader.readAsDataURL(files[0]);

  }
  createForm() {

    this.campaignForm = new FormGroup({
        title: new FormControl('', Validators.compose([Validators.required, this.whiteSpaceValidator])),
        description: new FormControl('', Validators.compose([Validators.required, this.whiteSpaceValidator])),
      startDate: new FormControl(new Date(), Validators.required),
      endDate: new FormControl(new Date(), Validators.required),
      corporates: new FormControl([]),
      countryId: new FormControl('', Validators.required),
      pictureUrl: new FormControl(''),
     IsCertificateRequired: new FormControl(''),

    }, { validators: this.dateLessThan('startDate', 'endDate') });
  }


  dateLessThan(from: string, to: string) {
    return (group: FormGroup): { [key: string]: any } => {
      let f = group.controls[from];
        let t = group.controls[to];
        var fromDate = _moment(f.value).format('YYYY-MM-DD');
        var toDate = _moment(t.value).format('YYYY-MM-DD');
        if (fromDate > toDate) {
        return {
          endDate: "Date from should be less than Date to"
        };
      }
      return {};
    }
    }


    setOptions(controlName: string, evnt: any) {
        switch (controlName) {
            case 'start':
                this.endDateOptions = {
                    minDate: new Date((new Date().setDate((new Date(evnt).getDate() - 1)))), // Minimal selectable date
                    
                };
                break;
            default:
                break;
        }
    }



  searchCorporates() {
    if (this.searchTerm) {
      var obj = { SearchTerm: this.searchTerm, PageSize: 10, PageNumber: 1 };
      this._service.searchCorporate(obj).subscribe(response => {
        if (response.isSuccess) {
          this.SearchedCorporates = response.list;
        }
      });
    }
    else {
      this.SearchedCorporates = [];
    }
  }

  selectcorporate(corporate: any) {
    let isExist = false;
    for (var i = 0; i < this.corporates.length; i++) {
      if (corporate.corporateId == this.corporates[i].corporateId) {
        isExist = true;
        break;
      }
    }
    if (!isExist) {
      this.corporates.push(corporate);
    }
    this.searchTerm = '';
    this.SearchedCorporates = [];
  }

   selectValueTypeforCertificate(type: number) {
    if (type == 2) {
      this.IsCertificateRequired = false;
    }
    else {
      this.IsCertificateRequired = true;

    }
  }

  removeCorporate(index: number) {
    this.corporates.splice(index, 1);
  }

  saveAsDraft() {
    this.statusId = 1;
  }

    save() {
       
        this.validateForm = true;
        console.log(this.campaignForm);
      if (this.campaignForm.valid) {
          console.log("called save****");
      this.campaignForm.value.IsCertificateRequired = this.IsCertificateRequired;
      this.campaignForm.value.adminLoginId = this.adminLoginId;
      this.campaignForm.value.statusId = this.statusId;
      this.campaignForm.value.campaignId = this.campiagnId;
      this.campaignForm.value.corporates = this.corporates;
      let startDate = new Date(this.campaignForm.value.startDate);
      if (startDate.getDate() != new Date().getDate()) {
        startDate.setDate(startDate.getDate() + 1);
      }
      this.campaignForm.value.startDate = startDate;
      let endDate = new Date(this.campaignForm.value.endDate);
      if (endDate.getDate() != new Date().getDate()) {
        endDate.setDate(endDate.getDate() + 1);
      }
      this.campaignForm.value.endDate = endDate;

      let html = this.campaignForm.value.description;

      var oParser = new DOMParser();
      var oDOM = oParser.parseFromString(html, "text/html");
      var text = oDOM.body.innerText;
      this.campaignForm.value.description = text;

      this._service.createSuperAdminCampaign(this.campaignForm.value).subscribe(response => {
        if (response.isSuccess) {

          if (this.file != null && this.file != undefined) {
            // upload image
            var obj = { campaignId: response.data.campaignId, file: this.file };
            this._service.uploadImage(obj).subscribe(response => {
              if (response.isSuccess) {
                this.imageUrl = response.data.imageUrl;
              }
            });
          }

          this.router.navigate(['/sa/campaigns']);
        }
      });
    }
  }

  getCampaignDetails() {
    var obj = { campaignId: this.campiagnId };
    this._service.getSuperAdminCampaignDetails(obj).subscribe(response => {
      if (response.isSuccess) {
        var detail = response.data;
        var corporates = detail.corporates;
        if (corporates != null && corporates.length > 0) {
          this.corporates = corporates;
        }
        this.imageUrl = response.data.imageUrl;
        this.campaignForm.setValue({
          title: detail.title,
          description: detail.description,
          startDate: new Date(detail.startDate),
          endDate: new Date(detail.endDate),
          corporates: this.corporates,
          countryId: detail.countryId,
          pictureUrl: detail.imageUrl,
          IsCertificateRequired: detail.IsCertificateRequired,
        });

      }
    });
  }

  canceltapped() {
    this.router.navigate(['/sa/campaigns']);
  }


  ngOnInit() {
    var obj = { pageNumber: 1, PageSize: 500 };
    this.masterService.getCountries(obj).subscribe(response => {
      this.countries = response.data;
    });
    this.createForm();
    if (this.campiagnId > 0) {
      this.getCampaignDetails();
    }

  }




}
