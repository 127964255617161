import { AfterViewInit, Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { MasterNGOService } from '../../shared/services/masterNGO.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AdminMasterService } from './../../shared/services/master.services';
import { FormControl, FormGroup, Validators, FormArray } from '@angular/forms';
//import { forEach } from '@angular/router/src/utils/collection';



@Component({
  selector: 'ngo-create',
  templateUrl: './ngo-create.component.html',
})

export class NGOCreateComponent implements OnInit {


  NGOForm: FormGroup;
  validateForm: boolean = false;
  masterNgoId: number;
  errorMessage: string;
  selectedThematicAreas: any[] = [];
  thematicAreas: any[] = [];
  groupThematicAreas: any[] = [];

  constructor(private router: Router, private _service: MasterNGOService, private route: ActivatedRoute, private masterService: AdminMasterService) {


    router.events.subscribe((response) => {
      this.masterNgoId = route.snapshot.params['id'];
    });


  }

  createForm() {
    this.NGOForm = new FormGroup({
      Name: new FormControl('', Validators.required),
      OwnerName: new FormControl('', Validators.required),
      Email: new FormControl('', [Validators.required, Validators.email]),
      MobileNumber: new FormControl('', [Validators.required, Validators.minLength(10)]),
      Location: new FormControl('', Validators.required),
      ThematicAreas: new FormControl('')
    });
  }

  save() {
    this.validateForm = true;
    console.log(this.NGOForm);
    if (this.NGOForm.valid) {
      this.NGOForm.controls["ThematicAreas"].setValue(this.selectedThematicAreas);
      if (this.masterNgoId > 0) {
        this.NGOForm.value.masterNgoId = this.masterNgoId;

        this._service.updateNGO(this.NGOForm.value).subscribe(response => {
          if (response.isSuccess) {
            this.router.navigate(['/sa/ngo']);
          }
          if (response.isExist) {
            this.errorMessage = 'NGO with this name or email already exist.'
          }
        });
      }
      else {
        this._service.createNGO(this.NGOForm.value).subscribe(response => {
          console.log(response);
          if (response.isSuccess) {
            this.router.navigate(['/sa/ngo']);
          }
          if (response.isExist) {
            this.errorMessage = 'NGO with this name or email already exist.'
          }
        });
      }

    }
  }
  getNGODetail() {

    var obj = { masterNgoId: this.masterNgoId };
    this._service.getNGODetail(obj).subscribe(response => {
      if (response.isSuccess) {
        var detail = response.data;
        this.selectedThematicAreas = [];
        if (detail.ngoThematicAreaMapping) {
          for (var i = 0; i < detail.ngoThematicAreaMapping.length; i++) {
            if (detail.ngoThematicAreaMapping[i].isDeleted != true) {
              this.selectedThematicAreas.push(detail.ngoThematicAreaMapping[i].thematicArea);
            }
          }
        }

        this.NGOForm.setValue({
          Name: detail.name,
          OwnerName: detail.ownerName,
          Email: detail.email,
          MobileNumber: detail.mobileNumber,
          Location: detail.location,
          ThematicAreas: this.selectedThematicAreas
        });
      }
    });
  }

  selectThematicArea(id: number) {
    var thematicArea = this.thematicAreas.filter(a => a.thematicAreaId == id);
    this.selectedThematicAreas.push(thematicArea[0]);
  }

  deleteThematicArea(index: number) {
    this.selectedThematicAreas.splice(index, 1);
  }

  groupBy = function (xs, key) {
    return xs.reduce(function (rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  };

  ngOnInit() {
    if (this.masterNgoId > 0) {
      this.getNGODetail();
    }
    this.createForm();
    let obj = {};
    this.masterService.getThematicAreas(obj).subscribe(response => {
      if (response.isSuccess) {
        this.thematicAreas = response.list;
        var groupthematicAreas = this.groupBy(this.thematicAreas, "category");
        var areas = [];
        for (var c in groupthematicAreas) {
          if (groupthematicAreas[c].length > 0) {
            var area = { category: c, areas: groupthematicAreas[c] };
            areas.push(area);
          }
        }
        this.groupThematicAreas = areas;
      }
    });
  }

}
