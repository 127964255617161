import { AfterViewInit, Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { AdminVolunteerService } from '../../shared/services/volunteer.service';
import { AuthenticationService } from '../../shared/services/authentication.service';
import { WallPostService } from '../../shared/services/wallpost.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { collectExternalReferences } from '@angular/compiler';



@Component({
  selector: 'wallpost-list',
  templateUrl: './wallpost-list.component.html',
})

export class WallPostListComponent implements OnInit {

  adminLoginId: number;
  corporateId: number;
  myfeed: any;
  userfeed: any;
  adminfeed: any;
  status: string;

  // MYFEED Pagination Variable

  myfeedpageNumber: number = 1;
  myfeedpageSize: number = 10;
  myfeedstartFrom: number = 1;
  myfeedcount: number;
  myfeedendOn: number = this.myfeedpageSize;
  myfeedlastPageClass: string;
  myfeedfirstPageClass: string = "disabled";
  myfeedpreviousPageClass: string = "disabled";
  myfeednextPageClass: string;
  myfeednumberOfPages: number;

  // USER FEED Pagination Variables

  userpageNumber: number = 1;
  userpageSize: number = 10;
  userstartFrom: number = 1;
  usercount: number;
  userendOn: number = this.myfeedpageSize;
  userlastPageClass: string;
  userfirstPageClass: string = "disabled";
  userpreviousPageClass: string = "disabled";
  usernextPageClass: string;
  usernumberOfPages: number;

  // ADMIN FEED Pagination Variables

  adminpageNumber: number = 1;
  adminpageSize: number = 10;
  adminstartFrom: number = 1;
  admincount: number;
  adminendOn: number = this.adminpageSize;
  adminlastPageClass: string;
  adminfirstPageClass: string = "disabled";
  adminpreviousPageClass: string = "disabled";
  adminnextPageClass: string;
  adminnumberOfPages: number;




  constructor(private authentication: AuthenticationService, private _service: WallPostService, private router: Router, private route: ActivatedRoute) {
    var details = this.authentication.getToken();
    this.adminLoginId = details.access_token.adminLoginId;
    this.corporateId = null;
  }



  getUserfeeds() {
    document.getElementById("userfeed").className = "tab-pane active";
    document.getElementById("myfeed").className = "tab-pane";
    document.getElementById("adminfeed").className = "tab-pane";
    var obj = { corporateId: this.corporateId, IsAdminPost: false, PageSize: this.userpageSize, PageNumber: this.userpageNumber };
    console.log(obj)
    this._service.getWallPostForSuperAdminMyfeed(obj).subscribe(response => {
      if (response.isSuccess) {
        this.myfeed = response.list;
        this.usercount = response.count;
        if (this.usercount == 0) {
          this.userstartFrom = 0;
        }
        else {
          this.userstartFrom = ((this.userpageNumber - 1) * this.userpageSize) + 1;
        }
        if (this.usercount <= this.userpageSize) {
          this.userendOn = this.usercount;
          this.usernextPageClass = "disabled";
          this.userlastPageClass = "disabled";
        }
        else {
          this.userendOn = this.userpageNumber * this.userpageSize;
        }
        var pages = this.usercount / this.userpageSize;
        this.usernumberOfPages = 0;
        for (var i = 0; i < pages; i++) {
          this.usernumberOfPages = i + 1;
        }
        if (this.userendOn > this.usercount) {
          this.userendOn = this.usercount;
        }

      }
    });
  }

  userFirstPage() {
    if (this.userpageNumber > 1) {
      this.userfirstPageClass = "disabled";
      this.userpreviousPageClass = "disabled";
      this.usernextPageClass = "";
      this.userlastPageClass = "";
      this.userpageNumber = 1;
      this.getUserfeeds();
    }
  }

  userLastPage() {
    if (this.userpageNumber < this.usernumberOfPages) {
      this.userfirstPageClass = "";
      this.userpreviousPageClass = "";
      this.usernextPageClass = "disabled";
      this.userlastPageClass = "disabled";
      this.userpageNumber = this.usernumberOfPages;
      this.getUserfeeds();
    }
  }

  userPreviousPage() {
    if (this.userpageNumber > 1) {
      this.userpageNumber = this.userpageNumber - 1;
      if (this.userpageNumber == 1) {
        this.userfirstPageClass = "disabled";
        this.userpreviousPageClass = "disabled";
        this.usernextPageClass = "";
        this.userlastPageClass = "";
      }
      this.getUserfeeds();
    }
  }

  userNextPage() {

    if (this.userpageNumber < this.usernumberOfPages) {
      this.userpageNumber = this.userpageNumber + 1;
      console.log(this.userpageNumber);
      console.log(this.usernumberOfPages);
      if (this.userpageNumber == this.usernumberOfPages) {
        this.userfirstPageClass = "";
        this.userpreviousPageClass = "";
        this.usernextPageClass = "disabled";
        this.userlastPageClass = "disabled";
      }
      else {
        this.userfirstPageClass = "";
        this.userpreviousPageClass = "";
        this.usernextPageClass = "";
        this.userlastPageClass = "";
      }
      this.getUserfeeds();
    }
  }

  // userFirstPage() {
  //   if (this.userpageNumber > 1) {
  //     this.userfirstPageClass = "disabled";
  //     this.userpreviousPageClass = "disabled";
  //     this.usernextPageClass = "";
  //     this.userlastPageClass = "";
  //     this.userpageNumber = 1;
  //     this.getUserfeeds();
  //   }
  // }
  // userLastPage() {
  //   if (this.userpageNumber < this.usernumberOfPages) {
  //     this.userfirstPageClass = "";
  //     this.userpreviousPageClass = "";
  //     this.usernextPageClass = "disabled";
  //     this.userlastPageClass = "disabled";
  //     this.userpageNumber = this.adminnumberOfPages;
  //     this.getUserfeeds();
  //   }
  // }

  // userPreviousPage() {
  //   if (this.userpageNumber > 1) {
  //     this.userpageNumber = this.userpageNumber - 1;
  //     if (this.userpageNumber == 1) {
  //       this.userfirstPageClass = "disabled";
  //       this.userpreviousPageClass = "disabled";
  //       this.usernextPageClass = "";
  //       this.userlastPageClass = "";
  //     }
  //     this.getUserfeeds();
  //   }
  // }

  // userNextPage() {

  //   if (this.userpageNumber < this.usernumberOfPages) {
  //     this.userpageNumber = this.userpageNumber + 1;
  //     if (this.userpageNumber == this.usernumberOfPages) {
  //       this.userfirstPageClass = "";
  //       this.userpreviousPageClass = "";
  //       this.usernextPageClass = "disabled";
  //       this.userlastPageClass = "disabled";
  //     }
  //     this.getUserfeeds();
  //   }
  // }

  // GET MY FEEDS 
  getMyfeeds() {
    document.getElementById("userfeed").className = "tab-pane";
    document.getElementById("myfeed").className = "tab-pane active";
    document.getElementById("adminfeed").className = "tab-pane";
    var obj = { corporateId: this.corporateId, IsAdminPost: true, pagenumber: this.myfeedpageNumber, pagesize: this.myfeedpageSize };
    this._service.getWallPostForSuperAdminMyfeed(obj).subscribe(response => {
      if (response.isSuccess) {
        this.userfeed = response.list;
        this.myfeedcount = response.count;
        if (this.myfeedcount == 0) {
          this.myfeedstartFrom = 0;
        }
        else {
          this.myfeedstartFrom = ((this.myfeedpageNumber - 1) * this.myfeedpageSize) + 1;
        }
        if (this.myfeedcount <= this.myfeedpageSize) {
          this.myfeedendOn = this.myfeedcount;
          this.myfeednextPageClass = "disabled";
          this.myfeedlastPageClass = "disabled";
        }
        else {
          this.myfeedendOn = this.myfeedpageNumber * this.myfeedpageSize;
        }
        var pages = this.myfeedcount / this.myfeedpageSize;
        this.myfeednumberOfPages = 0;
        for (var i = 0; i < pages; i++) {
          this.myfeednumberOfPages = i + 1;
        }
        if (this.myfeedendOn > this.myfeedcount) {
          this.myfeedendOn = this.myfeedcount;
        }

      }
    });
  }
  myfeedFirstPage() {
    if (this.myfeedpageNumber > 1) {
      this.myfeedfirstPageClass = "disabled";
      this.myfeedpreviousPageClass = "disabled";
      this.myfeednextPageClass = "";
      this.myfeedlastPageClass = "";
      this.myfeedpageNumber = 1;
      this.getMyfeeds();
    }
  }

  myfeedLastPage() {
    if (this.myfeedpageNumber < this.myfeednumberOfPages) {
      this.myfeedfirstPageClass = "";
      this.myfeedpreviousPageClass = "";
      this.myfeednextPageClass = "disabled";
      this.myfeedlastPageClass = "disabled";
      this.myfeedpageNumber = this.myfeednumberOfPages;
      this.getMyfeeds();
    }
  }

  myfeedPreviousPage() {
    if (this.myfeedpageNumber > 1) {
      this.myfeedpageNumber = this.myfeedpageNumber - 1;
      if (this.myfeedpageNumber == 1) {
        this.myfeedfirstPageClass = "disabled";
        this.myfeedpreviousPageClass = "disabled";
        this.myfeednextPageClass = "";
        this.myfeedlastPageClass = "";
      }
      this.getMyfeeds();
    }
  }

  myfeedNextPage() {

    if (this.myfeedpageNumber < this.myfeednumberOfPages) {
      this.myfeedpageNumber = this.myfeedpageNumber + 1;
      if (this.myfeedpageNumber == this.myfeednumberOfPages) {
        this.myfeedfirstPageClass = "";
        this.myfeedpreviousPageClass = "";
        this.myfeednextPageClass = "disabled";
        this.myfeedlastPageClass = "disabled";
      }
      else {
        this.myfeedfirstPageClass = "";
        this.myfeedpreviousPageClass = "";
        this.myfeednextPageClass = "";
        this.myfeedlastPageClass = "";
      }
      this.getMyfeeds();
    }
  }


  getAdminFeeds() {
    document.getElementById("userfeed").className = "tab-pane";
    document.getElementById("myfeed").className = "tab-pane";
    document.getElementById("adminfeed").className = "tab-pane active";
    //  this.adminstartFrom = ((this.adminpageNumber - 1) * this.adminpageSize) + 1;
    var obj = { corporateId: this.corporateId, IsAdminPost: true, pagenumber: this.adminpageNumber, pagesize: this.adminpageSize };
    console.log(obj);
    this._service.getWallPostForSuperAdminCorporate(obj).subscribe(response => {
      if (response.isSuccess) {
        this.adminfeed = response.list;
        this.admincount = response.count;
        if (this.admincount == 0) {
          this.adminstartFrom = 0;
        }
        else {
          this.adminstartFrom = ((this.adminpageNumber - 1) * this.adminpageSize) + 1;
        }
        if (this.admincount <= this.adminpageSize) {
          this.adminendOn = this.admincount;
          this.adminnextPageClass = "disabled";
          this.adminlastPageClass = "disabled";
        }
        else {
          this.adminendOn = this.adminpageNumber * this.adminpageSize;
        }
        var pages = this.admincount / this.adminpageSize;
        this.adminnumberOfPages = 0;
        for (var i = 0; i < pages; i++) {
          this.adminnumberOfPages = i + 1;
        }
        if (this.adminendOn > this.admincount) {
          this.adminendOn = this.admincount;
        }



      }
    });

  }
  adminFirstPage() {
    if (this.adminpageNumber > 1) {
      this.adminfirstPageClass = "disabled";
      this.adminpreviousPageClass = "disabled";
      this.adminnextPageClass = "";
      this.adminlastPageClass = "";
      this.adminpageNumber = 1;
      this.getAdminFeeds();
    }
  }

  adminLastPage() {
    if (this.adminpageNumber < this.adminnumberOfPages) {
      this.adminfirstPageClass = "";
      this.adminpreviousPageClass = "";
      this.adminnextPageClass = "disabled";
      this.adminlastPageClass = "disabled";
      this.adminpageNumber = this.adminnumberOfPages;
      this.getAdminFeeds();
    }
  }

  adminPreviousPage() {
    if (this.adminpageNumber > 1) {
      this.adminpageNumber = this.adminpageNumber - 1;
      if (this.adminpageNumber == 1) {
        this.adminfirstPageClass = "disabled";
        this.adminpreviousPageClass = "disabled";
        this.adminnextPageClass = "";
        this.adminlastPageClass = "";
      }
      this.getAdminFeeds();
    }
  }

  adminNextPage() {

    if (this.adminpageNumber < this.adminnumberOfPages) {
      this.adminpageNumber = this.adminpageNumber + 1;
      if (this.adminpageNumber == this.adminnumberOfPages) {
        this.adminfirstPageClass = "";
        this.adminpreviousPageClass = "";
        this.adminnextPageClass = "disabled";
        this.adminlastPageClass = "disabled";
      }
      else {
        this.adminfirstPageClass = "";
        this.adminpreviousPageClass = "";
        this.adminnextPageClass = "";
        this.adminlastPageClass = "";
      }
      this.getAdminFeeds();
    }
  }


  deleteWallPostFeed(wallPostId: number, MyfeedDelete: number) {
    console.log("Delete Call");
    var obj = { wallPostId: wallPostId };
    console.log(obj);
    console.log(MyfeedDelete);



    this._service.deleteWallPostFeed(obj).subscribe(response => {
      if (response.isSuccess) {

        switch (MyfeedDelete) {
          case 1: {
            this.getUserfeeds();
            break;
          }
          case 2: {
            this.getMyfeeds();
            break;
          }
          case 3: {
            this.getAdminFeeds();;
            break;
          }
          default: {
            this.getUserfeeds();
            break;
          }
        }





      }
    });

  }

  ngOnInit() {
    this.getUserfeeds();
  }


}
