
import {throwError as observableThrowError,  Observable } from 'rxjs';

import {catchError, tap} from 'rxjs/operators';
import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';



import { Router } from '@angular/router';
import { environment } from './../../../environments/environment';
@Injectable()
export class AdminCampaignService {

  constructor(private router: Router, private http: HttpClient) { }

  searchCorporate(obj): Observable<any> {
    return this.http.post<any>(environment.apiBasePath + '/Corporate/Search', obj).pipe(
      tap(res => res),
      catchError(this.handleError),);
  }

  getAllCampaigns(obj): Observable<any> {
    return this.http.post<any>(environment.apiBasePath + '/campaign/GetAll', obj).pipe(
      tap(res => res),
      catchError(this.handleError),);
  }

  createCampaigns(obj): Observable<any> {
    return this.http.post<any>(environment.apiBasePath + '/campaign/create', obj).pipe(
      tap(res => res),
      catchError(this.handleError),);
  }
   createCampaignsTask(obj): Observable<any> {
    return this.http.post<any>(environment.apiBasePath + '/campaign/CreateTask', obj).pipe(
      tap(res => res),
      catchError(this.handleError),);
  }


  getAllCampaignsTaskList(obj): Observable<any> {
    return this.http.post<any>(environment.apiBasePath + '/campaign/GetTasks', obj).pipe(
      tap(res => res),
      catchError(this.handleError),);
  }
  pushNotificationforCampaignsTaskUser(obj): Observable<any> {
    return this.http.post<any>(environment.apiBasePath + '/campaign/SendPNtoTaskUsers', obj).pipe(
      tap(res => res),
      catchError(this.handleError),);
  }
   pushNotificationforCampaignsUser(obj): Observable<any> {
    return this.http.post<any>(environment.apiBasePath + '/campaign/SendPNtoCampaignUsers', obj).pipe(
      tap(res => res),
      catchError(this.handleError),);
  }
  getGetTaskDetail(obj): Observable<any> {
    return this.http.post<any>(environment.apiBasePath + '/campaign/GetTaskDetail', obj).pipe(
      tap(res => res),
      catchError(this.handleError),);
  }


  AddTaskuser(obj): Observable<any> {
    return this.http.post<any>(environment.apiBasePath + '/campaign/AddTaskUsers', obj).pipe(
      tap(res => res),
      catchError(this.handleError),);
  }
  deleteCampaignTask(obj): Observable<any> {
    return this.http.post<any>(environment.apiBasePath + '/campaign/DeleteTask', obj).pipe(
      tap(res => res),
      catchError(this.handleError),);
  }
  deleteCampaign(obj): Observable<any> {
    return this.http.post<any>(environment.apiBasePath + '/campaign/DeleteCampaign', obj).pipe(
      tap(res => res),
      catchError(this.handleError),);
  }

  getCampaignDetail(obj): Observable<any> {
    return this.http.post<any>(environment.apiBasePath + '/campaign/GetCampaignDetail', obj).pipe(
      tap(res => res),
      catchError(this.handleError),);
  }


  performAction(obj): Observable<any> {
    return this.http.post<any>(environment.apiBasePath + '/campaign/PerformAction', obj).pipe(
      tap(res => res),
      catchError(this.handleError),);
  }

  PerformTaskAction(obj): Observable<any> {
    return this.http.post<any>(environment.apiBasePath + '/campaign/PerformTaskAction', obj).pipe(
      tap(res => res),
      catchError(this.handleError),);
  }

  getSuperAdminCampaigns(obj): Observable<any> {
    return this.http.post<any>(environment.apiBasePath + '/campaign/GetSuperAdminCampaign', obj).pipe(
      tap(res => res),
      catchError(this.handleError),);
  }

  createSuperAdminCampaign(obj): Observable<any> {
    return this.http.post<any>(environment.apiBasePath + '/campaign/CreateSuperAdminCampaign', obj).pipe(
      tap(res => res),
      catchError(this.handleError),);
  }

  createSuperAdminTask(obj): Observable<any> {
    return this.http.post<any>(environment.apiBasePath + '/campaign/CreateSuperAdminTask', obj).pipe(
      tap(res => res),
      catchError(this.handleError),);
  }

  getSuperAdminCampaignDetails(obj): Observable<any> {
    return this.http.post<any>(environment.apiBasePath + '/campaign/GetSuperAdminCampaignDetail', obj).pipe(
      tap(res => res),
      catchError(this.handleError),);
  }


  performActionOnUser(obj): Observable<any> {
    return this.http.post<any>(environment.apiBasePath + '/campaign/PerformActionOnUser', obj).pipe(
      tap(res => res),
      catchError(this.handleError),);
  }
  
  uploadImage(obj): Observable<any> {

    const formData: FormData = new FormData();
    formData.append('CampaignId', obj.campaignId);
    formData.append('UploadFile', obj.file, obj.file.name);

    return this.http.post<any>(environment.apiBasePath + '/campaign/UploadImage', formData).pipe(
      tap(res => res),
      catchError(this.handleError),);
  }
  UploadTaskImage(obj): Observable<any> {

    const formData: FormData = new FormData();
    formData.append('CampaignId', obj.campaignId);
    formData.append('TaskId', obj.TaskId);
    formData.append('UploadFile', obj.file, obj.file.name);

    return this.http.post<any>(environment.apiBasePath + '/campaign/UploadTaskImage', formData).pipe(
      tap(res => res),
      catchError(this.handleError),);
  }

    getSuperAdminCampaignCsv(obj): Observable<any> {
        return this.http.post<any>(environment.apiBasePath + '/campaign/GetSuperAdminCampaignListCSV', obj).pipe(
            tap(res => res),
            catchError(this.handleError),);
    }
    getCampaignCsv(obj): Observable<any> {
        return this.http.post<any>(environment.apiBasePath + '/campaign/GetAdminCampaignListCSV', obj).pipe(
            tap(res => res),
            catchError(this.handleError),);
    }
    getCampaignTaskCsv(obj): Observable<any> {
        return this.http.post<any>(environment.apiBasePath + '/campaign/GetTasksListCsv', obj).pipe(
            tap(res => res),
            catchError(this.handleError),);
    }


  private handleError(error: HttpErrorResponse) {
    console.error(error);
    let errorMessage = '';
    if (error.error instanceof Error) {
      // A client-side or network error occurred. Handle it accordingly.
      errorMessage = `An error occurred: ${error.error.message}`;
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      errorMessage = `Server returned code: ${error.status}, error message is: ${error.message}`;
    }
    console.error(errorMessage);
    return observableThrowError(errorMessage);
  }

}
