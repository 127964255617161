import { AfterViewInit, Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { MasterNGOService } from '../../shared/services/masterNGO.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AdminMasterService } from './../../shared/services/master.services';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from './../../shared/services/authentication.service';



@Component({
  selector: 'skill-list',
  templateUrl: './skill.component.html',
})

export class SkillComponent implements OnInit {

  adminLoginId: number;
  skills: any[] = [];
  skillName: string;
  rate: string;
  selectedSkill: any;
  selectedSkillId: number = 0;
  errorMessage: string = '';


  constructor(private _service: AuthenticationService, private router: Router, private masterService: AdminMasterService, private route: ActivatedRoute) {
    router.events.subscribe((response) => {
      var details = this._service.getToken();
      this.adminLoginId = details.access_token.adminLoginId;
    });

  }

  getSkills() {
    var obj = { PageNumber: 1, PageSize: 10 };
    this.masterService.getSkills(obj).subscribe(response => {
      if (response.isSuccess) {
        this.skills = response.list;
      }
    });
  }

  updateSkill() {
    var obj = { SkillId: this.selectedSkillId, Skill: this.skillName, Rate: this.rate };
   
    console.log(obj);
if (this.skillName == null && this.skillName == undefined) {

   this.errorMessage = 'Please enter skill name';
  }
  else if(this.rate == null && this.rate == undefined){
   this.errorMessage = 'Please enter rate';

  }
   else{
    this.masterService.updateSkill(obj).subscribe(response => {
      if (response.isSuccess) {
        this.getSkills();
        this.selectedSkill = null
        this.selectedSkillId = 0;
        this.skillName =  '';
        this.rate = '';
        this.errorMessage = '';
      }
    });
   }
  }

  edit(skill: any) {
    this.selectedSkill = skill;
    this.selectedSkillId = skill.skillId;
    this.skillName = skill.skill;
    this.rate = skill.rate;
  }

  deleteSkill(skill: any) {
    this.masterService.deleteSkill(skill).subscribe(response => {
      if (response.isSuccess) {
        this.getSkills();
        this.selectedSkill = null
        this.selectedSkillId = null;
      }
    });
  }

  ngOnInit() {
    this.getSkills()
  }

}

