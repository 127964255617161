import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from './../shared/services/authentication.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrManager } from 'ng6-toastr-notifications';


@Component({
  selector: 'app-basic-login',
  templateUrl: './basic-login.component.html',
  styleUrls: ['./basic-login.component.scss']
})
export class BasicLoginComponent implements OnInit {

  email: string;
  mobile:string;
  errorMessage: string;
  loginForm: FormGroup;
  validateForm: boolean = false;
  rememberMe: boolean;
  loginCount:number = 0;
  savedUser:any;

    constructor(
      private _service: AuthenticationService, 
      private router: Router, 
      private activeRoute: ActivatedRoute,
      private toaster:ToastrManager) {
      
        localStorage.removeItem("corporateApprovalStatus");
        
    }

  createForm() {
    this.loginForm = new FormGroup({
        Email: new FormControl(this.email, [Validators.required, Validators.pattern("^[_A-Za-z0-9-\\+]+(\\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\\.[A-Za-z0-9]+)*(\\.[A-Za-z]{2,})$")]),
        //MobileNumber: new FormControl(this.mobile, [Validators.required,Validators.minLength(10),Validators.required,Validators.maxLength(15)]),
        
    });
  }

  login() {
      
    this.loginCount += 1;
       
      if(this.loginCount == 1) {
        
        this.validateForm = true;
      
        if (this.loginForm.valid) 
        {         
            var email = this.loginForm.controls.Email.value;
           // var mobileNumber = this.loginForm.controls.MobileNumber.value;

            var userCredentials = {
              "email":email,
              //"mobileNumber":mobileNumber
            }
            // set details to browser cache
            localStorage.setItem("userCredentials",JSON.stringify(userCredentials));
            
            var loginRequest = { 
              Email: email, 
             // MobileNumber: mobileNumber
            };

            this._service.login(loginRequest, this.rememberMe)
            .subscribe(response => 
            {     
              console.log(response);           
                if (response.isSuccess) 
                {    
                  if(this.rememberMe){
                    localStorage.setItem("User",JSON.stringify(userCredentials));
                  }         
                  
                    this.router.navigate(['otp-verify']);
                   
                }
                else 
                {
                  this.toaster.errorToastr(response.responseMessage);
                  this.loginCount = 0;  
                  if(!response.userExist)     
                  {
                    this.router.navigate(['login'])
                  }
                }           
            },
              (err) => 
              {
                  if (err.errorMessage != null && err.errorMessage != '') {
                    this.toaster.errorToastr(err.errorMessage);
                }
                else {
                    this.toaster.errorToastr("Some error occured");
                }     
                this.loginCount = 0;      
              });
      
      } 
      else {
        this.loginCount = 0;
      }
    }
  }

  keyDownHandler(event) {
    if (event.target.value.length > 0) {
        return true;
    } else {
        if (event.keyCode === 32) {
            return false;
        }
    }
}

// only number validation
numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        return false;
    }
    return true;

}

saveUserDetails(evt){
  this.rememberMe = evt.target.checked;
  if(!evt.target.checked){
    localStorage.removeItem("User");
  }
}
 
  ngOnInit() {
   
       
   
    this.savedUser = localStorage.getItem("User"); 
   
    if(this.savedUser!=null){
      var data = JSON.parse(this.savedUser);
      
      this.email = data.email;
      //this.mobile = data.mobileNumber;
      this.rememberMe = true;
    }
    else{
      this.rememberMe = false;
    }

    this.createForm();
  
  }
}
