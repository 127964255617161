import { Injectable } from '@angular/core';
import { Observable,BehaviorSubject } from 'rxjs';

@Injectable()
export class DataService {

  private object = new BehaviorSubject('');
  currentMessage = this.object.asObservable();
  isDashboard = this.object.asObservable();

  constructor() { }

  SendMessage(message) {
    this.object.next(message);
  }

  SetMenu(value){
    this.object.next(value);
  }

}
