import { AfterViewInit, Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { AdminVolunteerService } from '../../shared/services/volunteer.service';
import { AuthenticationService } from '../../shared/services/authentication.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { LearningZoneService } from '../../shared/services/learningzone.service';



@Component({
  selector: 'learningzone-list',
  templateUrl: './learningzone-list.component.html',
})

export class LearningZoneListComponent implements OnInit {

 corporateId: number;
    adminLoginId: number;
    isAdmin: boolean = true;
    allUsers: any[] = [];
    pageNumber: number = 1;
    pageSize: number = 10;
    count: number;
    startFrom: number = 1;
    endOn: number = this.pageSize;
    numberOfPages: number;
    lastPageClass: string;
    firstPageClass: string = "disabled";
    previousPageClass: string = "disabled";
    nextPageClass: string;
    posts: any[];




  constructor(private authentication: AuthenticationService, private learningZone: LearningZoneService,  private router: Router, private route: ActivatedRoute) {
    var details = this.authentication.getToken();
    this.adminLoginId = details.access_token.adminLoginId;
    this.corporateId = details.access_token.corporateId;
  }


  changePageSize(pageSize: number) {
    this.pageSize = pageSize;
    this.pageNumber = 1;
    this.firstPageClass = "disabled";
    this.previousPageClass = "disabled";
    this.nextPageClass = "";
    this.lastPageClass = "";
    this.getAllPost();
  }

  getAllPost() {
        var obj = { isAdminPost: this.isAdmin, CorporateId: this.corporateId, PageNumber: this.pageNumber, PageSize: this.pageSize};
        this.learningZone.getAllLearningPost(obj).subscribe(response => {
          if (response.isSuccess) {
         console.log(response);
        this.posts = response.list;
        this.count = response.count;
        if (this.count == 0) {
          this.startFrom = 0;
        }
        else {
          this.startFrom = ((this.pageNumber - 1) * this.pageSize) + 1;
        }
        if (this.count <= this.pageSize) {
          this.endOn = this.count;
          this.nextPageClass = "disabled";
          this.lastPageClass = "disabled";
        }
        else {
          this.endOn = this.pageNumber * this.pageSize;
        }
        var pages = this.count / this.pageSize;
        this.numberOfPages = 0;
        for (var i = 0; i < pages; i++) {
          this.numberOfPages = i + 1;
        }
        if (this.endOn > this.count) {
          this.endOn = this.count;
      }
          }
        });
      }

        firstPage() {
    if (this.pageNumber > 1) {
      this.firstPageClass = "disabled";
      this.previousPageClass = "disabled";
      this.nextPageClass = "";
      this.lastPageClass = "";
      this.pageNumber = 1;
      this.getAllPost();
    }
  }

  lastPage() {
    if (this.pageNumber < this.numberOfPages) {
      this.firstPageClass = "";
      this.previousPageClass = "";
      this.nextPageClass = "disabled";
      this.lastPageClass = "disabled";
      this.pageNumber = this.numberOfPages;
      this.getAllPost();
    }
  }

  previousPage() {
    if (this.pageNumber > 1) {
      this.pageNumber = this.pageNumber - 1;
      if (this.pageNumber == 1) {
        this.firstPageClass = "disabled";
        this.previousPageClass = "disabled";
        this.nextPageClass = "";
        this.lastPageClass = "";
      }
      this.getAllPost();
    }
  }

  nextPage() {
    if (this.pageNumber < this.numberOfPages) {
      this.pageNumber = this.pageNumber + 1;
      this.firstPageClass = "";
      this.previousPageClass = "";
      if (this.pageNumber == this.numberOfPages) {
        this.nextPageClass = "disabled";
        this.lastPageClass = "disabled";
      }
      this.getAllPost();
    }
  }

  deletePost(LearningZoneId: number) {
    var obj = { learningZoneId: LearningZoneId };
    console.log(obj);
    this.learningZone.DeletePost(obj).subscribe(response => {
      if (response.isSuccess) {
          console.log(response);
          this.getAllPost()
      }
    });

  }
 
  ngOnInit() {
    this.getAllPost();
  }


}
