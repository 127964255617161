import { AfterViewInit, Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { MasterNGOService } from '../../shared/services/masterNGO.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AdminMasterService } from './../../shared/services/master.services';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from './../../shared/services/authentication.service';




@Component({
  selector: 'companies-list',
  templateUrl: './companies-list.component.html',
})

export class CompaniesListComponent implements OnInit {

  companieslist: any;
  pageNumber: number = 1;
  pageSize: number = 10;
  startFrom: number = 1;
  count: number;
  endOn: number = this.pageSize;
  lastPageClass: string;
  firstPageClass: string = "disabled";
  previousPageClass: string = "disabled";
  nextPageClass: string;
  numberOfPages: number;
  searchTerm: string = "";
  adminLoginId: number;
  comment: string;
  selectedCompanyId: number;
  selectedAdminId: number;


  constructor(private _service: AuthenticationService, private router: Router, private masterService: AdminMasterService) {

    var details = this._service.getToken();
    this.adminLoginId = details.access_token.adminLoginId;
  }

  getAllCompanies() {
    var obj = { pagenumber: this.pageNumber, pagesize: this.pageSize, SearchTerm: this.searchTerm };
    console.log(obj);
    this._service.getAllCompanies(obj).subscribe(response => {
      if (response.isSuccess) {
        console.log(response);
        this.companieslist = response.list;
        this.count = response.count;
        if (this.count == 0) {
          this.startFrom = 0;
        }
        else {
          this.startFrom = ((this.pageNumber - 1) * this.pageSize) + 1;
        }
        if (this.count <= this.pageSize) {
          this.endOn = this.count;
          this.nextPageClass = "disabled";
          this.lastPageClass = "disabled";
        }
        else {
          this.endOn = this.pageNumber * this.pageSize;
          if (this.endOn > this.count) {
            this.endOn = this.count;
          }
        }
        var pages = this.count / this.pageSize;
        this.numberOfPages = 0;
        for (var i = 0; i < pages; i++) {
          this.numberOfPages = i + 1;
        }
      }
    });

  }

  firstPage() {
    if (this.pageNumber > 1) {
      this.firstPageClass = "disabled";
      this.previousPageClass = "disabled";
      this.nextPageClass = "";
      this.lastPageClass = "";
      this.pageNumber = 1;
      this.getAllCompanies();
    }
  }

  lastPage() {
    if (this.pageNumber < this.numberOfPages) {
      this.firstPageClass = "";
      this.previousPageClass = "";
      this.nextPageClass = "disabled";
      this.lastPageClass = "disabled";
      this.pageNumber = this.numberOfPages;
      this.getAllCompanies();
    }
  }

  previousPage() {
    if (this.pageNumber > 1) {
      this.pageNumber = this.pageNumber - 1;
      if (this.pageNumber == 1) {
        this.firstPageClass = "disabled";
        this.previousPageClass = "disabled";
        this.nextPageClass = "";
        this.lastPageClass = "";
      }
      this.getAllCompanies();
    }
  }

  nextPage() {
    if (this.pageNumber < this.numberOfPages) {
      this.pageNumber = this.pageNumber + 1;
      this.firstPageClass = "";
      this.previousPageClass = "";
      if (this.pageNumber == this.numberOfPages) {
        this.nextPageClass = "disabled";
        this.lastPageClass = "disabled";
      }
      this.getAllCompanies();
    }
  }

  changePageSize(pageSize: number) {
    this.pageSize = pageSize;
    this.pageNumber = 1;
    this.firstPageClass = "disabled";
    this.previousPageClass = "disabled";
    this.nextPageClass = "";
    this.lastPageClass = "";
    this.getAllCompanies();
  }

  checkIfBoxEmpty() {
    if (this.searchTerm == '' || this.searchTerm == null) {
      this.pageNumber = 1;
      this.firstPageClass = "disabled";
      this.previousPageClass = "disabled";
      this.nextPageClass = "";
      this.lastPageClass = "";
      this.getAllCompanies();
    }
  }

  search() {
    this.pageNumber = 1;
    this.firstPageClass = "disabled";
    this.previousPageClass = "disabled";
    this.nextPageClass = "";
    this.lastPageClass = "";
    this.getAllCompanies();
  }
  // deleteCompany(corporateId: number) {
  //   var obj = { corporateId: corporateId };
  //   console.log(obj);
  //   this._service.deletecompany(obj).subscribe(response => {
  //     console.log(response);
  //     this.firstPageClass = "disabled";
  //     this.previousPageClass = "disabled";
  //     this.nextPageClass = "";
  //     this.lastPageClass = "";
  //     this.pageNumber = 1;
  //     this.getAllCompanies();
  //   });
  // }

  selectCorporate(corporateId: number, adminloginId: number) {
    this.selectedAdminId = adminloginId;
    this.selectedCompanyId = corporateId;
  }

  performAction(corporateId: number, adminloginId: number, statusId: number) {
    var obj = { CorporateId: corporateId, StatusId: statusId, AdminLoginId: adminloginId, Comment: this.comment };
    console.log(obj);
    this._service.actionforStatus(obj).subscribe(response => {
      if (response.isSuccess) {
        this.comment = '';
        this.getAllCompanies();
      }
    });
  }


    downloadCsvFile() {
        var list = this.companieslist;
        if (list != null && list.length > 0) {
            var obj = { pagenumber: this.pageNumber, pagesize: this.pageSize, SearchTerm: this.searchTerm };
            this._service.getChapterCsv(obj).subscribe(response => {
                console.log(response);
                if (response.isSuccess) {
                    let elem = document.createElement("a");
                    elem.target = "_self"; elem.href = response.csvFilePath; elem.setAttribute("download", response.csvFilePath);
                    elem.click();
                }
            });
        }
        else {
            alert("Event list is empty");
        }
    }

  ngOnInit() {
    this.getAllCompanies();
  }


}
