import { Injectable } from '@angular/core';
import { AuthenticationService } from '../services/authentication.service';

export interface BadgeItem {
  type: string;
  value: string;
}

export interface ChildrenItems {
  state: string;
  target?: boolean;
  name: string;
  type?: string;
  children?: ChildrenItems[];
}

export interface MainMenuItems {
  state: string;
  short_label?: string;
  main_state?: string;
  target?: boolean;
  name: string;
  type: string;
  icon: string;
  badge?: BadgeItem[];
  children?: ChildrenItems[];
}

export interface Menu {
  label: string;
  main: MainMenuItems[];
}

const MENUITEMS = [
  {
    label: 'Navigation',
    main: [
     
      {
        state: 'dashboard',
        short_label: 'P',
        name: 'Dashboard',
        type: 'link',
        icon: 'mdi mdi-account-circle',
      },
      {
        state: 'event',
        short_label: 'P',
        name: 'Activity',
        type: 'sub',
        icon: 'mdi mdi-format-list-checks',
        children: [
          {
            state: 'public',
            name: 'Public Activity'
          },
          {
            state: 'private',
            name: 'My Activity',
          }
        ]
       },
       {
        state: 'project',
        short_label: 'P',
        name: 'Opportunity',
        type: 'sub',
        icon: 'mdi mdi-certificate',
        children:[
          {
            state: 'public',
            name: 'Public Opportunity'
          },
          {
            state: 'private',
            name: 'My Opportunity',
          }
        ]
      },
      // {
      //   state: 'curriculum',
      //   short_label: 'L',
      //   name: 'Curriculum Activities',
      //   type: 'link',
      //   icon: 'mdi mdi-newspaper',
      // },
      {
        state: 'newsfeed',
        short_label: 'L',
        name: 'News Feeds',
        type: 'link',
        icon: 'mdi mdi-newspaper',
      },
      // {
      //   state: 'leaderboard',
      //   short_label: 'L',
      //   name: 'Leader Board',
      //   type: 'link',
      //   icon: 'mdi mdi-account-circle',
      // },
      {
        state: 'history',
        short_label: 'H',
        name: 'History',
        type: 'link',
        icon: 'mdi mdi-file-chart',
      },
      {
        state: 'profile',
        short_label: 'P',
        name: 'Profile',
        type: 'link',
        icon: 'mdi mdi-account-circle',
      },
      {
        state: 'beneficiary',
        short_label: 'P',
        name: 'Beneficiaries',
        type: 'link',
        icon: 'mdi mdi-account-circle',       
       },
     
     
     
  
      // {
      //   state: 'impact',
      //   short_label: 'I',
      //   name: 'Impact And Achievement',
      //   type: 'link',
      //   icon: 'mdi mdi-account-circle',
      // },
      // {
      //   state: 'unsubscribe',
      //   short_label: 'I',
      //   name: 'Unsubscribe',
      //   type: 'link',
      //   icon: 'mdi mdi-account-circle',
      // },
     
 
      
    ]
  }
];

const BENEFICIARYMENUITEMS = [
  {
    label: 'Navigation',
    main: [
      {
        state: 'beneficiary-curriculum',
        short_label: 'L',
        name: 'Curriculum',
        type: 'link',
        icon: 'mdi mdi-newspaper',
      },
        {
        state: 'profile',
        short_label: 'P',
        name: 'Profile',
        type: 'link',
        icon: 'mdi mdi-account-circle',
      },
    
    ]
  }
];

@Injectable()
export class MenuItems {
  userRegistrationStatusId: any;
 // roleId: number;
  constructor(private authService: AuthenticationService) {
    var details = this.authService.getToken();
    if(details!=null) 
    {
      this.userRegistrationStatusId = details.access_token.userRegistrationStatusId;
    }
   
    console.log("Const")
  }
  getAll(): Menu[] {
   // if (this.roleId === 1) {
   //   return MENUITEMS;
   // }
   var details = this.authService.getToken();
   this.userRegistrationStatusId = details.access_token.userRegistrationStatusId;

   if(this.userRegistrationStatusId==4){
     return BENEFICIARYMENUITEMS;
   } else{
   
    return MENUITEMS;
   }
  }
}
