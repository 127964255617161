import { AfterViewInit, Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { AdminEventsService } from '../../../shared/services/events.services';
import { AuthenticationService } from '../../../shared/services/authentication.service';

@Component({
  selector: 'event-list',
  templateUrl: './event-list.component.html',
})

export class EventListComponent implements OnInit {

  adminLoginId: number;
  events: any[] = [];
  pageNumber: number = 1;
  pageSize: number = 10;
  searchTerm: string;
  count: number;
  startFrom: number = 1;
  endOn: number = this.pageSize;
  numberOfPages: number;
  lastPageClass: string;
  firstPageClass: string = "disabled";
  previousPageClass: string = "disabled";
  nextPageClass: string;
  titleIcon: string = "sorting";
  startDateIcon: string = "sorting";
  applicantIcon: string = "sorting";
  stateIcon: string = "sorting";
  isAscending: boolean = false;
  sortColumn: string;

  constructor(private _service: AdminEventsService, private authentication: AuthenticationService) {
    var details = this.authentication.getToken();
    this.adminLoginId = details.access_token.adminLoginId;
  }



  getEvents() {
    var obj = { AdminLoginId: this.adminLoginId, IsPublic: true, PageNumber: this.pageNumber, PageSize: this.pageSize, SearchTerm: this.searchTerm, IsAscending: this.isAscending, SortBy: this.sortColumn};
    console.log(obj);
    this._service.getSuperAdminEvents(obj).subscribe(response => {
      if (response.isSuccess) {
        console.log(response);
        this.events = response.list;
        this.count = response.count;
        if (this.count == 0) {
          this.startFrom = 0;
        }
        else {
          this.startFrom = ((this.pageNumber - 1) * this.pageSize) + 1;
        }
        if (this.count <= this.pageSize) {
          this.endOn = this.count;
          this.nextPageClass = "disabled";
          this.lastPageClass = "disabled";
        }
        else {
          this.endOn = this.pageNumber * this.pageSize;
        }
        var pages = this.count / this.pageSize;
        this.numberOfPages = 0;
        for (var i = 0; i < pages; i++) {
          this.numberOfPages = i + 1;
        }
        if (this.endOn > this.count) {
          this.endOn = this.count;
      }
      }
    });
  }

  sortColumns(columnName: string) {
    switch (columnName) {
      case "Title":
        if (this.titleIcon == "sorting") {
          this.titleIcon = "sorting_asc";
          this.isAscending = true;
        }
        else if (this.titleIcon == "sorting_asc") {
          this.titleIcon = "sorting_desc";
          this.isAscending = false;
        }
        else if (this.titleIcon == "sorting_desc") {
          this.titleIcon = "sorting_asc";
          this.isAscending = true;
        }
        this.startDateIcon = "sorting";
        this.stateIcon = "sorting";
        this.applicantIcon = "sorting";
        break;
      case "StartDate":
        this.titleIcon = "sorting";
        if (this.startDateIcon == "sorting") {
          this.startDateIcon = "sorting_asc";
          this.isAscending = true;
        }
        else if (this.startDateIcon == "sorting_asc") {
          this.startDateIcon = "sorting_desc";
          this.isAscending = false;
        }
        else if (this.startDateIcon == "sorting_desc") {
          this.startDateIcon = "sorting_asc";
          this.isAscending = true;
        }
        this.stateIcon = "sorting";
        this.applicantIcon = "sorting";
        break;
      case "State":
        this.titleIcon = "sorting";
        this.startDateIcon = "sorting";
        if (this.stateIcon == "sorting") {
          this.stateIcon = "sorting_asc";
          this.isAscending = true;
        }
        else if (this.stateIcon == "sorting_asc") {
          this.stateIcon = "sorting_desc";
          this.isAscending = false;
        }
        else if (this.stateIcon == "sorting_desc") {
          this.stateIcon = "sorting_asc";
          this.isAscending = true;
        }
        this.applicantIcon = "sorting";
        break;
      case "Applicants":
        this.titleIcon = "sorting";
        this.startDateIcon = "sorting";
        if (this.applicantIcon == "sorting") {
          this.applicantIcon = "sorting_asc";
          this.isAscending = true;
        }
        else if (this.applicantIcon == "sorting_asc") {
          this.applicantIcon = "sorting_desc";
          this.isAscending = false;
        }
        else if (this.applicantIcon == "sorting_desc") {
          this.applicantIcon = "sorting_asc";
          this.isAscending = true;
        }
        this.stateIcon = "sorting";
        break;
    }
    this.sortColumn = columnName;
    this.pageNumber = 1;
    this.getEvents();
  }

  firstPage() {
    if (this.pageNumber > 1) {
      this.firstPageClass = "disabled";
      this.previousPageClass = "disabled";
      this.nextPageClass = "";
      this.lastPageClass = "";
      this.pageNumber = 1;
      this.getEvents();
    }
  }

  lastPage() {
    if (this.pageNumber < this.numberOfPages) {
      this.firstPageClass = "";
      this.previousPageClass = "";
      this.nextPageClass = "disabled";
      this.lastPageClass = "disabled";
      this.pageNumber = this.numberOfPages;
      this.getEvents();
    }
  }

  previousPage() {
    if (this.pageNumber > 1) {
      this.pageNumber = this.pageNumber - 1;
      if (this.pageNumber == 1) {
        this.firstPageClass = "disabled";
        this.previousPageClass = "disabled";
        this.nextPageClass = "";
        this.lastPageClass = "";
      }
      this.getEvents();
    }
  }

  nextPage() {
    if (this.pageNumber < this.numberOfPages) {
      this.pageNumber = this.pageNumber + 1;
      this.firstPageClass = "";
      this.previousPageClass = "";
      if (this.pageNumber == this.numberOfPages) {
        this.nextPageClass = "disabled";
        this.lastPageClass = "disabled";
      }
      this.getEvents();
    }
  }

  search() {
    this.pageNumber = 1;
    this.firstPageClass = "disabled";
    this.previousPageClass = "disabled";
    this.nextPageClass = "";
    this.lastPageClass = "";
    this.getEvents();
  }

  changePageSize(pageSize: number) {
    this.pageSize = pageSize;
    this.pageNumber = 1;
    this.firstPageClass = "disabled";
    this.previousPageClass = "disabled";
    this.nextPageClass = "";
    this.lastPageClass = "";
    this.getEvents();
  }

  checkIfBoxEmpty() {
    if (this.searchTerm == '' || this.searchTerm == null) {
      this.pageNumber = 1;
      this.firstPageClass = "disabled";
      this.previousPageClass = "disabled";
      this.nextPageClass = "";
      this.lastPageClass = "";
      this.getEvents();
    }
  }

  performAction(evnt: any, statusId: number, status: string) {
    var obj = { EventId: evnt.eventId, StatusId: statusId };
    this._service.performAction(obj).subscribe(response => {
      if (response.isSuccess) {
        evnt.statusId = statusId;
        evnt.status = status;
      }
    });
  }

  deleteEvent(evnt: any, index: number) {
    var obj = { EventId: evnt.eventId };
    this._service.deleteEvent(obj).subscribe(response => {
      this.firstPageClass = "disabled";
      this.previousPageClass = "disabled";
      this.nextPageClass = "";
      this.lastPageClass = "";
      this.pageNumber = 1;
      this.getEvents();
    });
  }


    downloadCsvFile() {
        var list = this.events;
        if (list != null && list.length > 0) {
            var obj = { AdminLoginId: this.adminLoginId, IsPublic: true, SearchTerm: this.searchTerm, IsAscending: this.isAscending, SortBy: this.sortColumn };
            this._service.getEventCsv(obj).subscribe(response => {
                console.log(response);
                if (response.isSuccess) {
                    let elem = document.createElement("a");
                    elem.target = "_self"; elem.href = response.csvFilePath; elem.setAttribute("download", response.csvFilePath);
                    elem.click();
                }
            });
        }
        else {
            alert("Event list is empty");
        }
    }

  ngOnInit() {
    this.getEvents();
  }
}
