
import {throwError as observableThrowError,  Observable } from 'rxjs';

import {tap, catchError} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';



import { Router } from '@angular/router';
import { environment } from './../../../environments/environment';

@Injectable()
export class QuizService {

  constructor(private router: Router, private http: HttpClient) { }

  getQuizQuestionFirstOrNext(obj): Observable<any> {
    return this.http.post<any>(environment.apiBasePath + '/Quiz/GetFirstOrNextQuestion', obj).pipe(
      tap(res => res),
      catchError(this.handleError),);
  }

  GetPreviousQuestion(obj): Observable<any> {
    return this.http.post<any>(environment.apiBasePath + '/Quiz/GetPreviousQuestion', obj).pipe(
      tap(res => res),
      catchError(this.handleError),);
  }

  GetQuizReport(obj): Observable<any> {
    return this.http.post<any>(environment.apiBasePath + '/Quiz/GetQuizReport', obj).pipe(
      tap(res => res),
      catchError(this.handleError),);
  }

  GetQuestionCount(obj): Observable<any> {
    return this.http.post<any>(environment.apiBasePath + '/Quiz/GetQuestionCount', obj).pipe(
      tap(res => res),
      catchError(this.handleError),);
  }
  StartQuiz(obj): Observable<any> {
    return this.http.post<any>(environment.apiBasePath + '/Quiz/StartQuiz', obj).pipe(
      tap(res => res),
      catchError(this.handleError),);
  }


  private handleError(error: HttpErrorResponse) {
    console.error(error);
    let errorMessage = '';
    if (error.error instanceof Error) {
      // A client-side or network error occurred. Handle it accordingly.
      errorMessage = `An error occurred: ${error.error.message}`;
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      errorMessage = `Server returned code: ${error.status}, error message is: ${error.message}`;
    }
    console.error(errorMessage);
    return observableThrowError(errorMessage);
  }

}
