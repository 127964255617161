import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BasicLoginComponent } from './login/basic-login.component';
import { LayoutComponent } from './layout/all-layout/layout.component';
import { RegisterComponent } from './login/register/register.component';
import { ForgotPasswordComponent } from './login/forgotpassword/forgotpassword.component';
import { ResetPasswordComponent } from './login/resetpassword/resetpassword.component';
import { OtpComponent } from '../app/login/otp/otp.component';
import { NonApprovedComponent } from '../app/login/non-approved/non-approved.component';
import { RejectedComponent } from './login/rejected/rejected.component';





const routes: Routes = [
  {
    path: '',
    component: BasicLoginComponent, pathMatch: 'full'
  },
  {
    path: 'login',
    component: BasicLoginComponent, pathMatch: 'full'
  },
  {
    path: 'rejected',
    component: RejectedComponent, pathMatch: 'full'
  },
  
  {
    path: 'otp-verify',
    component: OtpComponent,
    pathMatch: 'full',
  },
  {
    path:'non-approved',
    component:NonApprovedComponent
  },
  {
    path: 'register',
    component: RegisterComponent
  },

  {
    path: 'forgotpassword',
    component: ForgotPasswordComponent
  },
  {
    path: 'resetPassword/:email/:time',
    component: ResetPasswordComponent
  },
    {
    path: 'resetPassword/:email',
    component: ResetPasswordComponent
  },
  {
    path: 'volunteer',
    component: LayoutComponent,
    pathMatch: 'full'
  },
  {
    path: 'volunteer',
    component: LayoutComponent,
    children: [
      //{
      //  path: '',
      //  redirectTo: 'dashboard/default',
      //  pathMatch: 'full'
      //},
      {
        path: '',
        loadChildren: () => import('./volunteer/volunteer.module').then(m => m.VolunteerModule)
      },
      {
        path: 'navigation',
        loadChildren: () => import('./layout/navigation/navigation.module').then(m => m.NavigationModule)
      }
    ]
  }
];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
