import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from './../../shared/services/authentication.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';



@Component({
  selector: 'resetpassword',
  templateUrl: './resetpassword.component.html',
})
export class ResetPasswordComponent implements OnInit {

  email: string;
  time: string;
  confirmpassword: string;
  password: string;
  errorMessage: string;
  loginForm: FormGroup;
  validateForm: boolean = false;
  isActive: boolean = true;


  constructor(private router: Router, private _service: AuthenticationService, private route: ActivatedRoute) {

    router.events.subscribe((response) => {
      this.email = route.snapshot.params['email'];
      //this.time = route.snapshot.params['time'];
    });
  }

  createForm() {
    this.loginForm = new FormGroup({
      confirmpassword: new FormControl('', Validators.required),
      password: new FormControl('', Validators.required),

    });
  }

  reset() {

    this.errorMessage = '';
    this.validateForm = true;
    if (this.loginForm.valid) {

      if (this.loginForm.controls.password.value == this.loginForm.controls.confirmpassword.value) {
        console.log("Password Matched");
        var obj = { Password: this.loginForm.controls.password.value, email: this.email, ResetTime: this.time };
        this._service.resetPassword(obj).subscribe(response => {
          if (response.isSuccess) {
            var a = document.getElementById("successModal");
            a.click();
            this.validateForm = false;
            this.confirmpassword = null;
            this.password = null;

          }
          else {
            this.errorMessage = "Some Error Occur";
          }
        });
      }
      else {
        this.errorMessage = "Password Mismatched";

      }

    }
  }

  ngOnInit() {
    //var obj = { Link: this.email + "/" + this.time };
    // this._service.checkIfLinkActive(obj).subscribe(response => {
    //   this.isActive = !response.isSuccess;
    // });
    this.createForm();
  }
}
