import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { HttpHandler, HttpRequest, HttpEvent, HttpResponse, HttpErrorResponse, HttpInterceptor } from "@angular/common/http";
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { AuthenticationService } from './authentication.service';
import { concatMap } from 'rxjs/operators';

@Injectable()
export class ApiInterceptorService {
  dialog: any;
  authenticationService: any;

  constructor(private injector: Injector, private router: Router, private auth: AuthenticationService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (this.auth.isAuthenticated()) {
     // var token = this.auth.getAuthToken();
     var details = this.auth.getToken();
      var token = details.access_token.token;
      if (token) {
        const cloned = req.clone({
          headers: req.headers.set("Authorization", "Bearer " + token)
        });
        return next.handle(cloned)
        .do(
          succ => { },
          err => {
              if (err.status === 401)
                  this.auth.logout();
                  //this.router.navigateByUrl('/rejected');
                
                           
          }
          
          );
  }
  
      }
    
    return next.handle(req);
  }
}




