import { AfterViewInit, Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AdminCampaignService } from '../../shared/services/campaigns.service';
import { AuthenticationService } from '../../shared/services/authentication.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'campaign-detail',
  templateUrl: './campaign-detail.component.html',
})

export class CampaignDetailComponent implements OnInit {
  adminLoginId: number;
  campaignForm: FormGroup;
  validateForm: boolean = false;
  statusId: number = 2;
  campiagnId: number;
  details: any;
  pushmessage: string;
  pushmsgValidation: boolean = false;
  pushmsgSendSuccess: boolean = false;


  constructor(private _service: AdminCampaignService, private authentication: AuthenticationService, private router: Router, private route: ActivatedRoute) {
    var details = this.authentication.getToken();
    this.adminLoginId = details.access_token.adminLoginId;

    router.events.subscribe((response) => {
      this.campiagnId = route.snapshot.params['id'];
    });
  }

  getCampaignDetail() {
    var obj = { campaignId: this.campiagnId };
    this._service.getSuperAdminCampaignDetails(obj).subscribe(response => {
      if (response.isSuccess) {
        console.log(response);
        this.details = response.data;
      }
    });
  }

  managetapped() {
    this.router.navigate(['/sa/campaigns/taskList', this.campiagnId]);
  }


  sendPushNotification() {
    if (this.pushmessage == undefined || this.pushmessage == "" || this.pushmessage == null) {
      this.pushmsgValidation = true;
    }
    else {
      this.pushmsgValidation = false;
      var obj = { CampaignId: this.campiagnId, Message: this.pushmessage };
      console.log(obj);
      this._service.pushNotificationforCampaignsUser(obj).subscribe(response => {
        if (response.isSuccess) {
          this.pushmsgSendSuccess = true;
          this.pushmessage = '';
        }
      });
    }
  }

  setPushMessage() {
    this.pushmsgSendSuccess = false;
    this.pushmsgValidation = false;
  }

  ngOnInit() {
    this.getCampaignDetail();

  }

}
