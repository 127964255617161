import { AfterViewInit, Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { MasterNGOService } from '../../shared/services/masterNGO.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AdminMasterService } from './../../shared/services/master.services';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from './../../shared/services/authentication.service';



@Component({
  selector: 'contries-list',
  templateUrl: './countries-list.component.html',
})

export class CountriesListComponent implements OnInit {

  countries: any[] = [];
  countriesForCities: any[] = [];
  states: any[] = [];
  StateForCities: any[] = [];
  cities: any[] = [];
  countrySelectedForState: boolean = false;
  countryStateSelectedForcites: boolean = false;
  //Country Section
  newCountryName: string;
  panicNumber: string;
  countryValidation: boolean = false;
  panicNumberValidation: boolean = false;
  adminLoginId: number;
  editCountryId: number = 0;
  //State Section
  newStateName: string;
  countryIdforState: number;
  editStateId: number = 0;
  alertforStateSection: string;

  // CitySection
  newCityName: string;
  stateIdforCity: number;
  editCityId: number = 0;
  alertforCitySection: string;







  constructor(private _service: AuthenticationService, private router: Router, private masterService: AdminMasterService, private route: ActivatedRoute) {
    router.events.subscribe((response) => {
      var details = this._service.getToken();
      this.adminLoginId = details.access_token.adminLoginId;
    });

  }



  getStates(countryId: number) {
    var obj = { pageNumber: 1, PageSize: 500, CountryId: countryId };
    this.masterService.getStates(obj).subscribe(response => {
      this.states = response.data;
      this.countryIdforState = countryId;

    });
  }

  getStatesForCities(countryId: number) {
    var obj = { pageNumber: 1, PageSize: 500, CountryId: countryId };
    this.masterService.getStates(obj).subscribe(response => {
      this.StateForCities = response.data;
    });
  }
  getCities(stateId: number) {
    var obj = { PageNumber: 1, PageSize: 500, stateId: stateId };
    this.masterService.getCities(obj).subscribe(response => {
      this.cities = response.data;
      this.stateIdforCity = stateId;
    });
  }

  getCountries() {
    var obj = { pageNumber: 1, PageSize: 500 };
    this.masterService.getCountries(obj).subscribe(response => {
      this.countries = response.data;
      this.countriesForCities = response.data;
    });
  }


  addCountry() {

    if (this.newCountryName == undefined || this.newCountryName == "") {
      this.countryValidation = true;
    }
    // else if (this.panicNumber == undefined || this.panicNumber == "") {
    //   this.panicNumberValidation = true;
    // }
    else {
      this.countryValidation = false;
      var obj = { CountryName: this.newCountryName, AdminLoginId: this.adminLoginId, CountryId: null, PanicNumber: this.panicNumber };
      this.masterService.addCountry(obj).subscribe(response => {
        if (response.isSuccess == true) {
          this.newCountryName = null;
          this.panicNumber = null;
          this.countryValidation = false;
          this.panicNumberValidation = false;
          this.getCountries();
        }
      });

    }
  }
  // CounTry Section
  editCountry() {
    if (this.newCountryName == undefined || this.newCountryName == "") {
      this.countryValidation = true;
    }
    // else if (this.panicNumber == undefined || this.panicNumber == "") {
    //   this.panicNumberValidation = true;
    // }
    else {
      this.countryValidation = false;
      var obj = { CountryName: this.newCountryName, AdminLoginId: this.adminLoginId, CountryId: this.editCountryId, PanicNumber: this.panicNumber };
      console.log(obj);
      this.masterService.addCountry(obj).subscribe(response => {
        if (response.isSuccess == true) {
          this.newCountryName = null;
          this.panicNumber = null;
          this.countryValidation = false;
          this.panicNumberValidation = false;
          this.editCountryId = 0;
          this.getCountries();
        }
      });

    }
  }
  editCountryIconTapped(country: any, countryId: number) {
    this.countryValidation = false;
    this.panicNumberValidation = false;
    this.newCountryName = country.country;
    this.panicNumber = country.panicNumber;
    this.editCountryId = countryId;

  }
  // State Section 
  editStateIconTapped(stateName: string, stateId: number) {
    this.newStateName = stateName;
    this.editStateId = stateId;
    console.log(this.newStateName);
    console.log(this.editStateId);

  }
  addState() {

    if (this.countryIdforState == undefined) {
      this.countrySelectedForState = true;
      this.alertforStateSection = "Choose Country First!"
    }
    else if (this.newStateName == undefined || this.newStateName == "") {
      this.countrySelectedForState = true;
      this.alertforStateSection = " Please Enter State Name First"
    }
    else {
      this.countrySelectedForState = false;
      var obj = { StateName: this.newStateName, AdminLoginId: this.adminLoginId, StateId: null, CountryId: this.countryIdforState };
      this.masterService.addState(obj).subscribe(response => {
        if (response.isSuccess == true) {
          this.newStateName = null;
          this.getStates(this.countryIdforState);

        }
      });

    }
  }

  editState() {
    if (this.newStateName == undefined || this.newStateName == "") {
      this.countrySelectedForState = true;
    }

    else {
      this.countrySelectedForState = false;
      var obj = { StateName: this.newStateName, AdminLoginId: this.adminLoginId, StateId: this.editStateId, CountryId: this.countryIdforState };
      console.log(obj);
      this.masterService.addState(obj).subscribe(response => {
        if (response.isSuccess == true) {
          this.newStateName = null;
          this.editStateId = 0;
          this.getStates(this.countryIdforState);
        }
      });


    }
  }
  // City Section 

  addCity() {

    if (this.stateIdforCity == undefined) {
      this.countryStateSelectedForcites = true;
      this.alertforCitySection = "Choose Country and State First";
    }
    else if (this.newCityName == undefined || this.newCityName == "") {
      this.alertforCitySection = " Please Enter City Name First";

      this.countryStateSelectedForcites = true;
    }
    else {
      this.countryStateSelectedForcites = false;
      var obj = { CityName: this.newCityName, AdminLoginId: this.adminLoginId, StateId: this.stateIdforCity, CityId: null };
      this.masterService.addCity(obj).subscribe(response => {
        if (response.isSuccess == true) {
          this.newCityName = null;
          this.getCities(this.stateIdforCity);

        }
      });

    }
  }
  editCityIconTapped(cityName: string, cityId: number) {
    this.newCityName = cityName;
    this.editCityId = cityId;

  }

  editCity() {
    if (this.newCityName == undefined || this.newCityName == "") {
      this.countryStateSelectedForcites = true;
    }

    else {
      this.countryStateSelectedForcites = false;
      var obj = { CityName: this.newCityName, AdminLoginId: this.adminLoginId, StateId: this.stateIdforCity, CityId: this.editCityId };
      console.log(obj);
      this.masterService.addCity(obj).subscribe(response => {
        if (response.isSuccess == true) {
          this.newCityName = null;
          this.editCityId = 0;
          this.getCities(this.stateIdforCity);
        }
      });
    }
  }
  // Delete Functionality

  deleteCity(cityId: number) {

    var obj = { StateId: this.stateIdforCity, CityId: cityId };
    console.log(obj);
    this.masterService.deleteCity(obj).subscribe(response => {
      console.log(response);
      if (response.isSuccess == true) {
        this.getCities(this.stateIdforCity);
        this.newCityName = null;
        this.editCityId = 0;

      }
    });
  }

  deleteState(stateId: number) {

    var obj = { StateId: stateId, CountryId: this.countryIdforState };
    console.log(obj);
    this.masterService.deleteState(obj).subscribe(response => {
      if (response.isSuccess == true) {
        this.getStates(this.countryIdforState);
        this.getStatesForCities(this.countryIdforState);
        this.cities = null;
        this.newStateName = null;
        this.editStateId = 0;


      }
    });
  }
  deleteCountry(countryId: number) {
    var obj = { CountryId: countryId, AdminLoginId: this.adminLoginId };
    console.log(obj);
    this.masterService.deleteCountry(obj).subscribe(response => {
      console.log(response);
      if (response.isSuccess == true) {
        this.getCountries();
        this.newCountryName = null;
        this.panicNumber = null;
        this.editCountryId = 0;
      }
    });
  }


  ngOnInit() {
    this.getCountries()
  }

}

