import { AfterViewInit, Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { AdminCampaignService } from '../../../shared/services/campaigns.service';
import { AuthenticationService } from '../../../shared/services/authentication.service';
import { Router, ActivatedRoute } from '@angular/router';



@Component({
  selector: 'campaign-tasklist',
  templateUrl: './campaign-tasklist.component.html',
})

export class CampaignTaskListComponent {

  adminLoginId: number;
  campaignId: number;
  campaignstask: any[] = [];
  pageNumber: number = 1;
  pageSize: number = 10;
  count: number;
  startFrom: number = 1;
  endOn: number = this.pageSize;
  numberOfPages: number;
  lastPageClass: string;
  firstPageClass: string = "disabled";
  previousPageClass: string = "disabled";
  nextPageClass: string;
  searchTerm: string;
  taskId: number;
  titleIcon: string = "sorting";
  startDateIcon: string = "sorting";
  endDateIcon: string = "sorting";
  MaximumIcon: string = "sorting";
  isAscending: boolean = false;
  sortColumn: string;
  campaignTitle: string;







  constructor(private _service: AdminCampaignService, private route: ActivatedRoute, private authentication: AuthenticationService, private router: Router) {
    var details = this.authentication.getToken();
    this.adminLoginId = details.access_token.adminLoginId;
    router.events.subscribe((response) => {
      this.campaignId = route.snapshot.params['id'];
    });
  }


  getCampaignsTaskList() {
    var obj = { AdminLoginId: this.adminLoginId, IsPublic: true, PageNumber: this.pageNumber, IsAscending: this.isAscending, PageSize: this.pageSize, SearchTerm: this.searchTerm, campaignId: this.campaignId, SortBy: this.sortColumn };
    console.log(obj);
    this._service.getAllCampaignsTaskList(obj).subscribe(response => {
      if (response.isSuccess) {
        this.campaignstask = response.list;
        this.campaignTitle = response.data.campaignTitle;
        this.count = response.count;
        if (this.count == 0) {
          this.startFrom = 0;
        }
        else {
          this.startFrom = ((this.pageNumber - 1) * this.pageSize) + 1;
        }
        if (this.count <= this.pageSize) {
          this.endOn = this.count;
          this.nextPageClass = "disabled";
          this.lastPageClass = "disabled";
        }
        else {
          this.endOn = this.pageNumber * this.pageSize;
        }
        var pages = this.count / this.pageSize;
        this.numberOfPages = 0;
        for (var i = 0; i < pages; i++) {
          this.numberOfPages = i + 1;
        }
        if (this.endOn > this.count) {
          this.endOn = this.count;
      }
      }
    });
  }


  firstPage() {
    if (this.pageNumber > 1) {
      this.firstPageClass = "disabled";
      this.previousPageClass = "disabled";
      this.nextPageClass = "";
      this.lastPageClass = "";
      this.pageNumber = 1;
      this.getCampaignsTaskList();
    }
  }

  lastPage() {
    if (this.pageNumber < this.numberOfPages) {
      this.firstPageClass = "";
      this.previousPageClass = "";
      this.nextPageClass = "disabled";
      this.lastPageClass = "disabled";
      this.pageNumber = this.numberOfPages;
      this.getCampaignsTaskList();
    }
  }

  previousPage() {
    if (this.pageNumber > 1) {
      this.pageNumber = this.pageNumber - 1;
      if (this.pageNumber == 1) {
        this.firstPageClass = "disabled";
        this.previousPageClass = "disabled";
        this.nextPageClass = "";
        this.lastPageClass = "";
      }
      this.getCampaignsTaskList();
    }
  }

  nextPage() {
    if (this.pageNumber < this.numberOfPages) {
      this.pageNumber = this.pageNumber + 1;
      this.firstPageClass = "";
      this.previousPageClass = "";
      if (this.pageNumber == this.numberOfPages) {
        this.nextPageClass = "disabled";
        this.lastPageClass = "disabled";
      }
      this.getCampaignsTaskList();
    }
  }

  search() {
    this.pageNumber = 1;
    this.firstPageClass = "disabled";
    this.previousPageClass = "disabled";
    this.nextPageClass = "";
    this.lastPageClass = "";
    this.getCampaignsTaskList();
  }

  changePageSize(pageSize: number) {
    this.pageSize = pageSize;
    this.pageNumber = 1;
    this.firstPageClass = "disabled";
    this.previousPageClass = "disabled";
    this.nextPageClass = "";
    this.lastPageClass = "";
    this.getCampaignsTaskList();
  }

  checkIfBoxEmpty() {
    if (this.searchTerm == '' || this.searchTerm == null) {
      this.pageNumber = 1;
      this.firstPageClass = "disabled";
      this.previousPageClass = "disabled";
      this.nextPageClass = "";
      this.lastPageClass = "";
      this.getCampaignsTaskList();
    }
  }

  deleteCampaign(taskId: number, index: number) {
    var obj = { taskId: taskId };
    this._service.deleteCampaignTask(obj).subscribe(response => {
      if (response.isSuccess) {
        this.firstPageClass = "disabled";
        this.previousPageClass = "disabled";
        this.nextPageClass = "";
        this.lastPageClass = "";
        this.pageNumber = 1;
        this.getCampaignsTaskList();
        console.log("Delete Task Completed");
      }
    });
  }

  sortColumns(columnName: string) {
    switch (columnName) {
      case "Title":
        if (this.titleIcon == "sorting") {
          this.titleIcon = "sorting_asc";
          this.isAscending = true;
        }
        else if (this.titleIcon == "sorting_asc") {
          this.titleIcon = "sorting_desc";
          this.isAscending = false;
        }
        else if (this.titleIcon == "sorting_desc") {
          this.titleIcon = "sorting_asc";
          this.isAscending = true;
        }
        this.startDateIcon = "sorting";
        this.endDateIcon = "sorting";
        this.MaximumIcon = "sorting";
        break;
      case "StartDate":
        if (this.startDateIcon == "sorting") {
          this.startDateIcon = "sorting_asc";
          this.isAscending = true;
        }
        else if (this.startDateIcon == "sorting_asc") {
          this.startDateIcon = "sorting_desc";
          this.isAscending = false;
        }
        else if (this.startDateIcon == "sorting_desc") {
          this.startDateIcon = "sorting_asc";
          this.isAscending = true;
        }
        this.titleIcon = "sorting";
        this.MaximumIcon = "sorting";
        this.endDateIcon = "sorting";

        break;
      case "EndDate":
        if (this.endDateIcon == "sorting") {
          this.endDateIcon = "sorting_asc";
          this.isAscending = true;
        }
        else if (this.endDateIcon == "sorting_asc") {
          this.endDateIcon = "sorting_desc";
          this.isAscending = false;
        }
        else if (this.endDateIcon == "sorting_desc") {
          this.endDateIcon = "sorting_asc";
          this.isAscending = true;
        }
        this.titleIcon = "sorting";
        this.MaximumIcon = "sorting";
        this.startDateIcon = "sorting";
        break;
      case "MaximumHours":
        if (this.MaximumIcon == "sorting") {
          this.MaximumIcon = "sorting_asc";
          this.isAscending = true;
        }
        else if (this.MaximumIcon == "sorting_asc") {
          this.MaximumIcon = "sorting_desc";
          this.isAscending = false;
        }
        else if (this.MaximumIcon == "sorting_desc") {
          this.MaximumIcon = "sorting_asc";
          this.isAscending = true;
        }
        this.titleIcon = "sorting";
        this.endDateIcon = "sorting";
        this.startDateIcon = "sorting";
        break;
    }
    this.sortColumn = columnName;
    this.pageNumber = 1;
    this.getCampaignsTaskList();
  }

    downloadCsvFile() {
        var list = this.campaignstask;
        if (list != null && list.length > 0) {
           var obj = { AdminLoginId: this.adminLoginId, IsAscending: this.isAscending, PageSize: this.pageSize, SearchTerm: this.searchTerm, campaignId: this.campaignId, SortBy: this.sortColumn };
            this._service.getCampaignTaskCsv(obj).subscribe(response => {
                console.log(response);
                if (response.isSuccess) {
                    let elem = document.createElement("a");
                    elem.target = "_self"; elem.href = response.csvFilePath; elem.setAttribute("download", response.csvFilePath);
                    elem.click();
                }
            });
        }
        else {
            alert("Task list is empty");
        }
    }

  performAction(taskId: number, statusId: number) {
    var obj = { taskId: taskId, StatusId: statusId, campaignId: this.campaignId };
    console.log(obj);
    this._service.PerformTaskAction(obj).subscribe(response => {
      if (response.isSuccess) {
        this.getCampaignsTaskList();
      }
    });
  }

  ngOnInit() {
    this.getCampaignsTaskList();
  }

}
