import { AfterViewInit, Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Location } from '@angular/common';
import { animate, style, transition, trigger } from '@angular/animations';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {Router } from '@angular/router';
import { AdminMasterService } from '../../shared/services/master.services';
import { AuthenticationService } from '../../shared/services/authentication.service';
import { AdminVolunteerService } from '../../shared/services/volunteer.service';



@Component({
  selector: 'volunteers-create',
  templateUrl: './volunteers-create.component.html',
})

export class VolunteersCreateComponent implements OnInit {


  volunteersForm: FormGroup;
  validateForm: boolean = false;
  adminLoginId: number;
  states: any[] = [];
  cities: any[] = [];
  errorMessage: string;
    countries: any[] = [];
    isSubmitEnable: boolean;


  constructor(private masterService: AdminMasterService, private authentication: AuthenticationService, private _service: AdminVolunteerService, private router: Router, private _location: Location) {
    var details = this.authentication.getToken();
    this.adminLoginId = details.access_token.adminLoginId;
  }

  createForm() {
    this.volunteersForm = new FormGroup({
      firstName: new FormControl('', Validators.required),
      lastName: new FormControl('', Validators.required),
      countryId: new FormControl('', Validators.required),
      stateId: new FormControl('', Validators.required),
      cityId: new FormControl('', Validators.required),
      gender: new FormControl('', Validators.required),
      email: new FormControl('', Validators.required),
      mobileNumber: new FormControl('', [Validators.required, Validators.minLength(10)]),
      //profilePicture: new FormControl('', Validators.required),
    });
  }

  save() {
    this.validateForm = true;
        if (this.volunteersForm.valid) {
            this.isSubmitEnable = false;
      this._service.addVolunteer(this.volunteersForm.value).subscribe(response => {
        if (response.isSuccess) {
          if(response.alreadyExist == true){
            this.errorMessage = "Volunteer already Exist";

          }
          else{
            this.router.navigate(['/sa/volunteers']);

            }

            this.isSubmitEnable = true;
        }
      });
    }
  }

  getCities(stateId: number) {
    var obj = { PageNumber: 1, PageSize: 500, stateId: stateId };
    this.masterService.getCities(obj).subscribe(response => {
      this.cities = response.data;
    });
  }


  getStates(countryId: number) {
    var obj = { pageNumber: 1, PageSize: 500, CountryId: countryId };
    this.masterService.getStates(obj).subscribe(response => {
      this.states = response.data;
    });
  }

  backClicked() {
    this._location.back();
  }

  ngOnInit() {
    var obj = { pageNumber: 1, PageSize: 500 };
    this.masterService.getCountries(obj).subscribe(response => {
      this.countries = response.data;
    });
      this.createForm();

      this.isSubmitEnable = true;
    
  }

}
