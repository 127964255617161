import { AfterViewInit, Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FeedbackService } from '../../shared/services/feedback.service';
import { AuthenticationService } from '../../shared/services/authentication.service';

@Component({
  selector: 'media-list',
  templateUrl: './media-list.component.html',
})

export class MediaListComponent implements OnInit {

  adminLoginId: number;
  events: any[];
  tasks: any[] = [];
  pageNumber: number = 1;
  pageSize: number = 100;
  searchTerm: string;
  count: number;
  startFrom: number = 1;
  endOn: number = this.pageSize;
  numberOfPages: number;
  lastPageClass: string;
  firstPageClass: string = "disabled";
  previousPageClass: string = "disabled";
  nextPageClass: string;
  isAscending: boolean = false;
  sortColumn: string;
  showFeedback: boolean = false;
  feedbacks: any[];
  selectedEvent: number = 0;

  constructor(private _service: FeedbackService, private authentication: AuthenticationService) {
    var details = this.authentication.getToken();
    this.adminLoginId = details.access_token.adminLoginId;
  }



  getEvents() {
    var obj = { AdminLoginId: this.adminLoginId, IsPublic: true, PageNumber: this.pageNumber, PageSize: this.pageSize, SearchTerm: this.searchTerm, IsAscending: this.isAscending, SortBy: this.sortColumn };
    console.log(obj);
    this._service.getAllEvents(obj).subscribe(response => {
      if (response.isSuccess) {
        this.events = response.list;
        this.tasks = response.taskList;
        this.count = response.count;
        for (var i = 0; i < this.events.length; i++) {
          for (var j = 0; j < this.events[i].feedback.length; j++) {
            if (this.events[i].feedback[j].feedbackMedia.length > 0) {
              this.events[i].mediaFileUrl = this.events[i].feedback[j].feedbackMedia[0].mediaUrl;
              this.events[i].address = this.events[i].feedback[j].address;
              this.events[i].latitude = this.events[i].feedback[j].latitude;
              this.events[i].longitude = this.events[i].feedback[j].longitude;
              break;
            }
          }
        }

        for (var i = 0; i < this.tasks.length; i++) {
          for (var j = 0; j < this.tasks[i].feedback.length; j++) {
            if (this.tasks[i].feedback[j].feedbackMedia.length > 0) {
              this.tasks[i].mediaFileUrl = this.tasks[i].feedback[j].feedbackMedia[0].mediaUrl;
              this.tasks[i].address = this.tasks[i].feedback[j].address;
              this.tasks[i].latitude = this.tasks[i].feedback[j].latitude;
              this.tasks[i].longitude = this.tasks[i].feedback[j].longitude;
              break;
            }
          }
        }
        if (this.count == 0) {
          this.startFrom = 0;
        }
        else {
          this.startFrom = ((this.pageNumber - 1) * this.pageSize) + 1;
        }
        if (this.count <= this.pageSize) {
          this.endOn = this.count;
          this.nextPageClass = "disabled";
          this.lastPageClass = "disabled";
        }
        else {
          this.endOn = this.pageNumber * this.pageSize;
        }
        var pages = this.count / this.pageSize;
        this.numberOfPages = 0;
        for (var i = 0; i < pages; i++) {
          this.numberOfPages = i + 1;
        }
        if (this.endOn > this.count) {
          this.endOn = this.count;
      }
      }
    });
  }

  showEventFeedback(evnt: any) {
    this.showFeedback = true;
    var obj = { EventId: evnt.eventId, PageSize: 500, PageNumber: 1 };
    this._service.getEventFeedback(obj).subscribe(response => {
      if (response.isSuccess) {
        this.feedbacks = response.list;
        this.selectedEvent = evnt.eventId;
      }
    });
  }

  firstPage() {
    if (this.pageNumber > 1) {
      this.firstPageClass = "disabled";
      this.previousPageClass = "disabled";
      this.nextPageClass = "";
      this.lastPageClass = "";
      this.pageNumber = 1;
      this.getEvents();
      this.feedbacks = null;
      this.selectedEvent = 0;
    }
  }

  lastPage() {
    if (this.pageNumber < this.numberOfPages) {
      this.firstPageClass = "";
      this.previousPageClass = "";
      this.nextPageClass = "disabled";
      this.lastPageClass = "disabled";
      this.pageNumber = this.numberOfPages;
      this.getEvents();
      this.feedbacks = null;
      this.selectedEvent = 0;
    }
  }

  previousPage() {
    if (this.pageNumber > 1) {
      this.pageNumber = this.pageNumber - 1;
      if (this.pageNumber == 1) {
        this.firstPageClass = "disabled";
        this.previousPageClass = "disabled";
        this.nextPageClass = "";
        this.lastPageClass = "";
      }
      this.getEvents();
      this.feedbacks = null;
      this.selectedEvent = 0;

    }
  }

  nextPage() {
    if (this.pageNumber < this.numberOfPages) {
      this.pageNumber = this.pageNumber + 1;
      this.firstPageClass = "";
      this.previousPageClass = "";
      if (this.pageNumber == this.numberOfPages) {
        this.nextPageClass = "disabled";
        this.lastPageClass = "disabled";
      }
      this.getEvents();
      this.feedbacks = null;
      this.selectedEvent = 0;
    }
  }

  search() {
    this.pageNumber = 1;
    this.firstPageClass = "disabled";
    this.previousPageClass = "disabled";
    this.nextPageClass = "";
    this.lastPageClass = "";
    this.getEvents();
    this.feedbacks = null;
    this.selectedEvent = 0;
  }

  changePageSize(pageSize: number) {
    this.pageSize = pageSize;
    this.pageNumber = 1;
    this.firstPageClass = "disabled";
    this.previousPageClass = "disabled";
    this.nextPageClass = "";
    this.lastPageClass = "";
    this.getEvents();
    this.feedbacks = null;
    this.selectedEvent = 0;
  }

  checkIfBoxEmpty() {
    if (this.searchTerm == '' || this.searchTerm == null) {
      this.pageNumber = 1;
      this.firstPageClass = "disabled";
      this.previousPageClass = "disabled";
      this.nextPageClass = "";
      this.lastPageClass = "";
      this.getEvents();
      this.feedbacks = null;
      this.selectedEvent = 0;
    }
  }

  setStyle(evnt: any) {
    if (this.selectedEvent == evnt.eventId) {
      return "#aac6ef";
    }
    else {
      return "";
    }
  }


  ngOnInit() {
    this.getEvents();
  }
}
