
import {throwError as observableThrowError,  Observable } from 'rxjs';

import {catchError, tap} from 'rxjs/operators';
import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';



import { Router } from '@angular/router';
import { environment } from './../../../environments/environment';
@Injectable()
export class WallPostService {

  constructor(private router: Router, private http: HttpClient) { }


  createPost(obj): Observable<any> {

    const formData: FormData = new FormData();
    formData.append('CorporateId', obj.CorporateId);
    formData.append('Description', obj.Description);
    formData.append('CreatedBy', obj.CreatedBy);
    formData.append('IsAdminPost', obj.IsAdminPost);
    formData.append('VideoUrl',obj.VideoUrl);
    if(obj.file!= null){
        formData.append('UploadImage', obj.file, obj.file.name);
    }

    return this.http.post<any>(environment.apiBasePath + '/WallPost/CreateWallPost', formData).pipe(
      tap(res => res),
      catchError(this.handleError),);
  }
 
  getNewsFeedsVolunteer(obj):Observable<any> {
    return this.http.post<any>(environment.apiBasePath + '/WallPost/GetWallPostVolunteer',obj).pipe(
      tap(res => res),
      catchError(this.handleError),);
  }

  getWallPostForAdminMyfeed(obj): Observable<any> {
    return this.http.post<any>(environment.apiBasePath + '/WallPost/GetAdminWallPost', obj).pipe(
      tap(res => res),
      catchError(this.handleError),);
  }
  getWallPostForSuperAdminMyfeed(obj): Observable<any> {
    return this.http.post<any>(environment.apiBasePath + '/WallPost/GetSuperAdminWallPost', obj).pipe(
      tap(res => res),
      catchError(this.handleError),);
  }
  getWallPostForSuperAdminCorporate(obj): Observable<any> {
    return this.http.post<any>(environment.apiBasePath + '/WallPost/GetSuperAdminCorporateWallPost', obj).pipe(
      tap(res => res),
      catchError(this.handleError),);
  }
  getCommentBasedonWallpostId(obj):Observable <any>{
    return this.http.post<any>(environment.apiBasePath + '/WallPost/GetComments', obj).pipe(
    tap(res => res),
    catchError(this.handleError),);
  }
  callLikeService(obj):Observable <any> {
    return this.http.post<any>(environment.apiBasePath + '/WallPost/WallPostLike', obj).pipe(
    tap(res => res),
    catchError(this.handleError),);
  }
  callWallpostComment(obj):Observable<any> {
    return this.http.post<any>(environment.apiBasePath + '/WallPost/AddWallPostComment', obj).pipe(
    tap(res => res),
    catchError(this.handleError),);
  }

  deleteWallPostFeed(obj): Observable<any> {
    return this.http.post<any>(environment.apiBasePath + '/WallPost/DeleteWallPost', obj).pipe(
      tap(res => res),
      catchError(this.handleError),);
  }

  deleteWallpostComment(obj): Observable<any>{
    return this.http.post<any>(environment.apiBasePath + '/WallPost/DeleteComment', obj).pipe(
    tap(res => res),
    catchError(this.handleError),);
  }
  
  callCreatePost(obj):Observable<any>{
    return this.http.post<any>(environment.apiBasePath + '/WallPost/CreateWallPost', obj).pipe(
    tap(res => res),
    catchError(this.handleError),);
  }


  private handleError(error: HttpErrorResponse) {
    console.error(error);
    let errorMessage = '';
    if (error.error instanceof Error) {
      // A client-side or network error occurred. Handle it accordingly.
      errorMessage = `An error occurred: ${error.error.message}`;
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      errorMessage = `Server returned code: ${error.status}, error message is: ${error.message}`;
    }
    console.error(errorMessage);
    return observableThrowError(error.error);
  }

}
