import { AfterViewInit, Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { AdminVolunteerService } from '../../shared/services/volunteer.service';
import { AuthenticationService } from '../../shared/services/authentication.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { LearningZoneService } from '../../shared/services/learningzone.service';



@Component({
  selector: 'learningzone-create',
  templateUrl: './learningzone-create.component.html',
})

export class LearningZoneCreateComponent implements OnInit {

  adminLoginId: number;
  corporateId: number;
  LearningPostForm: FormGroup;
  validateForm: boolean = false;
  file: any;
  file1: any;
  imageUrl1: string;
  Videofile:any;
  imageUrl: string;
  mediaType: number = 1;
  isAdminPost =  true;
  errorMessage: string;
  fileName : string;




  constructor(private authentication: AuthenticationService, private learningZone: LearningZoneService,  private router: Router, private route: ActivatedRoute) {
    var details = this.authentication.getToken();
    this.adminLoginId = details.access_token.adminLoginId;
    this.corporateId = details.access_token.corporateId;
  }

  createForm() {

    this.LearningPostForm = new FormGroup({
      title: new FormControl('',Validators.required),
      description: new FormControl('', Validators.required),
    });

  }


  selectValueType(type: number) {
    this.errorMessage = '';
    if (type == 1) {
      this.mediaType = 1;
    }
    else {
      this.mediaType = 2;
    }
    this.fileName = '';
    this.imageUrl ='';
    this.imageUrl1 = '';
    this.file = null;
    this.Videofile = null;
    this.file1 = null;


  }
 selectImage(event) {
    var files = event.target.files;
    this.file = files[0];
    var reader = new FileReader();
    reader.onload = (e: any) => {
      this.imageUrl = e.target.result;
    }
    reader.readAsDataURL(files[0]);

  }
   selectImage1(event) {
    var files = event.target.files;
    this.file1 = files[0];
    var reader = new FileReader();
    reader.onload = (e: any) => {
      this.imageUrl1 = e.target.result;
    }
    reader.readAsDataURL(files[0]);

  }

   selectVideo(event) {
    var files = event.target.files;
    this.Videofile = files[0];
    this.fileName =  files[0].name;
    var reader = new FileReader();
    reader.onload = (e: any) => {
      this.imageUrl = e.target.result;
    }
    reader.readAsDataURL(files[0]);

  }
  save() {
    this.validateForm = true;
    this .errorMessage = '';
  

if(this.mediaType == 1){
if (this.file != null && this.file != undefined) {
     if((this.LearningPostForm.valid) ){
        var obj = { file: this.file , Description: this.LearningPostForm.value.description, Title: this.LearningPostForm.value.title ,CreatedBy: this.adminLoginId , CorporateId:this.corporateId,IsAdminPost :true, MediaType:this.mediaType};
       console.log(obj);
       this.learningZone.createPost(obj).subscribe(response => {
         if (response.isSuccess) {
         this.router.navigate(['/sa/learningZone']);
          console.log(response);

         }
       });
          }
        }
          else{
             this.errorMessage = "Please select file";
          }
     }
     else{
       if (this.Videofile != null && this.Videofile != undefined  && this.file1 != null  && this.file1 != undefined) {
     if((this.LearningPostForm.valid) ){
        var obj = { file: this.Videofile , Description: this.LearningPostForm.value.description, Title: this.LearningPostForm.value.title ,CreatedBy: this.adminLoginId , CorporateId:this.corporateId,IsAdminPost :true, MediaType:this.mediaType};
       console.log(obj);
       this.learningZone.createPost(obj).subscribe(response => {
         if (response.isSuccess) {
         //this.router.navigate(['/admin/learningZone']);
          console.log(response);
          var obj = { file: this.file1 , learningZoneId: response.data.learningZoneId};
       console.log(obj);
       this.learningZone.UploadThumbnail(obj).subscribe(response => {
         if (response.isSuccess) {
         this.router.navigate(['/sa/learningZone']);
          console.log(response);

         }
       });

         }
       });
          }
        }
          else{
             this.errorMessage = "Please select file";
          }
     }
   
  }

   ngOnInit() {
     this.createForm();
  }
}
