import { AfterViewInit, Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { AuthenticationService } from '../../shared/services/authentication.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AdminMasterService } from './../../shared/services/master.services';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
    selector: 'users-list',
    templateUrl: './users-list.component.html',
  })

  export class UsersListComponent implements OnInit {


    adminLoginId: number;
    allUsers: any[] = [];
    pageNumber: number = 1;
    pageSize: number = 10;
    searchTerm: string;
    count: number;
    startFrom: number = 1;
    endOn: number = this.pageSize;
    numberOfPages: number;
    lastPageClass: string;
    firstPageClass: string = "disabled";
    previousPageClass: string = "disabled";
    nextPageClass: string;
    nameIcon: string = "sorting";
    emailIcon: string = "sorting";
    isAscending: boolean = false;
    sortColumn: string;

    constructor( private router: Router,private _service: AuthenticationService, private route: ActivatedRoute,private authentication: AuthenticationService,private masterService: AdminMasterService) { 
        var details = this.authentication.getToken();
      }


      getAllUsers() {
        var obj = { AdminLoginId: this.adminLoginId, IsPublic: false, PageNumber: this.pageNumber, PageSize: this.pageSize, SearchTerm: this.searchTerm, IsAscending: this.isAscending, SortBy: this.sortColumn };
        this._service.getUsers(obj).subscribe(response => {
          if (response.isSuccess) {
            this.allUsers = response.list;
            this.count = response.count;
            if (this.count == 0) {
              this.startFrom = 0;
            }
            else {
              this.startFrom = ((this.pageNumber - 1) * this.pageSize) + 1;
            }
            if (this.count <= this.pageSize) {
              this.endOn = this.count;
              this.nextPageClass = "disabled";
              this.lastPageClass = "disabled";
            }
            else {
              this.endOn = this.pageNumber * this.pageSize;
            }
            var pages = this.count / this.pageSize;
            this.numberOfPages = 0;
            for (var i = 0; i < pages; i++) {
              this.numberOfPages = i + 1;
            }
            if (this.endOn > this.count) {
              this.endOn = this.count;
          }
          }
        });
      }

      sortColumns(columnName: string) {
        switch (columnName) {
          case "Name":
            if (this.nameIcon == "sorting") {
              this. nameIcon = "sorting_asc";
              this.isAscending = true;
            }
            else if (this.nameIcon == "sorting_asc") {
              this.nameIcon = "sorting_desc";
              this.isAscending = false;
            }
            else if (this.nameIcon == "sorting_desc") {
              this.nameIcon = "sorting_asc";
              this.isAscending = true;
            }
            this.emailIcon = "sorting";
            break;

            case "Email":
            if (this.emailIcon == "sorting") {
              this. emailIcon = "sorting_asc";
              this.isAscending = true;
            }
            else if (this.emailIcon == "sorting_asc") {
              this.emailIcon = "sorting_desc";
              this.isAscending = false;
            }
            else if (this.emailIcon == "sorting_desc") {
              this.emailIcon = "sorting_asc";
              this.isAscending = true;
            }
            this.nameIcon = "sorting";
            break;
          
        }
        this.sortColumn = columnName;
        this.pageNumber = 1;
        this.getAllUsers();
      }
    
      firstPage() {
        if (this.pageNumber > 1) {
          this.firstPageClass = "disabled";
          this.previousPageClass = "disabled";
          this.nextPageClass = "";
          this.lastPageClass = "";
          this.pageNumber = 1;
          this.getAllUsers();
        }
      }
    
      lastPage() {
        if (this.pageNumber < this.numberOfPages) {
          this.firstPageClass = "";
          this.previousPageClass = "";
          this.nextPageClass = "disabled";
          this.lastPageClass = "disabled";
          this.pageNumber = this.numberOfPages;
          this.getAllUsers();
        }
      }
    
      previousPage() {
        if (this.pageNumber > 1) {
          this.pageNumber = this.pageNumber - 1;
          if (this.pageNumber == 1) {
            this.firstPageClass = "disabled";
            this.previousPageClass = "disabled";
            this.nextPageClass = "";
            this.lastPageClass = "";
          }
          this.getAllUsers();
        }
      }
    
      nextPage() {
        if (this.pageNumber < this.numberOfPages) {
          this.pageNumber = this.pageNumber + 1;
          this.firstPageClass = "";
          this.previousPageClass = "";
          if (this.pageNumber == this.numberOfPages) {
            this.nextPageClass = "disabled";
            this.lastPageClass = "disabled";
          }
          this.getAllUsers();
        }
      }
    
      search() {
        this.pageNumber = 1;
        this.firstPageClass = "disabled";
        this.previousPageClass = "disabled";
        this.nextPageClass = "";
        this.lastPageClass = "";
        this.getAllUsers();
      }
    
      changePageSize(pageSize: number) {
        this.pageSize = pageSize;
        this.pageNumber = 1;
        this.firstPageClass = "disabled";
        this.previousPageClass = "disabled";
        this.nextPageClass = "";
        this.lastPageClass = "";
        this.getAllUsers();
      }
    
      checkIfBoxEmpty() {
        if (this.searchTerm == '' || this.searchTerm == null) {
          this.pageNumber = 1;
          this.firstPageClass = "disabled";
          this.previousPageClass = "disabled";
          this.nextPageClass = "";
          this.lastPageClass = "";
          this.getAllUsers();
        }
      }


      deleteUser(user: any, index: number) {
        var obj = { AdminLoginId:user.adminLoginId};
        console.log(obj);
        this._service.deleteUser(obj).subscribe(response => {
          this.pageNumber = 1;
          this.getAllUsers();
        });
      }
    ngOnInit() {
        this.getAllUsers();
 
      }
  }
