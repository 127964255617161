import { AfterViewInit, Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { MasterNGOService } from '../../shared/services/masterNGO.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AdminMasterService } from './../../shared/services/master.services';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from './../../shared/services/authentication.service';
import { PushNotificationService } from '../../shared/services/pushNotification.service';
//import { DISABLED } from '@angular/forms/src/model';



@Component({
  selector: 'pushnotification',
  templateUrl: './pushnotification.component.html',
})

export class PushNotificationComponent implements OnInit {

  PNForm: FormGroup;
  successMessage: string;
  errorMessage: string
  states: any[] = [];
  corporates: any[] = [];
  validateForm: boolean = false;
  disabled: boolean = false;
  test:string;

  constructor(private pnService: PushNotificationService, private masterService: AdminMasterService, private _service: AuthenticationService) { }

  createForm() {
    this.PNForm = new FormGroup({
      message: new FormControl('', Validators.required),
      stateId: new FormControl(''),
      allUsers: new FormControl(false),
      corporateId: new FormControl('')
    });
  }

  save() {
    this.validateForm = true;
    this.errorMessage = '';
    this.successMessage = '';
    if (this.PNForm.valid) {

      if (this.PNForm.value.message.trim() == ""){
        this.errorMessage = "Please enter the message";

      }
      else if (this.PNForm.value.stateId == "" && this.PNForm.value.corporateId == "")
      {
      this.errorMessage = "Please select either state or corporate";

    }
    //else if(this.PNForm.value.corporateId == "")
    //  {
    //  this.errorMessage = "Please select the corporate";

    //  }
    else
    {

   this.pnService.sendPN(this.PNForm.value).subscribe(response => {
       console.log(response);

       this.PNForm.get('stateId').enable();
       this.PNForm.get('corporateId').enable();
       this.disabled = false;

       if (response.isSuccess) {
           this.validateForm = false;
          this.createForm();
          this.successMessage = "Push notification sent successfully";
          this.errorMessage = '';

        }
        else{
          this.errorMessage =  "Volunteer not available in the selected combination";
          this.successMessage = '';
       }

       

      });
    }
   
    }
  }

  getStates() {
    var obj = { pageNumber: 1, PageSize: 500, CountryId: 24 };
    this.masterService.getStates(obj).subscribe(response => {
        this.states = response.data;
        console.log(response.data);
    });
  }

  getCorporates() {
    var obj = { pagenumber: 1, pagesize: 500 };
    this._service.getAllCompanies(obj).subscribe(response => {
      if (response.isSuccess) {
        this.corporates = response.list;
      }
    });
  }

  changeStatus() {
    if (!this.disabled) {
      this.PNForm.get('stateId').disable();
      this.PNForm.get('corporateId').disable();
      this.disabled = true;
    }
    else {
      this.PNForm.get('stateId').enable();
      this.PNForm.get('corporateId').enable();
      this.disabled = false;
    }
  }

  ngOnInit() {
    this.createForm();
    this.getStates();
    this.getCorporates();
  }
}
