import { AfterViewInit, Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { AdminCampaignService } from '../../../shared/services/campaigns.service';
import { AdminEventsService } from '../../../shared/services/events.services';
import { AuthenticationService } from '../../../shared/services/authentication.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { DatepickerOptions } from 'ng2-datepicker';
import { AdminMasterService } from '../../../shared/services/master.services';
import * as _moment from 'moment';


@Component({
  selector: 'campaign-taskcreate',
  templateUrl: './campaign-taskcreate.component.html',
})

export class CampaignTaskCreateComponent implements OnInit {


  adminLoginId: number;
  campaigntaskForm: FormGroup;
  validateForm: boolean = false;
  campaignId: number;
  statusId: number = 2;
  taskId: number;
  file: any;
  imageUrl: string;
  eventGoals: any[] = [];
  campaignDetail: any;
    startDateOptions: DatepickerOptions = {
        minDate: new Date((new Date().setDate((new Date(Date.now()).getDate() - 1)))) // Minimal selectable date
    };
    endDateOptions: DatepickerOptions = {
        minDate: new Date((new Date().setDate((new Date(Date.now()).getDate() - 1)))) // Minimal selectable date
    };
  skillSets: any[] = [];
  skills: any[] = [];
  showSkills: boolean = false;

  constructor(private _service: AdminCampaignService, private route: ActivatedRoute, private authentication: AuthenticationService, private router: Router, private eventService: AdminEventsService, private masterService: AdminMasterService) {
    var details = this.authentication.getToken();
    this.adminLoginId = details.access_token.adminLoginId;
    router.events.subscribe((response) => {
      this.campaignId = route.snapshot.params['id'];
      this.taskId = route.snapshot.params['taskid'];
    });
  }

    whiteSpaceValidator(
        control: FormControl
    ): { [key: string]: any } | null {
        var val = control.value;

        for (var i = 0; i <= val.length - 1; i++) {
            val = val.replace("&nbsp;", "");
        }
        if (val.trim() == "") {
            return { 'hasWhiteSpace': true }
        }
        else {
            return null;
        }
    }


  selectImage(event) {
    var files = event.target.files;
    this.file = files[0];
    var reader = new FileReader();
    reader.onload = (e: any) => {
      this.imageUrl = e.target.result;
    }
    reader.readAsDataURL(files[0]);

  }

  selectValueType(type: number) {
    if (type == 1) {
      this.showSkills = false;
    }
    else {
      this.showSkills = true;
    }
  }

  createForm() {

    this.campaigntaskForm = new FormGroup({
        title: new FormControl('', Validators.compose([Validators.required, this.whiteSpaceValidator])),
        description: new FormControl('', Validators.compose([Validators.required, this.whiteSpaceValidator])),
      startDate: new FormControl(new Date(), Validators.required),
      endDate: new FormControl(new Date(), Validators.required),
      maximumHours: new FormControl('', Validators.required),
      pictureUrl: new FormControl(''),
      goalId: new FormControl('', Validators.required),
      value: new FormControl('', Validators.required),
      skillSets: new FormControl([]),
    }, { validators: this.dateLessThan('startDate', 'endDate') });
  }


  dateLessThan(from: string, to: string) {
    return (group: FormGroup): { [key: string]: any } => {
      let f = group.controls[from];
        let t = group.controls[to];

        var fromDate = _moment(f.value).format('YYYY-MM-DD');
        var toDate = _moment(t.value).format('YYYY-MM-DD');
        if (fromDate > toDate) {
        return {
          endDate: "Date from should be less than Date to"
        };
      }
      return {};
    }
  }

    setOptions(controlName: string, evnt: any) {
        switch (controlName) {
            case 'start':
                this.endDateOptions = {
                    minDate: new Date((new Date().setDate((new Date(evnt).getDate() - 1)))), // Minimal selectable date
                };
                break;
            default:
                break;
        }
    }



  save() {
    this.validateForm = true;
    if (this.campaigntaskForm.valid) {
      if (this.campaigntaskForm.value.title.trim() != '') {
          
      }
      if (this.campaigntaskForm.value.description.trim() != '') {

      }
      // this.campaigntaskForm.value.value = parseFloat(this.campaigntaskForm.value.value).toFixed(2);
      this.campaigntaskForm.value.adminLoginId = this.adminLoginId;
      this.campaigntaskForm.value.campaignId = this.campaignId;
      this.campaigntaskForm.value.statusId = this.statusId;
      this.campaigntaskForm.value.taskid = this.taskId;
      this.campaigntaskForm.value.skillSets = this.skillSets;
      let startDate = new Date(this.campaigntaskForm.value.startDate);
      if (startDate.getDate() != new Date().getDate()) {
        startDate.setDate(startDate.getDate() + 1);
      }
      this.campaigntaskForm.value.startDate = startDate;
      let endDate = new Date(this.campaigntaskForm.value.endDate);
      if (endDate.getDate() != new Date().getDate()) {
        endDate.setDate(endDate.getDate() + 1);
      }
      this.campaigntaskForm.value.endDate = endDate;

      let html = this.campaigntaskForm.value.description;

      var oParser = new DOMParser();
      var oDOM = oParser.parseFromString(html, "text/html");
      var text = oDOM.body.innerText;
      this.campaigntaskForm.value.description = text;

      this._service.createSuperAdminTask(this.campaigntaskForm.value).subscribe(response => {
        if (response.isSuccess) {
          if (this.file != null && this.file != undefined) {
            // upload image
            var obj = { campaignId: response.data.campaignId, file: this.file, TaskId: response.data.taskId };
            this._service.UploadTaskImage(obj).subscribe(response => {
              if (response.isSuccess) {
                this.imageUrl = response.data.imageUrl;
              }
            });
          }
          this.maganeTapped();
          //this.router.navigate(['/sa/campaigns/taskList/' + this.campaignId]);
        }
      });
    }
  }


  maganeTapped(){
    this.router.navigate(['/sa/campaigns/taskList/' + this.campaignId]);

  }
  saveAsDraft() {
    this.statusId = 1;

  }
  getTaskDetails() {
    var obj = { taskId: this.taskId, campaignId: this.campaignId };
    this._service.getGetTaskDetail(obj).subscribe(response => {
      if (response.isSuccess) {
        var detail = response.data;
        this.imageUrl = detail.imageUrl;
        this.skillSets = detail.skillSets;
        this.campaigntaskForm.setValue({
          title: detail.title,
          description: detail.description,
          startDate: new Date(detail.startDate),
          endDate: new Date(detail.endDate),
          maximumHours: detail.maximumHours,
          pictureUrl: detail.imageUrl,
          goalId: detail.goalId,
          value: detail.value,
          skillSets: this.skillSets,
        });
        if (this.skillSets && this.skillSets.length > 0) {
          this.showSkills = true;
        }
      }
    });
  }

  selectSkill(skillId: any) {
    var s = this.skills.filter(a => a.skillId == skillId);
    var skill = s[0];
    var exist = this.skillSets.filter(a => a.skillId == skill.skillId);
    if (exist.length == 0) {
      this.skillSets.push(skill);
    }
    this.calculateAverageValue();
  }

  removeSkill(index: number) {
    this.skillSets.splice(index, 1);
    this.calculateAverageValue();
  }

  calculateAverageValue() {
    var value = 0;
    for (var i = 0; i < this.skillSets.length; i++) {
      value += +this.skillSets[i].rate;
    }
    var avg = value / this.skillSets.length;
    this.campaigntaskForm.controls["value"].setValue(avg);
  }



  canceltapped() {
    this.router.navigate(['/sa/campaigns/taskList/' + this.campaignId]);
  }

  ngOnInit() {
    var obj = { pageNumber: 1, PageSize: 500 };
    this.eventService.getEventGoals(obj).subscribe(response => {
      this.eventGoals = response.list;
    });
    this.masterService.getSkills(obj).subscribe(response => {
      if (response.isSuccess) {
        this.skills = response.list;
      }
    });
    if (this.campaignId > 0) {
      var o = { "CampaignId": this.campaignId };
      this._service.getCampaignDetail(o).subscribe(response => {
        if (response.isSuccess) {
          this.campaignDetail = response.data;
        }
      })
    }
    this.createForm();
    if (this.taskId > 0) {
      this.getTaskDetails();

    }

  }

}
