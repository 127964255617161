
import {throwError as observableThrowError,  Observable } from 'rxjs';

import {catchError, tap} from 'rxjs/operators';
import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';



import { Router } from '@angular/router';
import { environment } from './../../../environments/environment';

@Injectable()
export class BeneficiaryService {

  constructor(private router: Router, private http: HttpClient) { }

  getBeneficiaryCampaigns(obj):Observable<any>{
    return this.http.post(environment.apiBasePath+'/User/GetBeneficiaryCurriculumCampaign',obj).pipe(
                    tap(res => res),
                    catchError(this.handleError),);
  }

  getActivityCurriculums(obj):Observable<any>{
    return this.http.post(environment.apiBasePath+'/User/GetBeneficiaryCurriculums',obj).pipe(
                    tap(res => res),
                    catchError(this.handleError),);
  }

  getBeneficiaryList(obj):Observable<any>{
    return this.http.post(environment.apiBasePath+'/Volunteer/GetBeneficiaryList',obj).pipe(
                    tap(res => res),
                    catchError(this.handleError),);
  }

  getCurriculumBeneficiaryList(obj):Observable<any>{
    return this.http.post(environment.apiBasePath+'/User/GetCurriculumBeneficiaryList',obj).pipe(
                    tap(res => res),
                    catchError(this.handleError),);
  }

  createBeneficiary(obj):Observable<any>{
    return this.http.post(environment.apiBasePath+'/User/CreateBeneficiaryUser',obj).pipe(
                    tap(res => res),
                    catchError(this.handleError),);
  }
  
  assignCurriculumToBeneficiary(obj):Observable<any>{
    return this.http.post(environment.apiBasePath+'/User/AssignCurriculumToBeneficiary',obj).pipe(
                    tap(res => res),
                    catchError(this.handleError),);
  }

  unassignCurriculum(obj):Observable<any>{
    return this.http.post(environment.apiBasePath+'/User/UnassignCurriculumFromBeneficiary',obj).pipe(
                    tap(res => res),
                    catchError(this.handleError),);
  }

  getBeneficiaryAssignedCurriculum(obj):Observable<any>{
    return this.http.post(environment.apiBasePath+'/User/GetBeneficiaryAssignedCurriculums',obj).pipe(
                    tap(res => res),
                    catchError(this.handleError),);
  }

  getBeneficiaryQuizList(obj):Observable<any>{
    return this.http.post(environment.apiBasePath+'/User/GetBeneficiaryQuizList',obj).pipe(
                    tap(res => res),
                    catchError(this.handleError),);
  }

  getBeneficiaryQuizDetail(obj):Observable<any>{
    return this.http.post(environment.apiBasePath+'/User/GetQuizDetail',obj).pipe(
                    tap(res => res),
                    catchError(this.handleError),);
  }

  performActionOnBeneficiary(obj):Observable<any>{
    return this.http.post(environment.apiBasePath+'/User/PerformActionOnBeneficiary',obj).pipe(
                    tap(res => res),
                    catchError(this.handleError),);
  }

  private handleError(error: HttpErrorResponse) {
    console.error(error);
    let errorMessage = '';
    if (error.error instanceof Error) {
      // A client-side or network error occurred. Handle it accordingly.
      errorMessage = `An error occurred: ${error.error.message}`;
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      errorMessage = `Server returned code: ${error.status}, error message is: ${error.message}`;
    }
    console.error(errorMessage);
    return observableThrowError(error.error);
  }

}
