import { AfterViewInit, Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from './../../shared/services/authentication.service';
import { AdminMasterService } from './../../shared/services/master.services';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'admin-profile',
  templateUrl: './admin-profile.component.html',
})

export class AdminProfileComponent implements OnInit {
  adminLoginId: number;
  personalInfoForm: FormGroup;
  corporateInfoForm: FormGroup;
  validateForm: boolean = false;
  validateCorporateForm: boolean = false;
  states: any[] = [];
  cities: any[] = [];
  countries: any[] = [];
  corporateimageUrl: string;
  corprateLogofile: any;

  corporateLicenseUrl: string;
  corprateLicensefile: any;

  adminProfileUrl: string;
  adminProfileFile: any;
  fullname: string;
  errorMessage: string;
  Email:string;



  constructor(private router: Router, private _service: AuthenticationService, private route: ActivatedRoute, private authentication: AuthenticationService, private masterService: AdminMasterService) {
    var details = this.authentication.getToken();
    this.adminLoginId = details.access_token.adminLoginId;
  }


  createForm() {
    this.personalInfoForm = new FormGroup({
      adminEmail: new FormControl('', Validators.required),
      adminFirstName: new FormControl('', Validators.required),
      adminLastName: new FormControl('', Validators.required),
      adminMobileNumber: new FormControl('', [Validators.required,Validators.minLength(10)]),
      adminProfilePicture: new FormControl(''),

    });
  }

  getProfile() {
    var obj = { adminloginId: this.adminLoginId };
    this._service.getSuperAdminProfile(obj).subscribe(response => {
      if (response.isSuccess) {
        var detail = response.data;
        this.fullname = detail.adminFirstName + " " + detail.adminLastName;
        this.personalInfoForm.setValue({
          adminEmail: detail.adminEmail,
          adminFirstName: detail.adminFirstName,
          adminLastName: detail.adminLastName,
          adminMobileNumber: detail.adminMobileNumber,
          adminProfilePicture: detail.adminProfilePicture,

        });
        this.adminProfileUrl = detail.adminProfilePicture;
        this.Email = detail.adminEmail;
      }
    });
  }

  UpdatePersonalProfile() {
    this.validateForm = true;
    if (this.personalInfoForm.valid) {
      this.personalInfoForm.value.adminLoginId = this.adminLoginId;
      this._service.updateSuperAdminProfile(this.personalInfoForm.value).subscribe(response => {
        if (response.isSuccess) {
          this.errorMessage = "Personal Info Updated Successfully";
          this.getProfile()
        }
      });
    }
  }


  selectProfileUrl(event) {
    var files = event.target.files;
    this.adminProfileFile = files[0];
    var reader = new FileReader();
    reader.onload = (e: any) => {
      this.adminProfileUrl = e.target.result;
    }
    reader.readAsDataURL(files[0]);

    if (this.adminProfileFile != null && this.adminProfileFile != undefined) {
      // upload image
      var obj = { adminLoginId: this.adminLoginId, file: this.adminProfileFile };
      this._service.uploadProfile(obj).subscribe(response => {
        if (response.isSuccess) {
          this.adminProfileUrl = response.data.profilePicture;
          var details = this.authentication.getToken();
          details.access_token.profilePicture = this.adminProfileUrl;
          this.authentication.setToken(details);
        }
      });
    }
  }

  ngOnInit() {
    this.createForm();
    this.getProfile();
  }
}

