import { AfterViewInit, Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { AdminVolunteerService } from '../../shared/services/volunteer.service';
import { AuthenticationService } from '../../shared/services/authentication.service';
import { WallPostService } from '../../shared/services/wallpost.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';


@Component({
  selector: 'wallpost-create',
  templateUrl: './wallpost-create.component.html',
})

export class WallPostCreateComponent implements OnInit {

  adminLoginId: number;
  corporateId: number;
  commentForm: FormGroup;
  validateForm: boolean = false;
  statusId: number = 2;
  campiagnId: number;
  file: any;
  imageUrl: string;
  isVideoSelected:boolean = false;

  constructor(private authentication: AuthenticationService, private _service: WallPostService, private router: Router,private route: ActivatedRoute ) {
    var details = this.authentication.getToken();
    this.adminLoginId = details.access_token.adminLoginId;
  }

  createForm() {

    this.commentForm = new FormGroup({
      description: new FormControl('', this.WhiteSpaceValidator),
      videoUrl: new FormControl('')
    });

  }
  selectImage(event) {
    var files = event.target.files;
    this.file = files[0];
    var reader = new FileReader();
    reader.onload = (e: any) => {
      this.imageUrl = e.target.result;
    }
    reader.readAsDataURL(files[0]);

  }

  WhiteSpaceValidator(
    control: FormControl
): { [key: string]: any } | null {
    var val = control.value;
    if (val.trim() == "") {
        return { 'hasWhiteSpace': true }
    }
    else {
        return null;
    }
}

keyDownHandler(event) {
  if (event.target.value.length > 0) {
    return true;
  } else {
    if (event.keyCode === 32) {
      return false;
    }
  }
}

  enableVideo(){
    this.setValidators();
    this.isVideoSelected = true;
  }

  disableVideo(){
    this.removeValidators();
    this.isVideoSelected = false;
  }

  setValidators(){
      this.commentForm.get('videoUrl').setValidators([this.WhiteSpaceValidator]);
  }
  removeValidators(){
    var control =  this.commentForm.get('videoUrl');
      control.clearValidators();
      control.setErrors(null);
  }

  save() {
    this.validateForm = true;

    if(this.isVideoSelected && (this.imageUrl=='' || this.imageUrl==null )){
      return;
    }

    if(this.commentForm.valid)
      var obj = { file: this.file , Description: this.commentForm.value.description ,VideoUrl:this.commentForm.value.videoUrl, CorporateId:this.corporateId,  CreatedBy: this.adminLoginId ,IsAdminPost :true};
      this._service.createPost(obj).subscribe(response => {
        if (response.isSuccess) {
          this.router.navigate(['/sa/newsFeed']);
        }
      });
    
  }
  
  ngOnInit() {
     this.createForm();
  }
}
