
import {throwError as observableThrowError,  Observable } from 'rxjs';

import {catchError, tap} from 'rxjs/operators';
import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';



import { Router } from '@angular/router';
import { environment } from './../../../environments/environment';

@Injectable()
export class AdminEventsService {

  constructor(private router: Router, private http: HttpClient) { }

  // Events

  searchCorporate(obj): Observable<any> {
    return this.http.post<any>(environment.apiBasePath + '/Corporate/Search', obj).pipe(
      tap(res => res),
      catchError(this.handleError),);
  }

  getSuperAdminEvents(obj): Observable<any> {
    return this.http.post<any>(environment.apiBasePath + '/Event/GetSuperAdminEvents', obj).pipe(
      tap(res => res),
      catchError(this.handleError),);
  }

  createSuperAdminEvent(obj): Observable<any> {
    console.log(obj);
    console.log("PAyload for Event");
    return this.http.post<any>(environment.apiBasePath + '/Event/CreateSuperAdminEvent', obj).pipe(
      tap(res => res),
      catchError(this.handleError),);
  }

  getAll(obj): Observable<any> {
    return this.http.post<any>(environment.apiBasePath + '/Event/GetAll', obj).pipe(
      tap(res => res),
      catchError(this.handleError),);
  }

  createEvent(obj): Observable<any> {
    return this.http.post<any>(environment.apiBasePath + '/Event/Create', obj).pipe(
      tap(res => res),
      catchError(this.handleError),);
  }

  performAction(obj): Observable<any> {
    return this.http.post<any>(environment.apiBasePath + '/Event/PerformAction', obj).pipe(
      tap(res => res),
      catchError(this.handleError),);
  }

  addEventUsers(obj): Observable<any> {
    return this.http.post<any>(environment.apiBasePath + '/Event/AddEventUsers', obj).pipe(
      tap(res => res),
      catchError(this.handleError),);
  }

  pushNotificationforEventUser(obj): Observable<any> {
    return this.http.post<any>(environment.apiBasePath + '/Event/SendPNtoEventUsers', obj).pipe(
      tap(res => res),
      catchError(this.handleError),);
  }
  deleteEvent(obj): Observable<any> {
    return this.http.post<any>(environment.apiBasePath + '/Event/Delete', obj).pipe(
      tap(res => res),
      catchError(this.handleError),);
  }

  getDetails(obj): Observable<any> {
    return this.http.post<any>(environment.apiBasePath + '/Event/Detail', obj).pipe(
      tap(res => res),
      catchError(this.handleError),);
  }

  getSuperAdminEventDetails(obj): Observable<any> {
    return this.http.post<any>(environment.apiBasePath + '/Event/SuperAdminEventDetail', obj).pipe(
      tap(res => res),
      catchError(this.handleError),);
  }

  performActionOnUser(obj): Observable<any> {
    return this.http.post<any>(environment.apiBasePath + '/Event/PerformActionOnUser', obj).pipe(
      tap(res => res),
      catchError(this.handleError),);
  }

  getEventGoals(obj): Observable<any> {
    return this.http.post<any>(environment.apiBasePath + '/Event/Goals', obj).pipe(
      tap(res => res),
      catchError(this.handleError),);
  }

  getEventContributionTypes(obj): Observable<any> {
    return this.http.post<any>(environment.apiBasePath + '/Event/ContributionTypes', obj).pipe(
      tap(res => res),
      catchError(this.handleError),);
  }

  getLatLong(obj): Observable<any> {
    return this.http.post<any>(environment.apiBasePath + '/Master/Location', obj).pipe(
      tap(res => res),
      catchError(this.handleError),);
  }

    getEventCsv(obj): Observable<any> {
        return this.http.post<any>(environment.apiBasePath + '/Event/GetAdminEventsListCsv', obj).pipe(
            tap(res => res),
            catchError(this.handleError),);
    }
    getSuperAdminEventCsv(obj): Observable<any> {
        return this.http.post<any>(environment.apiBasePath + '/Event/GetSuperAdminEventsListCsv', obj).pipe(
            tap(res => res),
            catchError(this.handleError),);
    }


  uploadImage(obj): Observable<any> {

    const formData: FormData = new FormData();
    formData.append('EventId', obj.eventId);
    formData.append('UploadFile', obj.file, obj.file.name);

    return this.http.post<any>(environment.apiBasePath + '/Event/UploadImage', formData).pipe(
      tap(res => res),
      catchError(this.handleError),);
  }


  private handleError(error: HttpErrorResponse) {
    console.error(error);
    let errorMessage = '';
    if (error.error instanceof Error) {
      // A client-side or network error occurred. Handle it accordingly.
      errorMessage = `An error occurred: ${error.error.message}`;
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      errorMessage = `Server returned code: ${error.status}, error message is: ${error.message}`;
    }
    console.error(errorMessage);
    return observableThrowError(error.error);
  }

}
