import { AfterViewInit, Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { MasterNGOService } from '../../shared/services/masterNGO.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AdminMasterService } from './../../shared/services/master.services';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from './../../shared/services/authentication.service';
import { ReportService } from './../../shared/services/report.service';
import { DatepickerOptions } from 'ng2-datepicker';

@Component({
  selector: 'report-list',
  templateUrl: './report-list.component.html',
})

export class ReportListComponent implements OnInit {

  startDate: Date = new Date();
  endDate: Date = new Date();
  reportRequest: number = 0;
  errorMessage: string;
  

  constructor(private _service: ReportService) { }

  options: DatepickerOptions = {
    minDate: new Date((new Date().setFullYear((new Date(Date.now()).getFullYear() - 1)))), // Minimal selectable date
    maxDate: new Date()
  };

  endOptions: DatepickerOptions = {
    minDate: new Date((new Date().setFullYear((new Date(Date.now()).getFullYear() - 1)))), // Minimal selectable date
    maxDate: new Date()
  };

  setOptions(evnt: any) {
    this.endOptions = {
      minDate: evnt, // Minimal selectable date
      maxDate: new Date()
    };
  }

  downloadReport() {
   if(this.startDate > this.endDate){
  this.errorMessage = "Start date should be greater than end date";
    }


    else if( this.reportRequest == 0){
        this.errorMessage = "Please select reporting type";
    }
else{
  this.errorMessage = '';
var obj = { ReportRequest: this.reportRequest, StartDate: this.startDate, EndDate: this.endDate };
    this._service.generateReport(obj).subscribe(response => {
      if (response.isSuccess) {
        let a = document.createElement("a");
        a.target = "_self";
        a.href = response.fileUrl;
        a.setAttribute("download", response.fileUrl);
        a.click();
      }
    });
}

    
  }

  ngOnInit() {
  }




}
