import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-non-approved',
  templateUrl: './non-approved.component.html',
  styleUrls: ['./non-approved.component.scss']
})
export class NonApprovedComponent implements OnInit {

  status:any;

  constructor() { 
    this.status = localStorage.getItem("corporateApprovalStatus");
  }

  ngOnInit() {
  }

}
