import { AfterViewInit, Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { DashBoardService } from './../../shared/services/dashboard.service';


declare const AmCharts: any;

import '../../../assets/charts/amchart/amcharts.js';
import '../../../assets/charts/amchart/gauge.js';
import '../../../assets/charts/amchart/pie.js';
import '../../../assets/charts/amchart/serial.js';
import '../../../assets/charts/amchart/light.js';
import '../../../assets/charts/amchart/ammap.js';
import '../../../assets/charts/amchart/worldLow.js';
import '../../../assets/charts/amchart/continentsLow.js';

import { NotificationsService } from 'angular2-notifications';
import { ChartModule } from 'angular2-chartjs';
import { AdminEventsService } from '../../shared/services/events.services';

@Component({
  selector: 'app-default',
  templateUrl: './default.component.html',
  styleUrls: [
    './default.component.scss',
    '../../../assets/icon/icofont/css/icofont.scss'
  ],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('fadeInOutTranslate', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('400ms ease-in-out', style({ opacity: 1 }))
      ]),
      transition(':leave', [
        style({ transform: 'translate(0)' }),
        animate('400ms ease-in-out', style({ opacity: 0 }))
      ])
    ])
  ]
})
export class DefaultComponent implements OnInit {

  dashboardData: any;

  type: string = 'bar';
  data: any = {
    labels: [],
    datasets: [
      {
        label: "",
        data: [],
        backgroundColor: [
          "#e5233b",
          "#dda838",
          "#4ea145",
          "#c8202f",
          "#ef402f",
          "#2abfe7",
          "#fcc312",
          "#a41c44",
          "#f36b2d",
          "#e01668",
          "#f89d2a",
          "#c08e2f",
          "#407f46",
          "#1f97d4",
          "#5bb949",
          "#136a9f",
          "#1a4862",
          "#24b898"
        ],
      }
    ]
  };
  options: any = {
    tooltips: {
      callbacks: {
        label: function (tooltipItem, data) {
          var value = data.datasets[0].data[tooltipItem.index];
          var label = data.labels[tooltipItem.index];
          var sdg = '';
          switch (label) {
            case 1:
              sdg = 'No Poverty'
              break;
            case 2:
              sdg = 'Zero Hunger'
              break;
            case 3:
              sdg = 'Good Health and well-being'
              break;
            case 4:
              sdg = 'Quality Education'
              break;
            case 5:
              sdg = 'Gender Equality'
              break;
            case 6:
              sdg = 'Clean Water and Sanitation'
              break;
            case 7:
              sdg = 'Affordable and Clean Energy'
              break;
            case 8:
              sdg = 'Decent Work and Economic Growth'
              break;
            case 9:
              sdg = 'Industry, Innovation and Infrastructure'
              break;
            case 10:
              sdg = 'Reduced Inequality'
              break;
            case 11:
              sdg = 'Sustainable Cities and Communities'
              break;
            case 12:
              sdg = 'Responsible Consumption and Production'
              break;
            case 13:
              sdg = 'Climate Action'
              break;
            case 14:
              sdg = 'Life Below Water'
              break;
            case 15:
              sdg = 'Life on Land'
              break;
            case 16:
              sdg = 'Peace and Justice Strong Institutions'
              break;
            case 17:
              sdg = 'Partnerships to achieve the Goal'
              break;
            case 18:
              sdg = 'Others'
              break;
          }
          //var sdg = this.dashboardData.goals.filter(a => a.goalId == label);
          return sdg + ' : ' + value;
        }
      }
    },
  };


  constructor(private _service: DashBoardService, ) { }


  getDashboardData() {

    var obj = {};
    this._service.getDashBoardData(obj).subscribe(response => {
      if (response.isSuccess) {
        this.dashboardData = response;
        this.data.labels = [];
        this.data.datasets[0].data = [];
        for (var i = 0; i < this.dashboardData.goals.length; i++) {
          // this.data.labels.push(this.createSDGshortcuts(this.dashboardData.goals[i].goal));//this.dashboardData.goals[i].goalId);
          this.data.labels.push(this.dashboardData.goals[i].goalId);
          this.data.datasets[0].data.push(this.dashboardData.goals[i].amountSaved);
        }
      }
    },
      (err) => {
      });


  }

  createSDGshortcuts(sdg: string) {
    var returnString: string = '';
    var arr = sdg.split(" ");
    for (var i = 0; i < arr.length; i++) {
      returnString += arr[i].substring(0, 1).toLocaleUpperCase();
    }
    return returnString;
  }

  ngOnInit() {

    this.getDashboardData();
  }






}
