import { AfterViewInit, Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { FormControl, FormGroup, Validators, FormArray } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AdminVolunteerService } from '../../shared/services/volunteer.service';
import { AuthenticationService } from '../../shared/services/authentication.service';
import { AdminMasterService } from '../../shared/services/master.services';


@Component({
  selector: 'group-create',
  templateUrl: './group-create.component.html',
})

export class GroupCreateComponent implements OnInit {

  adminLoginId: number;
  groupForm: FormGroup;
  validateForm: boolean = false;
  groupId: number;

  constructor(private _service: AdminVolunteerService, private authentication: AuthenticationService, private masterService: AdminMasterService, private router: Router, private route: ActivatedRoute) {
    var details = this.authentication.getToken();
    this.adminLoginId = details.access_token.adminLoginId;
    router.events.subscribe((response) => {
      this.groupId = route.snapshot.params['id'];
    });
  }

  createForm() {
    this.groupForm = new FormGroup({
      groupId: new FormControl(''),
      adminLoginId: new FormControl(''),
      groupName: new FormControl('', Validators.required),
      volunteers: new FormArray([])
    });
  }

  get volunteers(): FormArray {
    return this.groupForm.get("volunteers") as FormArray;
  }

  add(data?: any) {
    this.volunteers.push(new FormGroup({
      userLoginId: new FormControl(0),
      email: new FormControl('', Validators.required),
      mobileNumber: new FormControl(''),
      firstName: new FormControl(''),
      lastName: new FormControl(''),
      isNew: new FormControl(true),
      volunteerSearchTerm: new FormControl(''),
      searchedVolunteers: new FormControl([])
    }));
    console.log(this.volunteers);
  }

  remove(index: number) {
    this.volunteers.removeAt(index)
  }

  save() {
    this.validateForm = true;
    if (this.groupForm.valid) {
      this.groupForm.value.adminLoginId = this.adminLoginId;
      if (this.groupForm.value.volunteers.length > 0) {
        this._service.addGroup(this.groupForm.value).subscribe(response => {
          if (response.isSuccess) {
            this.router.navigate(['/sa/groups']);
          }
        });
      }
      else {
        alert("Please add a volunteer first.");
      }
    }
  }

  searchVolunteer(volunteer: FormGroup) {
    if (volunteer.controls.volunteerSearchTerm.value != '' && volunteer.controls.volunteerSearchTerm.value != null) {
      var obj = { SearchTerm: volunteer.controls.volunteerSearchTerm.value };
      console.log(obj);
      this._service.searchVolunteer(obj).subscribe(response => {
        console.log(response);
        if (response.isSuccess) {
          volunteer.controls.searchedVolunteers.setValue({ data: response.list });// = response.list;
          console.log(volunteer);
        }
      });
    }
    else {
      volunteer.controls.searchedVolunteers.setValue({ data: [] }); // = [];
    }
  }


  getDetails() {
    var obj = { GroupId: this.groupId };
    this._service.groupDetails(obj).subscribe(response => {
      if (response.isSuccess) {
        var details = response.data;
        this.groupForm.setValue({
          adminLoginId: '',
          groupId: '',
          groupName: '',
          volunteers: []
        });
        for (var i = 0; i < details.volunteers.length; i++) {
          this.volunteers.push(new FormGroup({
            userLoginId: new FormControl(details.volunteers[i].userLoginId),
            firstName: new FormControl(details.volunteers[i].firstName),
            lastName: new FormControl(details.volunteers[i].lastName),
            email: new FormControl(details.volunteers[i].email),
            mobileNumber: new FormControl(details.volunteers[i].mobileNumber),
            isNew: new FormControl(false),
            volunteerSearchTerm: new FormControl(''),
            searchedVolunteers: new FormControl([{ data: [] }])
          }));
        }
        console.log(this.volunteers);
        this.groupForm.setValue({
          adminLoginId: this.adminLoginId,
          groupId: details.groupId,
          groupName: details.groupName,
          volunteers: this.volunteers.value
        });

        console.log(this.groupForm.controls.volunteers);
      }
    });
  }


  //checkIfBoxEmpty() {
  //  if (volunteer.controls.volunteerSearchTerm.value == '' || volunteer.controls.volunteerSearchTerm.value == null) {
  //    this.searchedVolunteers = [];
  //  }
  //}

  selectVolunteer(volunteer: any, i: number) {
    if (!volunteer.isPartOfGroup) {
      var exist = this.volunteers.value.filter(a => a.userLoginId == volunteer.userLoginId);
      if (exist.length == 0) {
        this.volunteers.controls[i].setValue({
          userLoginId: volunteer.userLoginId,
          email: volunteer.email,
          mobileNumber: volunteer.mobileNumber,
          firstName: volunteer.firstName,
          lastName: volunteer.lastName,
          isNew: false,
          volunteerSearchTerm: '',
          searchedVolunteers: { data: [] }
        });
      }
      else {
        this.volunteers.controls.pop();
      }
    }
    else {
      alert("Volunteer is already a part of another group");
    }
  }

  ngOnInit() {
    this.createForm();
    if (this.groupId > 0) {
      this.getDetails();
    }
  }
}
