import { AfterViewInit, Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { AdminVolunteerService } from '../../shared/services/volunteer.service';
import { AuthenticationService } from '../../shared/services/authentication.service';
import { WallPostService } from '../../shared/services/wallpost.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';


@Component({
  selector: 'wallpost-detail',
  templateUrl: './wallpost-detail.component.html',
})

export class WallPostDetailComponent implements OnInit {


    adminLoginId: number;
    corporateId: number;
    wallPostId: number;
    imageUrl: string;
     wallPostCommentList: any;
     wallpost: any;

  
  
    constructor(private authentication: AuthenticationService, private _service: WallPostService, private router: Router,private route: ActivatedRoute ) {
      var details = this.authentication.getToken();
      this.adminLoginId = details.access_token.adminLoginId;
      this.corporateId = details.access_token.corporateId;


      router.events.subscribe((response) => {
        this.wallPostId = route.snapshot.params['id'];
        console.log(this.wallPostId);
      });
    }
  

    getWallPost(){

        var obj = { wallPostId:this.wallPostId };
        this._service.getCommentBasedonWallpostId(obj).subscribe(response => {
          if (response.isSuccess) {
              console.log(response);
            this.wallPostCommentList = response.list;
            this.wallpost = response.data.wallPostDetails;

          }
        });
    }
    deleteWallPostComment(commentId: number ){
        var obj = { wallPostId:this.wallPostId, commentId:commentId};
        this._service.deleteWallpostComment(obj).subscribe(response => {
          if (response.isSuccess) {
            this.getWallPost();
          }
        });
    
      }
    ngOnInit() {
        this.getWallPost();
     }

}



