import { Injectable } from '@angular/core';
import { Router,NavigationEnd, NavigationStart } from '@angular/router';
import { filter } from 'rxjs/operators';


@Injectable()
export class RoutingService {

  history = [];
  previousPageUrl:string;
  currentPageUrl:string;
  constructor(private router: Router) {
    this.previousPageUrl = router.url;
    this.router.events.subscribe(event => {
      if(event instanceof NavigationEnd){
         this.currentPageUrl = event.url;
      }
    })

   }

  public getPreviousUrl(): string {
    return this.previousPageUrl;
  }

}
