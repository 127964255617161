import { AfterViewInit, Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { MasterNGOService } from '../../shared/services/masterNGO.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AdminMasterService } from './../../shared/services/master.services';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from './../../shared/services/authentication.service';
import { IfStmt } from '@angular/compiler';
import { DatepickerOptions } from 'ng2-datepicker';






@Component({
  selector: 'companies-create',
  templateUrl: './companies-create.component.html',
})

export class CompaniesCreateComponent implements OnInit {


  email: string;
  password: string;
  errorMessage: string;
  registerForm: FormGroup;
  validateForm: boolean = false;
  states: any[] = [];
  cities: any[] = [];
  countries: any[] = [];
  corporateimageUrl: string;
  corprateLogofile: any;
  corporateLicenseUrl: string;
  corprateLicensefile: any;
  corporateId: number;
  adminLoginId: number;
  uploadedLicense: any;
  licenseName:string;
  comment: string = '';

  options: DatepickerOptions = {
    maxDate: new Date(Date.now()), // Minimal selectable date
  };

  constructor(private _service: AuthenticationService, private router: Router, private masterService: AdminMasterService, private route: ActivatedRoute) {
    router.events.subscribe((response) => {
      this.corporateId = route.snapshot.params['id'];
      this.adminLoginId = route.snapshot.params['adminLoginId'];

    });
  }

  createForm() {
    this.registerForm = new FormGroup({
      adminEmail: new FormControl('', [Validators.required, Validators.email]),
      adminFirstName: new FormControl('', Validators.required),
      adminLastName: new FormControl('', Validators.required),
      adminMobileNumber: new FormControl('', [Validators.required, Validators.minLength(10)]),
      location: new FormControl('', Validators.required),
      area: new FormControl('', Validators.required),
      zipcode: new FormControl('', Validators.required),
      cityId: new FormControl('', Validators.required),
      stateId: new FormControl('', Validators.required),
      countryId: new FormControl('', Validators.required),
      corporateName: new FormControl('', Validators.required),
      corporateRegistrationDate: new FormControl(new Date(), Validators.required),
      corporateEmail: new FormControl('', [Validators.required, Validators.email]),
      corporateMobileNumber: new FormControl('', [Validators.required, Validators.minLength(10)]),

      corporateLogo: new FormControl(''),
      licenses: new FormControl(''),
    });
  }
  register() {
    if (this.corporateId > 0) {
      this.UpdateCorporateProfile();
    }
    else {
      this.saveData();
    }

  }

  saveData() {
    this.validateForm = true;
    this.errorMessage = '';
    console.log(this.registerForm.value);
    if (this.registerForm.valid) {
      this._service.register(this.registerForm.value).subscribe(response => {
        if (!response.data.isExist) {

          if (response.isSuccess) {
            if (this.corprateLogofile != null && this.corprateLogofile != undefined) {
              // upload image
              var obj = { CorporateId: response.data.corporateId, file: this.corprateLogofile };
              this._service.addCorporateLogo(obj).subscribe(response => {
                if (response.isSuccess) {
                  this.corporateimageUrl = response.data.logo;
                }
              });
            }
            if (this.corprateLicensefile != null && this.corprateLicensefile != undefined) {
              // upload image
              var obj = { CorporateId: response.data.corporateId, file: this.corprateLicensefile };
              this._service.addCorporateLicense(obj).subscribe(response => {
                if (response.isSuccess) {
                  this.corporateLicenseUrl = response.data.logo;
                }
              });
            }
            this.router.navigate(['/sa/masters/Company']);

          }
        }
        else {
          this.errorMessage = "Company with this E-mail already exist ";
        }
      });
    }
  }
  getProfile() {
    var obj = { adminloginId: this.adminLoginId, corporateId: this.corporateId };
    console.log(obj);
    this._service.getAdminProfile(obj).subscribe(response => {
      if (response.isSuccess) {
        console.log(response);
        var detail = response.data;
        this.comment = detail.comment;
        this.registerForm.setValue({
          location: detail.address.location,
          area: detail.address.area,
          zipcode: detail.address.zipcode,
          cityId: detail.address.cityId,
          stateId: detail.address.stateId,
          countryId: detail.address.countryId,
          corporateName: detail.corporateName,
          corporateRegistrationDate: detail.corporateRegistrationDate,
          corporateEmail: detail.corporateEmail,
          corporateMobileNumber: detail.corporateMobileNumber,
          corporateLogo: detail.corporateLogo,
          licenses: detail.licenses,
          adminEmail: detail.adminEmail,
          adminFirstName: detail.adminFirstName,
          adminLastName: detail.adminLastName,
          adminMobileNumber: detail.adminMobileNumber,
        });
        this.registerForm.get('adminEmail').disable();
        this.registerForm.get('adminFirstName').disable();
        this.registerForm.get('adminLastName').disable();
        this.registerForm.get('adminMobileNumber').disable();

        this.getStates(detail.address.countryId);
        this.getCities(detail.address.stateId);
        this.corporateimageUrl = detail.corporateLogo;
        this.uploadedLicense = detail.licenses;
        // this.adminProfileUrl = detail.adminProfilePicture;


      }
    });
  }
  UpdateCorporateProfile() {
    console.log("Update Button Call");
    this.validateForm = true;
    console.log(this.registerForm.valid);
    if (this.registerForm.valid) {
      this.registerForm.value.corporateId = this.corporateId;
      this.registerForm.value.adminLoginId = this.adminLoginId;
      this._service.updateCorporateAdminProfile(this.registerForm.value).subscribe(response => {
        console.log(response);
        if (response.isSuccess) {

          if (this.corprateLogofile != null && this.corprateLogofile != undefined) {
            // upload image
            var obj = { CorporateId: this.corporateId, file: this.corprateLogofile };
            this._service.addCorporateLogo(obj).subscribe(response => {
              if (response.isSuccess) {
                this.corporateimageUrl = response.data.logo;
              }
            });
          }
          if (this.corprateLicensefile != null && this.corprateLicensefile != undefined) {
            // upload image
            var obj = { CorporateId: this.corporateId, file: this.corprateLicensefile };
            this._service.addCorporateLicense(obj).subscribe(response => {
              if (response.isSuccess) {
                this.corporateLicenseUrl = response.data.logo;
              }
            });
          }
          this.getProfile()
          this.router.navigate(['/sa/Companies']);

        }
      });
    }
  }
  getCities(stateId: number) {
    var obj = { PageNumber: 1, PageSize: 500, stateId: stateId };
    this.masterService.getCities(obj).subscribe(response => {
      this.cities = response.data;
    });
  }

  getStates(countryId: number) {
    var obj = { pageNumber: 1, PageSize: 500, CountryId: countryId };
    this.masterService.getStates(obj).subscribe(response => {
      this.states = response.data;
    });
  }

  selectImage(event) {
    var files = event.target.files;
    this.corprateLogofile = files[0];
    var reader = new FileReader();
    reader.onload = (e: any) => {
      this.corporateimageUrl = e.target.result;
    }
    reader.readAsDataURL(files[0]);

  }
  selectCorporateLicense(event) {
       var files = event.target.files;
    if(this.corprateLicensefile == null){
      this.corprateLicensefile = [];
    }
    if(this.uploadedLicense == null){
      this.uploadedLicense = [];
    }
    for(var i = 0; i < files.length; i++){
      this.corprateLicensefile.push(files[i]);
      let file = {fileName: files[i].name};
      this.uploadedLicense.push(file);
    }
    
    
    
    var reader = new FileReader();
    reader.onload = (e: any) => {
      this.corporateLicenseUrl = e.target.result;
    }
    reader.readAsDataURL(files[0]);
    // var files = event.target.files;
    // this.corprateLicensefile = files[0];
    // var reader = new FileReader();
    // reader.onload = (e: any) => {
    //   this.corporateLicenseUrl = e.target.result;
    // }
    // reader.readAsDataURL(files[0]);

  }

  deleteLicense(corporateLicenseId: number) {
    var obj = { CorporateLicenseId: corporateLicenseId };
    console.log(obj);
    this._service.deletecompanyLicense(obj).subscribe(response => {
      this.getProfile();
    });
  }



  ngOnInit() {
    this.createForm();
    var obj = { pageNumber: 1, PageSize: 500 };
    this.masterService.getCountries(obj).subscribe(response => {
      this.countries = response.data;
      if (this.corporateId > 0) {
        this.getProfile();
      }
    });
  }

}
