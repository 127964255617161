import { AfterViewInit, Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { AdminCampaignService } from '../../shared/services/campaigns.service';
import { AuthenticationService } from '../../shared/services/authentication.service';
import { Router, ActivatedRoute } from '@angular/router';


@Component({
  selector: 'campaign-list',
  templateUrl: './campaign-list.component.html',
})

export class CampaignListComponent implements OnInit{

  adminLoginId: number;
  campaigns: any[];
  pageNumber: number = 1;
  pageSize: number = 10;
  count: number;
  startFrom: number = 1;
  endOn: number = this.pageSize;
  numberOfPages: number;
  lastPageClass: string;
  firstPageClass: string = "disabled";
  previousPageClass: string = "disabled";
  nextPageClass: string;
  searchTerm: string;
  campaignId:number; 
  titleIcon: string = "sorting";
  startDateIcon: string = "sorting";
  endDateIcon: string = "sorting";
  countryIcon: string = "sorting";
  volunteersIcon: string = "sorting";
  groupsIcon: string = "sorting";
  isAscending: boolean = false;
  sortColumn: string;
 



  constructor(private _service: AdminCampaignService, private authentication: AuthenticationService,private route: ActivatedRoute,private router: Router) {
    var details = this.authentication.getToken();
    this.adminLoginId = details.access_token.adminLoginId;
   
  }

  getCampaigns() {
    var obj = { AdminLoginId: this.adminLoginId, IsPublic: true, PageNumber: this.pageNumber, PageSize: this.pageSize, SearchTerm: this.searchTerm, SortBy: this.sortColumn, IsAscending: this.isAscending};
    this._service.getSuperAdminCampaigns(obj).subscribe(response => {
      if (response.isSuccess) {
        this.campaigns = response.list;
        console.log(this.campaigns);
        this.count = response.count;
        if (this.count == 0) {
          this.startFrom = 0;
        }
        else {
          this.startFrom = ((this.pageNumber - 1) * this.pageSize) + 1;
        }
        if (this.count <= this.pageSize) {
          this.endOn = this.count;
          this.nextPageClass = "disabled";
          this.lastPageClass = "disabled";
        }
        else {
          this.endOn = this.pageNumber * this.pageSize;
        }
        var pages = this.count / this.pageSize;
        this.numberOfPages = 0;
        for (var i = 0; i < pages; i++) {
          this.numberOfPages = i + 1;
        }
        if (this.endOn > this.count) {
          this.endOn = this.count;
      }
      }
    });
  }

  firstPage() {
    if (this.pageNumber > 1) {
      this.firstPageClass = "disabled";
      this.previousPageClass = "disabled";
      this.nextPageClass = "";
      this.lastPageClass = "";
      this.pageNumber = 1;
      this.getCampaigns();
    }
  }

  lastPage() {
    if (this.pageNumber < this.numberOfPages) {
      this.firstPageClass = "";
      this.previousPageClass = "";
      this.nextPageClass = "disabled";
      this.lastPageClass = "disabled";
      this.pageNumber = this.numberOfPages;
      this.getCampaigns();
    }
  }

  previousPage() {
    if (this.pageNumber > 1) {
      this.pageNumber = this.pageNumber - 1;
      if (this.pageNumber == 1) {
        this.firstPageClass = "disabled";
        this.previousPageClass = "disabled";
        this.nextPageClass = "";
        this.lastPageClass = "";
      }
      this.getCampaigns();
    }
  }

  nextPage() {
    if (this.pageNumber < this.numberOfPages) {
      this.pageNumber = this.pageNumber + 1;
      this.firstPageClass = "";
      this.previousPageClass = "";
      if (this.pageNumber == this.numberOfPages) {
        this.nextPageClass = "disabled";
        this.lastPageClass = "disabled";
      }
      this.getCampaigns();
    }
  }

  search() {
    this.pageNumber = 1;
    this.firstPageClass = "disabled";
    this.previousPageClass = "disabled";
    this.nextPageClass = "";
    this.lastPageClass = "";
    this.getCampaigns();
  }

  changePageSize(pageSize: number) {
    this.pageSize = pageSize;
    this.pageNumber = 1;
    this.firstPageClass = "disabled";
    this.previousPageClass = "disabled";
    this.nextPageClass = "";
    this.lastPageClass = "";
    this.getCampaigns();
  }

  checkIfBoxEmpty() {
    if (this.searchTerm == '' || this.searchTerm == null) {
      this.pageNumber = 1;
      this.firstPageClass = "disabled";
      this.previousPageClass = "disabled";
      this.nextPageClass = "";
      this.lastPageClass = "";
      this.getCampaigns();
    }
  }

  deleteCampaign(campaignId: number,) {
    console.log(campaignId);
    var obj = { campaignId:campaignId };
    this._service.deleteCampaign(obj).subscribe(response => {
      if (response.isSuccess) {
        this.firstPageClass = "disabled";
        this.previousPageClass = "disabled";
        this.nextPageClass = "";
        this.lastPageClass = "";
      this.pageNumber = 1;
      this.getCampaigns();
      }
    });
  }

  sortColumns(columnName: string) {
    switch (columnName) {
      case "Title":
      if (this.titleIcon == "sorting") {
        this.titleIcon = "sorting_asc";
        this.isAscending = true;
      }
      else if (this.titleIcon == "sorting_asc") {
        this.titleIcon = "sorting_desc";
        this.isAscending = false;
      }
      else if (this.titleIcon == "sorting_desc") {
        this.titleIcon = "sorting_asc";
        this.isAscending = true;
      }
      this.startDateIcon = "sorting";
      this.endDateIcon = "sorting";
      this.volunteersIcon = "sorting";
      this.countryIcon = "sorting";
      this.groupsIcon = "sorting";
      break;
    case "StartDate":
      if (this.startDateIcon == "sorting") {
        this.startDateIcon = "sorting_asc";
        this.isAscending = true;
      }
      else if (this.startDateIcon == "sorting_asc") {
        this.startDateIcon = "sorting_desc";
        this.isAscending = false;
      }
      else if (this.startDateIcon == "sorting_desc") {
        this.startDateIcon = "sorting_asc";
        this.isAscending = true;
      }
      this.titleIcon = "sorting";
      this.endDateIcon = "sorting";
      this.countryIcon = "sorting";
      this.volunteersIcon = "sorting";
      this.groupsIcon = "sorting";
      break;

      case "EndDate":
      if (this.endDateIcon == "sorting") {
        this.endDateIcon = "sorting_asc";
        this.isAscending = true;
      }
      else if (this.endDateIcon == "sorting_asc") {
        this.endDateIcon = "sorting_desc";
        this.isAscending = false;
      }
      else if (this.endDateIcon == "sorting_desc") {
        this.endDateIcon = "sorting_asc";
        this.isAscending = true;
      }
      this.titleIcon = "sorting";
      this.startDateIcon = "sorting";
      this.countryIcon = "sorting";
      this.volunteersIcon = "sorting";
      this.groupsIcon = "sorting";
      break;

      case "Country":
      if (this.countryIcon == "sorting") {
        this.countryIcon = "sorting_asc";
        this.isAscending = true;
      }
      else if (this.countryIcon == "sorting_asc") {
        this.countryIcon = "sorting_desc";
        this.isAscending = false;
      }
      else if (this.countryIcon == "sorting_desc") {
        this.countryIcon = "sorting_asc";
        this.isAscending = true;
      }
      this.titleIcon = "sorting";
      this.startDateIcon = "sorting";
      this.endDateIcon = "sorting";
      this.volunteersIcon = "sorting";
      this.groupsIcon = "sorting";
      break;


      case "Groups":
      if (this.groupsIcon == "sorting") {
        this.groupsIcon = "sorting_asc";
        this.isAscending = true;
      }
      else if (this.groupsIcon == "sorting_asc") {
        this.groupsIcon = "sorting_desc";
        this.isAscending = false;
      }
      else if (this.groupsIcon == "sorting_desc") {
        this.groupsIcon = "sorting_asc";
        this.isAscending = true;
      }
      this.titleIcon = "sorting";
      this.startDateIcon = "sorting";
      this.endDateIcon = "sorting";
      this.volunteersIcon = "sorting";
      break;


      case "Volunteers":
      if (this.volunteersIcon == "sorting") {
        this.volunteersIcon = "sorting_asc";
        this.isAscending = true;
      }
      else if (this.volunteersIcon == "sorting_asc") {
        this.volunteersIcon = "sorting_desc";
        this.isAscending = false;
      }
      else if (this.volunteersIcon == "sorting_desc") {
        this.volunteersIcon = "sorting_asc";
        this.isAscending = true;
      }
      this.titleIcon = "sorting";
      this.startDateIcon = "sorting";
      this.endDateIcon = "sorting";
      this.groupsIcon = "sorting";
      this.countryIcon = "sorting";
      break;
    }
    
    this.sortColumn = columnName;
    this.pageNumber = 1;
    this.getCampaigns();
  }

    downloadCsvFile() {
        var list = this.campaigns;
        if (list != null && list.length > 0) {
            var obj = { AdminLoginId: this.adminLoginId, IsPublic: true, SearchTerm: this.searchTerm, SortBy: this.sortColumn, IsAscending: this.isAscending };
            this._service.getSuperAdminCampaignCsv(obj).subscribe(response => {
                console.log(response);
                if (response.isSuccess) {
                    let elem = document.createElement("a");
                    elem.target = "_self"; elem.href = response.csvFilePath; elem.setAttribute("download", response.csvFilePath);
                    elem.click();
                }
            });
        }
        else {
            alert("Campaign list is empty");
        }
    }

  performAction(campaignId: number, statusId: number) {
    var obj = { campaignId: campaignId, StatusId: statusId };
    console.log(obj);
    this._service.performAction(obj).subscribe(response => {
      if (response.isSuccess) {
        this.getCampaigns();
      }
    });
  }


  ngOnInit() {
    this.getCampaigns();
  }

}
