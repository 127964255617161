
import {throwError as observableThrowError,  Observable } from 'rxjs';

import {catchError, tap} from 'rxjs/operators';
import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { Broadcast } from './broadcast.service';

import { environment } from './../../../environments/environment';
//import { IAdminLogin } from './auth.interface';

@Injectable()
export class AuthenticationService {
  token: any = {
    auth_token: '',
    access_token: {
      cityId: null,
      corporateId: null,
      countryId: '',
      corporateLogo:'',
      email: '',
      mobileNumber: '',
      profilePicUrl:'',
      panicNumber: '',
      stateId: '',
      token: '',
      userId: null,
      userName:'',
      userProfileId: null,
      userRegistrationStatusId: null,
    }
  };

  tokenKey: string = "a5smm_utoken"
  details: any;

  constructor(private router: Router, private http: HttpClient, private broadcast: Broadcast) {

    var details = this.getToken();
   
    if(details==null)
    {      
      var loc = document.location.toString();   
      if(loc.length > 40){
        localStorage.setItem("navigationUrl",loc);
      } 

      var url = loc.split('/');
      if(url.length == 4){
        this.router.navigate(["/"+url[3]]);
      }else{
        this.router.navigate(['']);
      }  
    }else{
      var saved = localStorage.getItem("User");
      if(saved != null){
        if (details.access_token.userRegistrationStatusId == 4) {
          this.router.navigate(["/volunteer/beneficiary-curriculum"]);
      }
      else {                       
         this.router.navigate(['/volunteer/dashboard']);
      }
      }
    }
   }

  // login(obj): any {
  //   return this.http.post<any>(environment.apiBasePath + '/AdminLogin/Login', obj, { observe: 'response' }).subscribe(response => {
  //     this.details = response.body;
  //     this.authorizeToken(obj.Email);
  //     this.navigateBasedOnRole(this.details.data.roleId);
  //     return true;
  //   },
  //     (err) => {
  //       if (err.error instanceof Error) {
  //         console.log('An error occurred on the client side', err.error.message);
  //       } else {
  //         console.log(`Backend returned code ${err.status}, body was: ${err.error} (server error)`)
  //       }
  //       return false;
  //     });
  // }

  authorizeToken(username) {
    //return this.http.post<any>(environment.apiBasePath + '/Token', { "Email": username }, { observe: 'response' }).subscribe(response => {
    //  if (response.body) {
    this.token.auth_token = ''; // response.body.token;
    this.token.access_token = {
      cityId: this.details.cityId,
      corporateId: this.details.corporateId,
      corporateLogo:this.details.corporateLogo,
      countryId: this.details.countryId,
      email: this.details.email,
      mobileNumber: this.details.mobileNumber,
      profilePicture:this.details.profilePicture,
      panicNumber: this.details.panicNumber,
      stateId: this.details.stateId,
      token: this.details.token,
      userId: this.details.userId,
      userName:this.details.userName,
      userProfileId: this.details.userProfileId,
      userRegistrationStatusId: this.details.userRegistrationStatusId,
      unsubscribeType:this.details.unsubscribeType,
      accountType:this.details.accountType
    }
    this.setToken(this.token);
    // }
    //},
    //  (err) => {
    //    if (err.error instanceof Error) {
    //      console.log('An error occurred on the client side', err.error.message);
    //    } else {
    //      console.log(`Backend returned code ${err.status}, body was: ${err.error} (server error)`)
    //    }
    //  });
  }


  //login(obj, rememberMe): Observable<any> {
  //  return this.http.post<any>(environment.apiBasePath + '/AdminLogin/Login', obj)
  //    .do(res => {
  //      if (res.isSuccess) {
  //        this.details = res;
  //        this.authorizeToken(obj.Email);
  //       // this.navigateBasedOnRole(this.details.data.roleId);
  //        if (rememberMe) {
  //          document.cookie = this.tokenKey + "=" + JSON.stringify(this.token);
  //        }
  //      }
  //    })
  //    .catch(this.handleError);
  //}

  authoriseUser(obj):Observable<any> {
    return this.http.post<any>(environment.apiBasePath + '/User/AuthenticateWebUser',obj).pipe(
    tap(res=> res),
    catchError(this.handleError),);   
  }

  login(obj,rememberMe): Observable<any> {
      return this.http.post<any>(environment.apiBasePath + '/User/UserLogin',obj).pipe(
      tap(res=> res),
      catchError(this.handleError),);   
  }
  verifyOtp(obj):Observable<any> {
      return this.http.post<any>(environment.apiBasePath + '/User/VerifyUserOtp',obj).pipe(
    tap(res=> res),
    catchError(this.handleError),);   
  }

  sendConfirmation(obj):Observable<any> {
    return this.http.post<any>(environment.apiBasePath + '/User/SendConfirmationMail',obj).pipe(
  tap(res=> res),
  catchError(this.handleError),);   
}

  resendOtp(obj):Observable<any> {
    return this.http.post<any>(environment.apiBasePath + '/User/RequestNewOTP',obj).pipe(
    tap(res => res),
    catchError(this.handleError),);
  }

  setAccountType(obj):Observable<any> {
    return this.http.post<any>(environment.apiBasePath + '/User/SetUserAccountType',obj).pipe(
    tap(res => res),
    catchError(this.handleError),);
  }

  getAccountType(obj):Observable<any> {
    return this.http.post<any>(environment.apiBasePath + '/User/GetUserAccountType',obj).pipe(
    tap(res => res),
    catchError(this.handleError),);
  }

  register(obj): Observable<any> {
      return this.http.post<any>(environment.apiBasePath + '/User/UserRegister', obj).pipe(
      tap(res => res),
      catchError(this.handleError),);
  }
  forgotPassword(obj): Observable<any> {
    return this.http.post<any>(environment.apiBasePath + '/AdminLogin/ForgetPassword', obj).pipe(
      tap(res => res),
      catchError(this.handleError),);
  }

  resetPassword(obj): Observable<any> {
    return this.http.post<any>(environment.apiBasePath + '/AdminLogin/ResetPassword', obj).pipe(
      tap(res => res),
      catchError(this.handleError),);
  }

  checkIfLinkActive(obj): Observable<any> {
    return this.http.post<any>(environment.apiBasePath + '/AdminLogin/ResetLinkActive', obj).pipe(
      tap(res => res),
      catchError(this.handleError),);
  }

  getAdminProfile(obj): Observable<any> {
    return this.http.post<any>(environment.apiBasePath + '/adminLogin/GetProfile', obj).pipe(
      tap(res => res),
      catchError(this.handleError),);
  }

  getSuperAdminProfile(obj): Observable<any> {
    return this.http.post<any>(environment.apiBasePath + '/adminLogin/GetSuperAdminProfile', obj).pipe(
      tap(res => res),
      catchError(this.handleError),);
  }

  getUserDetails(obj):Observable<any> {
    return this.http.post<any>(environment.apiBasePath + '/User/GetUserDetails', obj).pipe(
    tap(res => res),
    catchError(this.handleError),);
  }
  

updateSuperAdminProfile(obj): Observable<any> {
    return this.http.post<any>(environment.apiBasePath + '/adminLogin/UpdateSuperAdminProfile', obj).pipe(
      tap(res => res),
      catchError(this.handleError),);
  }

  updateAdminProfile(obj): Observable<any> {
    return this.http.post<any>(environment.apiBasePath + '/adminLogin/UpdateProfile', obj).pipe(
      tap(res => res),
      catchError(this.handleError),);
  }
  updateCorporateAdminProfile(obj): Observable<any> {
    return this.http.post<any>(environment.apiBasePath + '/adminLogin/UpdateCorporate', obj).pipe(
      tap(res => res),
      catchError(this.handleError),);
  }

  createUsers(obj): Observable<any> {
    return this.http.post<any>(environment.apiBasePath + '/AdminLogin/CreateUser', obj).pipe(
      tap(res => res),
      catchError(this.handleError),);
  }

  getUsers(obj): Observable<any> {
    return this.http.post<any>(environment.apiBasePath + '/AdminLogin/GetUsers', obj).pipe(
      tap(res => res),
      catchError(this.handleError),);
  }
  deleteUser(obj): Observable<any> {
    return this.http.post<any>(environment.apiBasePath + '/AdminLogin/deleteUser', obj).pipe(
      tap(res => res),
      catchError(this.handleError),);
  }


  getAllCompanies(obj): Observable<any> {
    return this.http.post<any>(environment.apiBasePath + '/Corporate/GetAll', obj).pipe(
      tap(res => res),
      catchError(this.handleError),);
  }
  deletecompany(obj): Observable<any> {
    return this.http.post<any>(environment.apiBasePath + '/Corporate/Delete', obj).pipe(
      tap(res => res),
      catchError(this.handleError),);
  }

  addCorporateLogo(obj): Observable<any> {

    const formData: FormData = new FormData();
    formData.append('CorporateId', obj.CorporateId);
    formData.append('UploadFile', obj.file, obj.file.name);

    return this.http.post<any>(environment.apiBasePath + '/corporate/UploadLogo', formData).pipe(
      tap(res => res),
      catchError(this.handleError),);
  }

  addCorporateLicense(obj): Observable<any> {

    const formData: FormData = new FormData();
    formData.append('CorporateId', obj.CorporateId);
    if (obj.file) {
      for (var i = 0; i < obj.file.length; i++) {
        formData.append('UploadFiles', obj.file[i]);
      }
    }
    return this.http.post<any>(environment.apiBasePath + '/corporate/UploadLicenses', formData).pipe(
      tap(res => res),
      catchError(this.handleError),);
  }

  uploadProfile(obj): Observable<any> {

    const formData: FormData = new FormData();
    formData.append('AdminLoginId', obj.adminLoginId);
    formData.append('UploadFile', obj.file, obj.file.name);

    return this.http.post<any>(environment.apiBasePath + '/AdminLogin/UploadImage', formData).pipe(
      tap(res => res),
      catchError(this.handleError),);
  }
  deletecompanyLicense(obj): Observable<any> {
    return this.http.post<any>(environment.apiBasePath + '/Corporate/DeleteLicense', obj).pipe(
      tap(res => res),
      catchError(this.handleError),);
  }


   actionforStatus(obj): Observable<any> {
    return this.http.post<any>(environment.apiBasePath + '/Corporate/PerformAction', obj).pipe(
      tap(res => res),
      catchError(this.handleError),);
  }

  setCorporate() {
    // if (this.details.data.associations.length > 0) {
    this.token.access_token.corporateId = this.details.data.corporateId;
    this.token.access_token.corporateName = this.details.data.corporateName;

    // }
  }

  navigateBasedOnRole(roleId) {
   
     
      this.router.navigate(['volunteer/event/public']);
    
  }


  logout() {
  
    this.removeToken();
    document.cookie = this.tokenKey + "=" + "";
    this.router.navigate(['']);
    this.broadcast.broadcast('is-authenticated', false);
  }

  getToken() {
    return JSON.parse(localStorage.getItem(this.tokenKey));
  }

  getAuthToken() {
    return JSON.parse(localStorage.getItem(this.tokenKey))['auth_token'];
  }

  setToken(token) {
    localStorage.setItem(this.tokenKey, JSON.stringify(token));
    this.broadcast.broadcast(this.tokenKey, JSON.stringify(token));
  }

  setAdminDetails() {
    localStorage.setItem("adLogDeta", JSON.stringify(this.details));
  }

  getAdminDetails() {
    return JSON.parse(localStorage.getItem("adLogDeta"));
  }

  getAccessToken() {
    return JSON.parse(localStorage.getItem(this.tokenKey))['access_token'];
  }

  isAuthenticated() {
    let token = localStorage.getItem(this.tokenKey);

    if (token) {
      return true;
    }
    else {
      return false;
    }
  }

    getChapterCsv(obj): Observable<any> {
        return this.http.post<any>(environment.apiBasePath + '/Corporate/GetCorporateListCsv', obj).pipe(
            tap(res => res),
            catchError(this.handleError),);
    }


  removeToken() {
    var saved = localStorage.getItem("User");
    if(saved==null){
      localStorage.removeItem(this.tokenKey);
    }
  }

  private handleError(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof Error) {
      // A client-side or network error occurred. Handle it accordingly.
      errorMessage = `An error occurred: ${error.error.message}`;
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      errorMessage = `Server returned code: ${error.status}, error message is: ${error.message}`;
    }
    //console.error(errorMessage);
    return observableThrowError(error.error);
  }
}


