import { AfterViewInit, Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { AdminCampaignService } from '../../../shared/services/campaigns.service';
import { AuthenticationService } from '../../../shared/services/authentication.service';
import { AdminVolunteerService } from '../../../shared/services/volunteer.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';



@Component({
  selector: 'campaign-taskdetail',
  templateUrl: './campaign-taskdetail.component.html',
})

export class CampaignTaskDetailComponent implements OnInit {


  adminLoginId: number;
  campaignId: number;
  taskId: number;
  details: any;
  isAddNewVolunteer: boolean = false;
  searchedVolunteers: any[] = [];
  volunteerSearchTerm: string;
  isAddNewGroup: boolean = false;
  pushmessage: string;
  pushmsgValidation: boolean = false;
  pushmsgSendSuccess: boolean = false;


  constructor(private _service: AdminCampaignService, private volunteerService: AdminVolunteerService, private route: ActivatedRoute, private authentication: AuthenticationService, private router: Router) {
    var details = this.authentication.getToken();
    this.adminLoginId = details.access_token.adminLoginId;
    router.events.subscribe((response) => {
      this.campaignId = route.snapshot.params['campaignid'];
      this.taskId = route.snapshot.params['taskid'];

    });

  }

  getTaskDetails() {
    var obj = { taskId: this.taskId, campaignId: this.campaignId };
    this._service.getGetTaskDetail(obj).subscribe(response => {
      if (response.isSuccess) {
        console.log(response);
        this.details = response.data;
        console.log(this.details);
      }
    });
  }
  addVolunteer() {
    this.isAddNewVolunteer = true;
  }

  addGroup() {
    this.isAddNewGroup = true;
  }
  searchVolunteer() {
    this.searchedVolunteers = [];
    if (this.volunteerSearchTerm != '' && this.volunteerSearchTerm != null) {
      var obj = { SearchTerm: this.volunteerSearchTerm };
      console.log(obj);
      this.volunteerService.searchVolunteer(obj).subscribe(response => {
        console.log(response);
        if (response.isSuccess) {
          this.searchedVolunteers = response.list;
        }
      });
    }
    else {
      this.searchedVolunteers = [];
    }
  }
  selectVolunteer(volunteer: any, i: number): boolean {
    if (this.details.taskUsers == null) {
      this.details.taskUsers = [];
    }
    volunteer.statusId = 2;
    volunteer.status = "Approved";
    if (this.details.taskUsers.length > 0) {
      var exist = this.details.taskUsers.filter(a => a.userLoginId == volunteer.userLoginId);
      if (exist.length > 0) {
        this.searchedVolunteers = [];
        this.volunteerSearchTerm = '';
        this.isAddNewVolunteer = false;
        return this.isAddNewVolunteer;
      }
    }
    var obj = { taskId: this.taskId, taskUsers: volunteer };
    this._service.AddTaskuser(obj).subscribe(response => {
      if (response.isSuccess) {
        this.details.taskUsers.push(volunteer);
      }
    });
    this.searchedVolunteers = [];
    this.volunteerSearchTerm = '';
    this.isAddNewVolunteer = false;
  }

  searchGroup() {
    this.searchedVolunteers = [];
    if (this.volunteerSearchTerm != '' && this.volunteerSearchTerm != null) {
      var obj = { SearchTerm: this.volunteerSearchTerm };
      this.volunteerService.searchGroup(obj).subscribe(response => {
        if (response.isSuccess) {
          this.searchedVolunteers = response.list;
        }
      });
    }
    else {
      this.searchedVolunteers = [];
    }
  }


  selectGroup(group: any, i: number): boolean {
    if (this.details.taskUsers == null) {
      this.details.taskUsers = [];
    }
    group.statusId = 2;
    group.status = "Approved";
    if (this.details.taskUsers.length > 0) {
      var exist = this.details.taskUsers.filter(a => a.groupId == group.groupId);
      if (exist.length > 0) {
        this.searchedVolunteers = [];
        this.volunteerSearchTerm = '';
        this.isAddNewGroup = false;
        return this.isAddNewGroup;
      }
    }
    var obj = { taskId: this.taskId, taskUsers: group };
    console.log(obj);
    this._service.AddTaskuser(obj).subscribe(response => {
      if (response.isSuccess) {
        console.log(response);
        this.details.taskGroups.push(group);
      }
    });
    this.searchedVolunteers = [];
    this.volunteerSearchTerm = '';
    this.isAddNewGroup = false;
  }

  performActionOnUser(statusId: number, status: string, volunteer: any) {
    var obj = { campaignId: this.campaignId, StatusId: statusId, taskId: this.taskId, taskUsers: volunteer };
    console.log(obj);
    this._service.performActionOnUser(obj).subscribe(response => {
      if (response.isSuccess) {
        volunteer.statusId = statusId;
        volunteer.status = status;
      }
    });
  }

  maganeTapped() {
    this.router.navigate(['/sa/campaigns/taskList/' + this.campaignId]);

  }

  sendPushNotification() {
    console.log("Abhishek");
    if (this.pushmessage == undefined || this.pushmessage == "" || this.pushmessage == null) {
      this.pushmsgValidation = true;
    }
    else {
      this.pushmsgValidation = false;
      var obj = { taskId: this.taskId, Message: this.pushmessage };
      console.log(obj);
      this._service.pushNotificationforCampaignsTaskUser(obj).subscribe(response => {
        if (response.isSuccess) {
          this.pushmsgSendSuccess = true;
          this.pushmessage = '';
        }
      });
    }
  }

  setPushMessage() {
    this.pushmsgSendSuccess = false;
    this.pushmsgValidation = false;
  }

  ngOnInit() {
    this.getTaskDetails();
  }

}
