import { Injectable } from '@angular/core';
import { HttpClient, HttpHandler, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { environment as ENV } from './../../../environments/environment';

@Injectable()
export class TestimonialService {

  constructor(private http: HttpClient) { }


  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    // return throwError('Something bad happened; please try again later.');
  };

  private extractData(res) {
    return res || {};
  }

  createTestimonial(formData):Observable<any>{
      return this.http.post<any>(`${ENV.apiBasePath}/Testimonial/Create`,formData).pipe(tap(res=>{
        return res;
      }));
  }
  getTestimonialDetail(obj):Observable<any>{
    return this.http.post<any>(`${ENV.apiBasePath}/Testimonial/Detail`,obj).pipe(tap(res=>{
      return res;
    }));
  }
  getTestimonial(obj):Observable<any> {
    return this.http.post<any>(`${ENV.apiBasePath}/Testimonial/Get`,obj).pipe(tap(res=>{
      return res;
    }))
  }
  deleteTestimonialImage(obj):Observable<any> {
    return this.http.post<any>(`${ENV.apiBasePath}/Testimonial/DeleteTestimonialImage`,obj).pipe(tap(res=>{
      return res;
    }))
  }

  performAction(obj):Observable<any> {
    return this.http.post<any>(`${ENV.apiBasePath}/Testimonial/PerformAction`,obj).pipe(tap(res=>{
      return res;
    }))
  }



}
