import { AfterViewInit, Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AdminEventsService } from '../../../shared/services/events.services';
import { AuthenticationService } from '../../../shared/services/authentication.service';
import { AdminVolunteerService } from '../../../shared/services/volunteer.service';
import { AdminMasterService } from '../../../shared/services/master.services';
import { SurveyService } from '../../../shared/services/survey.service';



@Component({
  selector: 'event-detail',
  templateUrl: './event-detail.component.html',
})

export class EventDetailComponent implements OnInit {

  adminLoginId: number;
  eventId: any;
  details: any;
  volunteerSearchTerm: string;
  isAddNewVolunteer: boolean = false;
  isAddNewGroup: boolean = false;
  searchedVolunteers: any[] = [];
  surveys: any[] = [];
  pushmessage: string;
  pushmsgValidation: boolean = false;
  pushmsgSendSuccess: boolean = false;
  selectedVolunteer: any;
  comment: string;

  constructor(private _service: AdminEventsService, private router: Router, private route: ActivatedRoute, private volunteerService: AdminVolunteerService, private authentication: AuthenticationService, private surveyService: SurveyService) {
    var details = this.authentication.getToken();
    this.adminLoginId = details.access_token.adminLoginId;
    router.events.subscribe((response) => {
      this.eventId = route.snapshot.params['id'];
    });
  }

  getDetails() {
    var obj = { EventId: this.eventId };
    this._service.getSuperAdminEventDetails(obj).subscribe(response => {
      if (response.isSuccess) {
        this.details = response.data;
        console.log(this.details);
      }
    });
  }

  addVolunteer() {
    this.isAddNewVolunteer = true;
  }

  selectVolunteerForAction(volunteer: any) {
    this.selectedVolunteer = volunteer;
    this.comment = '';
  }

  searchVolunteer() {
    this.searchedVolunteers = [];
    if (this.volunteerSearchTerm != '' && this.volunteerSearchTerm != null) {
      var obj = { SearchTerm: this.volunteerSearchTerm };
      this.volunteerService.searchVolunteer(obj).subscribe(response => {
        if (response.isSuccess) {
          this.searchedVolunteers = response.list;
        }
      });
    }
    else {
      this.searchedVolunteers = [];
    }
  }

  selectVolunteer(volunteer: any, i: number): boolean {
    var isAllowed = true;
    if (this.details.domains) {
      var fullDomain = volunteer.email.split('@')[1];
      var domain = fullDomain.split('.')[0];
      isAllowed = this.details.domains.includes(domain);
    }
    if (isAllowed) {
      if (this.details.volunteers == null) {
        this.details.volunteers = [];
      }
      volunteer.statusId = 2;
      volunteer.status = "Approved";
      if (this.details.volunteers.length > 0) {
        var exist = this.details.volunteers.filter(a => a.userLoginId == volunteer.userLoginId);
        if (exist.length > 0) {
          this.searchedVolunteers = [];
          this.volunteerSearchTerm = '';
          this.isAddNewVolunteer = false;
          return this.isAddNewVolunteer;
        }
      }
      var obj = { EventId: this.eventId, Volunteer: volunteer };
      this._service.addEventUsers(obj).subscribe(response => {
        if (response.isSuccess) {
          this.details.volunteers.push(volunteer);
        }
      });
    }
    else {
      alert("Volunteer's domain does not match.");
    }
    this.searchedVolunteers = [];
    this.volunteerSearchTerm = '';
    this.isAddNewVolunteer = false;
  }

  addGroup() {
    this.isAddNewGroup = true;
  }

  searchGroup() {
    this.searchedVolunteers = [];
    if (this.volunteerSearchTerm != '' && this.volunteerSearchTerm != null) {
      var obj = { SearchTerm: this.volunteerSearchTerm };
      this.volunteerService.searchGroup(obj).subscribe(response => {
        if (response.isSuccess) {
          this.searchedVolunteers = response.list;
        }
      });
    }
    else {
      this.searchedVolunteers = [];
    }
  }

  selectGroup(group: any, i: number): boolean {
    var isAllowed = true;
    if (this.details.domains) {
      for (var i = 0; i < group.volunteers.length; i++) {
        var fullDomain = group.volunteers[i].email.split('@')[1];
        var domain = fullDomain.split('.')[0];
        isAllowed = this.details.domains.includes(domain);
        if (!isAllowed)
          break;
      }
    }
    if (isAllowed) {
      if (this.details.volunteers == null) {
        this.details.volunteers = [];
      }
      group.statusId = 2;
      group.status = "Approved";
      if (this.details.volunteers.length > 0) {
        var exist = this.details.volunteers.filter(a => a.groupId == group.groupId);
        if (exist.length > 0) {
          this.searchedVolunteers = [];
          this.volunteerSearchTerm = '';
          this.isAddNewGroup = false;
          return this.isAddNewGroup;
        }
      }
      var obj = { EventId: this.eventId, Volunteer: group };
      this._service.addEventUsers(obj).subscribe(response => {
        if (response.isSuccess) {
          this.details.volunteers.push(group);
        }
      });
    }
    else {
      alert("Group contains other domain volunteers.");
    }
    this.searchedVolunteers = [];
    this.volunteerSearchTerm = '';
    this.isAddNewGroup = false;
  }
  deleteSurvey(survey: any, index: number) {
    var obj = { SurveyId: survey.surveyId };
    this.surveyService.deleteSurvey(obj).subscribe(response => {
      this.getSurveys();
    });
  }

  performActionOnUser(statusId: number, status: string, volunteer: any) {
    var obj = { EventId: this.eventId, UserLoginId: volunteer.userLoginId, GroupId: volunteer.groupId, StatusId: statusId, Comment: this.comment };
    this._service.performActionOnUser(obj).subscribe(response => {
      if (response.isSuccess) {
        this.getDetails()
      }
    });
  }

  getSurveys() {
    var obj = { AdminLoginId: this.adminLoginId, EventId: this.eventId, PageNumber: 1, PageSize: 100 };
    this.surveyService.getAllSurveys(obj).subscribe(response => {
      if (response.isSuccess) {
        this.surveys = response.list;
      }
    });
  }


  performActionOnSurvey(survey: any, statusId: number, status: string) {
    var obj = { SurveyId: survey.surveyId, StatusId: statusId };
    this.surveyService.performAction(obj).subscribe(response => {
      if (response.isSuccess) {
        survey.statusId = statusId;
        survey.status = status;
      }
    });
  }

  sendPushNotification() {
    if (this.pushmessage == undefined || this.pushmessage == "" || this.pushmessage == null) {
      this.pushmsgValidation = true;
    }
    else {
      this.pushmsgValidation = false;
      var obj = { EventId: this.eventId, Message: this.pushmessage };
      console.log(obj);
      this._service.pushNotificationforEventUser(obj).subscribe(response => {
        if (response.isSuccess) {
          this.pushmsgSendSuccess = true;
          this.pushmessage = '';
        }
      });
    }
  }

  setPushMessage() {
    this.pushmsgSendSuccess = false;
    this.pushmsgValidation = false;
  }



  ngOnInit() {
    this.getDetails();
  }

}
