import { AfterViewInit, Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { FormControl, FormGroup, Validators, FormArray } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { SurveyService } from '../../../shared/services/survey.service';
import { AuthenticationService } from '../../../shared/services/authentication.service';
import { AdminMasterService } from '../../../shared/services/master.services';
import { NgDatepickerModule, DatepickerOptions } from 'ng2-datepicker';
import * as frLocale from 'date-fns/locale/fr';

@Component({
  selector: 'survey-response',
  templateUrl: './survey-response.component.html',
})

export class SurveyUserDetailComponent implements OnInit {

  adminLoginId: number;
  userLoginId: number;
  details: any;
  surveyId: number;
  file: any;

  constructor(private _service: SurveyService, private authentication: AuthenticationService, private masterService: AdminMasterService, private router: Router, private route: ActivatedRoute) {
    var details = this.authentication.getToken();
    this.adminLoginId = details.access_token.adminLoginId;
    router.events.subscribe((response) => {
      this.surveyId = route.snapshot.params['surveyId'];
      this.userLoginId = route.snapshot.params['userLoginId'];
    });
  }


  getSurveyDetails() {
    var obj = { SurveyId: this.surveyId, UserLoginId: this.userLoginId };
    this._service.getSurveyUserDetails(obj).subscribe(response => {
      if (response.isSuccess) {
        this.details = response.data;
      }
    });
  }

  ngOnInit() {
    if (this.surveyId > 0 && this.userLoginId > 0) {
      this.getSurveyDetails();
    }
  }
}
