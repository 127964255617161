
import {throwError as observableThrowError,  Observable } from 'rxjs';

import {catchError, tap} from 'rxjs/operators';
import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';



import { Router } from '@angular/router';
import { environment } from './../../../environments/environment';
import { AuthenticationService } from './authentication.service';

@Injectable()
export class AdminVolunteerService {

  constructor(private router: Router, private http: HttpClient, private auth:AuthenticationService) { }

  getAllVolunteers(obj): Observable<any> {
    return this.http.post<any>(environment.apiBasePath + '/Volunteer/GetAll', obj).pipe(
      tap(res => res),
      catchError(this.handleError),);
  }

  getCurriculumProjects(obj):Observable<any> {
    return this.http.post<any>(environment.apiBasePath + '/Campaign/GetVolunteerCurriculumCampaign', obj).pipe(
      tap(res => res),
      catchError(this.handleError),);
  }

  getAllGroups(obj): Observable<any> {
    return this.http.post<any>(environment.apiBasePath + '/Volunteer/GetAllGroups', obj).pipe(
      tap(res => res),
      catchError(this.handleError),);
  }


  addGroup(obj): Observable<any> {
    return this.http.post<any>(environment.apiBasePath + '/Volunteer/AddGroup', obj).pipe(
      tap(res => res),
      catchError(this.handleError),);
  }

  searchVolunteer(obj): Observable<any> {
    return this.http.post<any>(environment.apiBasePath + '/Volunteer/SearchVolunteer', obj).pipe(
      tap(res => res),
      catchError(this.handleError),);
  }

  searchGroup(obj): Observable<any> {
    return this.http.post<any>(environment.apiBasePath + '/Volunteer/SearchGroup', obj).pipe(
      tap(res => res),
      catchError(this.handleError),);
  }

  addVolunteer(obj): Observable<any> {
    return this.http.post<any>(environment.apiBasePath + '/Volunteer/Add', obj).pipe(
      tap(res => res),
      catchError(this.handleError),);
  }

  performAction(obj): Observable<any> {
    return this.http.post<any>(environment.apiBasePath + '/Volunteer/PerformAction', obj).pipe(
      tap(res => res),
      catchError(this.handleError),);
  }

  applyForTask(obj):Observable<any> {
    return this.http.post<any>(environment.apiBasePath + '/User/ApplyTask', obj).pipe(
      tap(res => res),
      catchError(this.handleError),);
    }

    cancelTask(obj): Observable<any> {
        return this.http.post<any>(environment.apiBasePath + '/User/CancelTaskApplication', obj).pipe(
            tap(res => res),
            catchError(this.handleError),);
    }

    startTask(obj):Observable<any> {
      return this.http.post<any>(environment.apiBasePath + '/User/StartTask', obj).pipe(
        tap(res => res),
        catchError(this.handleError),);
      }

      endTask(obj):Observable<any> {
        return this.http.post<any>(environment.apiBasePath + '/User/EndTask', obj).pipe(
          tap(res => res),
          catchError(this.handleError),);
        }

        closeTask(obj):Observable<any> {
          return this.http.post<any>(environment.apiBasePath + '/User/CloseTask', obj).pipe(
            tap(res => res),
            catchError(this.handleError),);
          }

    startEvent(obj):Observable<any> {
      return this.http.post<any>(environment.apiBasePath + '/User/StartEvent', obj).pipe(
        tap(res => res),
        catchError(this.handleError),);
      }

      endEvent(obj):Observable<any> {
        return this.http.post<any>(environment.apiBasePath + '/User/EndEvent', obj).pipe(
          tap(res => res),
          catchError(this.handleError),);
        }

        closeEvent(obj):Observable<any> {
          return this.http.post<any>(environment.apiBasePath + '/User/CloseEvent', obj).pipe(
            tap(res => res),
            catchError(this.handleError),);
          }
  
  performActionBysuperAdmin(obj): Observable<any> {
    return this.http.post<any>(environment.apiBasePath + '/Volunteer/PerformActionBySuperAdmin', obj).pipe(
      tap(res => res),
      catchError(this.handleError),);
  }

  deleteGroup(obj): Observable<any> {
    return this.http.post<any>(environment.apiBasePath + '/Volunteer/DeleteGroup', obj).pipe(
      tap(res => res),
      catchError(this.handleError),);
  }

  groupDetails(obj): Observable<any> {
    return this.http.post<any>(environment.apiBasePath + '/Volunteer/GroupVolunteers', obj).pipe(
      tap(res => res),
      catchError(this.handleError),);
  }
  getVolunteerDetail(obj): Observable<any> {
    return this.http.post<any>(environment.apiBasePath + '/Volunteer/Details', obj).pipe(
      tap(res => res),
      catchError(this.handleError),);
  }
  getVolunteerEventDetail(obj): Observable<any> {
    return this.http.post<any>(environment.apiBasePath + '/Volunteer/VolunteerEvents', obj).pipe(
      tap(res => res),
      catchError(this.handleError),);
  }
  getVolunteerCampaignDetail(obj): Observable<any> {
    return this.http.post<any>(environment.apiBasePath + '/Volunteer/VolunteerCampaigns', obj).pipe(
      tap(res => res),
      catchError(this.handleError),);
  }
  getVolunteerCsv(obj): Observable<any> {
    return this.http.post<any>(environment.apiBasePath + '/Volunteer/GetVolunteerListCsv', obj).pipe(
      tap(res => res),
      catchError(this.handleError),);
  }
  addVolunteerCsv(obj): Observable<any> {
    console.log(obj);
    const form: FormData = new FormData();
    form.append("AdminLoginId", obj.AdminLoginId.toString());
    if (obj.CorporateId != null) {
      form.append("CorporateId", obj.CorporateId.toString());
    }
    form.append("UploadBy", obj.file);
    return this.http.post<any>(environment.apiBasePath + '/Volunteer/BulkUpload', form).pipe(
      tap(res => res),
      catchError(this.handleError),);
    }

    uploadUserDocument(obj):Observable<any> {
      return this.http.post<any>(environment.apiBasePath + '/User/UploadUserDocument',obj).pipe(
      tap(res => res),
      catchError(this.handleError),);
    }
    deleteUserDocument(obj):Observable<any> {
      return this.http.post<any>(environment.apiBasePath + '/User/DeleteUserDocument',obj).pipe(
      tap(res => res),
      catchError(this.handleError),);
    }

    getCorporates(obj) : Observable<any> {
        return this.http.post<any>(environment.apiBasePath + '/Corporate/GetCorporates',obj).pipe(
            tap(res => res),
            catchError(this.handleError),);
    }

    getUserRegistrationStatus(obj): Observable<any> {
        return this.http.post<any>(environment.apiBasePath + '/Master/GetUserRegistrationStatus', obj).pipe(
            tap(res => res),
            catchError(this.handleError),);
    }

    uploadVolunteerProfileImage(obj):Observable<any> {
      return this.http.post<any>(environment.apiBasePath + '/User/UploadProfilePicture', obj).pipe(
          tap(res => res),
          catchError(this.handleError),);
  }
  uploadProfileDocument(obj):Observable<any> {
    return this.http.post<any>(environment.apiBasePath + '/User/UploadProfileDocument', obj).pipe(
        tap(res => res),
        catchError(this.handleError),);
}
deleteProfileDocument(obj):Observable<any> {
  return this.http.post<any>(environment.apiBasePath + '/User/DeleteProfileDocument', obj).pipe(
      tap(res => res),
      catchError(this.handleError),);
}
 
getUserCertificates(obj):Observable<any> {
  return this.http.post<any>(environment.apiBasePath + '/UserCertificate/GetUserCertificates', obj).pipe(
      tap(res => res),
      catchError(this.handleError),);
}

  getEventList(obj):Observable<any> {
    return this.http.post<any>(environment.apiBasePath + '/User/UserEvents',obj).pipe(
    tap(res => res),
    catchError(this.handleError),);
  }

  getVolunteerEventList(obj):Observable<any> {
    return this.http.post<any>(environment.apiBasePath + '/User/UserBasedEvents',obj).pipe(
    tap(res => res),
    catchError(this.handleError),);
  }

  getVolunteerEventDetails(obj):Observable<any> {
    return this.http.post<any>(environment.apiBasePath+'/User/UserEventDetail',obj).pipe(
    tap(res => res),
    catchError(this.handleError),);
  }

  getEventFeedbackDetail(obj):Observable<any> {
    return this.http.post<any>(environment.apiBasePath+'/EventFeedback/GetEventFeedbackDetail',obj).pipe(
    tap(res => res),
    catchError(this.handleError),);
  }

  getVolunteerProjectList(obj):Observable<any> {
    return this.http.post<any>(environment.apiBasePath+'/Campaign/GetVolunteerCampaign',obj).pipe(
    tap(res => res),
    catchError(this.handleError),);
  }

  getPublicProjects(obj):Observable<any> {
    return this.http.post<any>(environment.apiBasePath+'/Campaign/GetPublicCampaign',obj).pipe(
    tap(res => res),
    catchError(this.handleError),);
  }

  getProjectDetails(obj):Observable<any> {
    return this.http.post<any>(environment.apiBasePath+'/Volunteer/CampaignDetail',obj).pipe(
    tap(res => res),
    catchError(this.handleError),);
  }

  getProjectsTasks(obj):Observable<any> {
    return this.http.post<any>(environment.apiBasePath+'/Campaign/GetVolunteerTask',obj).pipe(
    tap(res => res),
    catchError(this.handleError),);
  }

  getProjectTaskDetail(obj):Observable<any> {
    return this.http.post<any>(environment.apiBasePath+'/Campaign/GetVolunteerTaskDetails',obj).pipe(
    tap(res => res),
    catchError(this.handleError),);
  }

  updateProjectTaskProgress(obj):Observable<any> {
    return this.http.post<any>(environment.apiBasePath+'/User/AddTaskTransaction',obj).pipe(
    tap(res => res),
    catchError(this.handleError),);
  }
  updateProjectTaskStatus(obj): Observable<any> {
    return this.http.post<any>(environment.apiBasePath+'/Event/PerformActionOnTaskUserTransaction',obj).pipe(
    tap(res => res),
    catchError(this.handleError),);
  }
  shareProjectTaskProgress(obj):Observable<any> {
    return this.http.post<any>(environment.apiBasePath + '/User/AddTaskTransaction',obj).pipe(
    tap(res => res),
    catchError(this.handleError),);
  }

  applyForEvent(obj):Observable<any> {
    return this.http.post<any>(environment.apiBasePath + '/User/ApplyForEvent',obj).pipe(
    tap(res => res),
    catchError(this.handleError),);
  }

  cancelEvent(obj):Observable<any> {
    return this.http.post<any>(environment.apiBasePath + '/User/CancelEventApplication',obj).pipe(
    tap(res => res),
    catchError(this.handleError),);
  }

  shareEventProgress(obj):Observable<any> {
    return this.http.post<any>(environment.apiBasePath + '/User/AddEventTransaction',obj).pipe(
    tap(res => res),
    catchError(this.handleError),);
  }

   getEventProgress(obj):Observable<any> {
    return this.http.post<any>(environment.apiBasePath + '/Event/GetEventUserTransaction',obj).pipe(
    tap(res => res),
    catchError(this.handleError),);
   }

   createEventFeedback(obj):Observable<any> {
    return this.http.post<any>(environment.apiBasePath + '/EventFeedback/CreateEventFeedback',obj).pipe(
    tap(res => res),
    catchError(this.handleError),);
   }

   performActionOnEventUserTransaction(obj):Observable<any> {
    return this.http.post<any>(environment.apiBasePath + '/Event/PerformActionOnEventUserTransaction',obj).pipe(
    tap(res => res),
    catchError(this.handleError),);
   }

   getUserHistory(obj):Observable<any> {
    return this.http.post<any>(environment.apiBasePath + '/User/GetUserHistory',obj).pipe(
    tap(res => res),
    catchError(this.handleError),);
   }

   getUserPointsDetail(obj):Observable<any> {
    return this.http.post<any>(environment.apiBasePath + '/User/GetUserLeaderBoardDetail',obj).pipe(
    tap(res => res),
    catchError(this.handleError),);
   }

  private handleError(error: HttpErrorResponse) {
    console.error("Error Status",error.status);
    let errorMessage = '';
    if (error.error instanceof Error) {
      // A client-side or network error occurred. Handle it accordingly.
      errorMessage = `An error occurred: ${error.error.message}`;
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      errorMessage = `Server returned code: ${error.status}, error message is: ${error.message}`;
    }
    console.error(errorMessage);
    return observableThrowError(error.error);
  }

}
